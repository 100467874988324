import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { GlobalStyles } from "./styles";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "./index.css";
import { AppProvider } from "./contexts/AppContext";
import { SocketProvider } from "./contexts/ActionCableContext";
import { PostsProvider } from "./contexts/PostsContext";
import { QuestionsProvider } from "./contexts/QuestionsContext";

// eslint-disable-next-line import/no-named-as-default-member
ReactDOM.render(
  <React.StrictMode>
    <SocketProvider>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <AppProvider>
        <PostsProvider>
          <QuestionsProvider>
            <GlobalStyles />
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </QuestionsProvider>
        </PostsProvider>
      </AppProvider>
    </SocketProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
