import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  border-radius: 4px;
  padding: 20px;
  align-items: center;
  gap: 15px;
  background-color: var(--kssc-light-red);
  border: 1px solid var(--kssc-red);
  color: var(--kssc-red);
  width: 100%;
  strong {
    padding-bottom: 10px;
  }
`;

export const ErrorTitle = styled.div`
  font-weight: bold;
  padding-bottom: 5px;
`;
