import { createGlobalStyle } from "styled-components";

export const MEDIA = {
  xxs: "360px",
  xs: "599px",
  sm: "959px",
  md: "1279px",
  lg: "1919px",
  nav: "706px",
} as const;

export const GlobalStyles = createGlobalStyle`

:root {
    /* fonts */
 @font-face {
 font-family: 'NotoSansKR-Regular';
 src: url(public/fonts/NotoSansKR-Regular.otf) format('otf');
}
  /* colors */
  --kssc-teal: #2cbcb3;
  --kssc-pastel-teal: #98e7e2;
  --kssc-medium-teal: #28a9a1;
  --kssc-light-teal: #eaf9f7;
  --kssc-dark-teal: #1c645c;
  --kssc-yellow: #fcc43c;
  --kssc-pastel-yellow: #fdd981;
  --kssc-dark-yellow: #e2a103;
  --kssc-light-yellow: #fff9ec;
  --kssc-light-yellow-transparent: rgb(255, 249, 236, 0.4);
  --kssc-light-yellow-semi-transparent: rgb(255, 249, 236, 0.6);
  --kssc-red: #ed1c24;
  --kssc-pastel-red: #f58a8f;
  --kssc-light-red: #fde8e9;
  --kssc-green: #5cc46c;
  --kssc-orange: #ff9830;
  --kssc-white: #ffffff;
  --kssc-text: #190f05;
  --kssc-light-grey: #e7e7e6;
  --kssc-grey: #a39f9b;
  --kssc-dark-grey: #756f69;
  --kssc-black: #000000;

  /* font-sizes */

  --kssc-sm: 11px;
  --kssc-md: 14px;
  --kssc-lg: 18px;
  --kssc-xlg: 22px;
  --kssc-2xl: 26px;
  --kssc-3xl: 30px;
  --kssc-4xl: 35px;
  --kssc-5xl: 48px;

  --kssc-box-shadow:  13px 16px 25px rgb(0 0 0 / 0.3);
  --kssc-paper-shadow: rgb(0 0 0 / 20%) 0px 3px 3px -2px, rgb(0 0 0 / 14%) 0px 3px 4px 0px, rgb(0 0 0 / 12%) 0px 1px 8px 0px;
  --kssc-border:  1px #e7e7e6 solid;

  --fc-button-bg-color: #2cbcb3;
  --fc-button-border-color: #2cbcb3;
  --fc-button-hover-bg-color: #1c645c;
  --fc-button-hover-border-color: #1c645c;
  --fc-event-bg-color: #f58a8f;
  --fc-event-border-color: #f58a8f;
  --fc-event-text-color: #fff;

  --fc-today-bg-color: #eaf9f7;
}
 
`;
