import { FC } from "react";
import { useTranslation } from "react-i18next";
import { ReturnButton } from "../atoms";

import * as S from "./Pages.css";

export const EmailAlreadyConfirmed: FC = () => {
  const { t } = useTranslation();
  return (
    <S.Container>
      <S.Title>{t("email-already-confirmed")}</S.Title>
      <ReturnButton />
    </S.Container>
  );
};
