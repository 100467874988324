import styled, { css } from "styled-components";
type Props = {
  variant?: "light";
};

export const InputComponent = styled.input<Props>`
  border: none;
  font-size: 20px;
  color: var(--kssc-dark-teal);
  background: transparent !important;
  width: 100%;
  height: 32px;

  &:focus {
    outline: none;
  }
  &:invalid {
    outline: red;
  }

  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px var(--kssc-dark-teal) inset !important;
    box-shadow: 0 0 0 30px var(--kssc-dark-teal) inset !important;
    -webkit-text-fill-color: var(--kssc-white) !important;

    &::first-line {
      font-size: 20px !important;
    }
  }

  ${({ variant }) =>
    variant === "light" &&
    css`
      &:-webkit-autofill {
        -webkit-box-shadow: 0 0 0 30px white inset !important;
        -webkit-text-fill-color: var(--kssc-dark-teal) !important;
      }
    `}
`;

export const TextAreaComponent = styled.textarea<Props>`
  border: none;
  font-size: 20px;
  color: var(--kssc-dark-teal);
  background-color: var(--kssc-light-teal);
  width: 100%;
  height: 200px;

  &:focus {
    outline: none;
  }
  ${({ variant }) =>
    variant === "light" &&
    css`
      background-color: unset;
    `}
`;
export const LabelComponent = styled.label<Props>`
  color: var(--kssc-dark-teal);
  width: 100%;
  display: flex;
  justify-content: space-between;

  &:focus-within {
    color: var(--kssc-teal);
  }

  span {
    position: absolute;
    top: -11px;
    background-color: var(--kssc-light-teal);
    padding: 0 2px;
    font-size: 15px;
  }
  ${({ variant }) =>
    variant === "light" &&
    css`
      background-color: var(--kssc-white);
      span {
        background-color: var(--kssc-white);
      }
    `}
`;
export const GroupComponent = styled.fieldset<Props>`
  width: 100%;

  border-radius: 10px;
  position: relative;
  border: 2px solid var(--kssc-dark-teal);

  &:focus-within {
    color: var(--kssc-teal);
    border-color: var(--kssc-teal);
  }

  &:invalid {
    border: 2px solid var(--kssc-red);
    label {
      color: var(--kssc-red) !important;
    }
  }
`;
