import styled from "styled-components";
import { Button } from "../atoms";

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
  margin-top: 10px;
  button {
    margin: 0;
    padding: 10px 5px;
    width: 50px;
    svg {
      margin-right: 0;
    }
  }
`;

export const Photo = styled.div<{ isSelected?: boolean }>`
  * {
    box-sizing: border-box;
  }
  img {
    height: auto;
    width: auto;
    margin: 20px;
    border-radius: 5px;
    border: ${(p) =>
      p.isSelected ? "25px solid var(--kssc-yellow)" : "unset"};
  }
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--kssc-teal);
  font-size: var(--kssc-sm);
  margin-bottom: 20px;
`;
export const PhotosContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
export const ActionContainer = styled.div`
  background-color: var(--kssc-light-teal);
  border-radius: 5px;
  padding: 15px;
  font-size: 15px;
`;
export const EditContainer = styled.div`
  display: inline-flex;
  background-color: var(--kssc-light-teal);
  border-radius: 5px;
  padding: 15px;
  align-items: center;
`;

export const ActionButton = styled(Button)`
  padding: 10px 5px;
  width: 40px;
  svg {
    margin-right: 0;
  }
`;

export const TitleInput = styled.input`
  background: var(--kssc-light-teal);
  border: 2px solid var(--kssc-teal);
  width: 100%;
  outline: none;
  border-radius: 4px;
  color: var(--kssc-dark-teal);
  height: 25px;
  &:focus {
    border: 2px solid var(--kssc-dark-teal);
  }
`;
