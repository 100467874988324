import styled, { keyframes } from "styled-components";
import { Button } from "../atoms";
import { MEDIA } from "../styles";

const slideRight = keyframes`
from {
  left: -300px;
}

to {
  left: 10px;
}
`;

export const Container = styled.div`
  margin: 1.5rem 0;
  font-size: var(--kssc-xlg);
  color: var(--kssc-black);
`;

export const ContactUsText = styled.span`
  color: var(--kssc-teal);
  display: inline-flex;
  align-items: center;
  svg {
    margin: 5px;
  }
`;
export const SectionTeal = styled(Container)`
  background-color: var(--kssc-light-teal);
`;
export const SubTitle = styled.h2`
  display: block;
  font-size: var(--kssc-2xl);
  font-weight: bold;
  color: var(--kssc-dark-teal);
  margin: 20px;
`;
export const CoreValues = styled.div`
  font-weight: bold;
  color: var(--kssc-dark-teal);
  margin-bottom: 10px;
`;
export const VisionStrong = styled.span`
  font-size: var(--kssc-2xl);
  font-weight: bold;
  color: var(--kssc-dark-teal);
  padding-right: 20px;
`;
export const Text = styled.p`
  border-radius: 5px;
  font-size: var(--kssc-xlg);
  padding: 5px;
  margin: 20px;
`;
export const List = styled.ol`
  border-radius: 5px;
  font-size: var(--kssc-lg);
  padding: 10px;
  margin: 5px 30px;
  li {
    padding: 5px;
  }
`;
export const ListAlt = styled.ul`
  border-radius: 5px;
  padding: 10px;
  margin: 5px 30px;
  li {
    padding: 5px;
  }
  li::marker {
    color: var(--kssc-teal);
  }
`;
export const TextLast = styled(Text)`
  padding-bottom: 20px;
`;
export const TextAlt1 = styled(Text)`
  background-color: var(--kssc-light-yellow);
`;
export const TextAlt2 = styled(Text)`
  background-color: var(--kssc-light-teal);
`;

export const Title = styled.h3`
  font-size: var(--kssc-5xl);
  opacity: 50%;
  margin: 0;

  color: var(--kssc-dark-teal);
  padding: 10px;
  animation: ${slideRight} 0.5s;
  animation-fill-mode: forwards;
  position: relative;

  svg {
    padding-left: 2px;
  }
`;

export const ImageSection = styled.div<{ imageUrl: string }>`
  background-image: url(${(p) => p.imageUrl});
  background-repeat: no-repeat;
  background-color: black;
  display: flex;
  min-height: 800px;
  max-height: 999px;
  border: 5px solid var(--kssc-white);

  @media (max-width: ${MEDIA.sm}) {
    flex-direction: column;
    padding: 80px 0;
  }
`;
export const ProgrammesSection = styled.div`
  padding: 80px;
`;
export const AboutText = styled.div`
  color: var(--kssc-white);
  font-size: var(--kssc-lg);
  box-sizing: border-box;
  padding: 20px;
  width: 500px;
  position: absolute;
  text-align: left;
  background-color: var(--kssc-light-yellow-transparent);
  @media (max-width: ${MEDIA.xs}) {
    width: 100%;
  }
`;
export const AboutTitle = styled.div`
  color: var(--kssc-white);
  font-size: var(--kssc-3xl);
  margin: 20px;
  margin-left: 0;
  font-weight: bold;
`;

export const ImageText = styled.div`
  cursor: pointer;
  margin-top: 10px;
  font-size: 20px;

  &:hover {
    color: var(--kssc-teal);
  }
`;

export const ProgrammesTitle = styled.div`
  font-size: var(--kssc-3xl);
  font-weight: bold;
  border-bottom: 4px solid;
`;

export const ProgrammeIconsContainer = styled.div`
  padding-top: 20px;
  display: grid;
  align-items: baseline;
  grid-template-columns: 1fr 1fr 1fr;

  svg {
    color: var(--kssc-yellow);
    height: 45px;
  }

  @media (max-width: ${MEDIA.sm}) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: ${MEDIA.xs}) {
    grid-template-columns: 1fr;
  }
`;

export const ContactFormText = styled.div`
  margin: 20px;
  color: var(--kssc-dark-teal);
  display: grid;
  grid-template-columns: 50px 1fr;
  grid-template-areas:
    "icon text1"
    "icon text2"
    "icon text3";
`;
export const IconWrapper = styled.div`
  grid-area: icon;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    height: 25px;
    color: var(--kssc-teal);
  }
`;
export const ContactTitle = styled.div`
  grid-area: text1;
  font-size: 25px;
  color: var(--kssc-teal);
`;
export const ContactText = styled.div<{ gridArea: string }>`
  grid-area: ${(p) => p.gridArea};
  font-size: 18px;
  color: var(--kssc-text);
`;

export const StaffListItem = styled.div`
  font-size: 25px;
  color: var(--kssc-dark-grey);
  font-weight: bold;
  span {
    color: var(--kssc-text);
    padding-left: 12px;
    font-size: 20px;
  }
`;

export const AddButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;
  small {
    font-size: 15px;
  }
`;
export const Icons = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: inherit;
  width: 100%;
  margin-bottom: 20px;
  z-index: 2;
  @media (max-width: ${MEDIA.sm}) {
    margin-top: -60px;
  }
`;
export const IconButton = styled(Button)`
  margin: 0;
  height: 45px;
  width: 45px;
  padding: 0;
  svg {
    margin: 0;
  }
  &:hover {
    border: 3px white solid;
  }
`;
export const SendButton = styled(Button)`
  align-self: flex-end;
`;
export const SignUp = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-start;
`;
export const SmallLinks = styled.div`
  display: flex;
  color: white;
  align-items: center;
  button {
    margin: 0 10px;
    &::before {
      content: "";
      position: relative;
      right: 15px;
      width: 1px;
      height: 15px;
      border-radius: 0.5px;
      background-color: var(--kssc-teal);
    }
    &:first-child {
      ::before {
        content: none;
      }
    }
  }
`;

export const IconButtonLeft = styled(IconButton)`
  background-color: var(--kssc-light-yellow-semi-transparent);
  border-radius: 4px 0 0 4px;
`;
export const IconButtonRight = styled(IconButton)`
  border-radius: 0 4px 4px 0;
  border: 1px white solid;
  background-color: unset;
`;

export const SignUpContainer = styled.form`
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 600px;
  margin: 20px;
  gap: 20px;

  @media (max-width: ${MEDIA.xs}) {
    width: 250px;
  }
`;

export const SignUpSection = styled.div`
  align-self: flex-start;
  color: var(--kssc-dark-teal);
  font-weight: bold;
  font-size: var(--kssc-lg);
`;
export const Chairwoman = styled.img`
  height: 25%;
  width: 25%;
  display: inline;
  margin: 17px;
`;
export const Greeting = styled.div`
  padding: 20px;
  display: flex;
  gap: 20px;
  justify-content: space-between;
  font-size: var(--kssc-lg);
  @media (max-width: ${MEDIA.xs}) {
    flex-direction: column;
    img {
      height: 75%;
      width: 75%;
      @media (max-width: ${MEDIA.xs}) {
        height: 90%;
        width: 90%;
      }
    }
  }
`;
export const GreetingText = styled.div`
  padding: 5px;
`;
export const Hr = styled.hr`
  width: 100%;
  color: var(--kssc-light-grey);
  background-color: var(--kssc-light-grey);
  height: 4px;
  margin-top: 38px;
  border: none;
`;
export const Chart = styled.div`
  display: flex;
  justify-content: center;
  img {
    height: 75%;
    width: 75%;
    @media (max-width: ${MEDIA.xs}) {
      height: 100%;
      width: 100%;
    }
  }
`;

export const Map = styled.iframe`
  padding: 20px;
  height: 450px;
  width: 600px;
  box-sizing: border-box;
  border: 0;
  @media (max-width: ${MEDIA.xs}) {
    padding: 5px;
    height: 100%;
    width: 100%;
  }
`;

export const ProfileItems = styled.div`
  background-color: var(--kssc-light-teal);
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  margin: 10px;
  width: 800px;
  box-shadow: var(--kssc-box-shadow);
  @media (max-width: ${MEDIA.xs}) {
    width: 90%;
  }
`;
export const ProfileItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  color: var(--kssc-dark-teal);
  svg {
    color: var(--kssc-dark-teal);
    padding-right: 10px;
  }
`;
export const Buttons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
