import styled from "styled-components";
import { MEDIA } from "../styles";

export const PopperDiv = styled.div`
  position: absolute;
  z-index: 9999;
  width: 100%;
  left: 50%;
  top: 50%;
  transform: translate(-10%, -50%);
  @media (max-width: ${MEDIA.sm}) {
    position: fixed;
    left: 0;
    top: 25%;
    transform: unset;
  }
`;

export const RefDiv = styled.div`
  opacity: 50%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background-color: black;
`;
