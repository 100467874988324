import { createContext, Dispatch, FC, Reducer, useContext } from "react";
import { AsyncActionHandlers, useReducerAsync } from "use-reducer-async";
import { api } from "../api";
import { handleError } from "../helpers/handlerError";
import { TimelineEvent } from "../types";

export type PageState = {
  loading: boolean;
  error: string | null;
  events: TimelineEvent[];
};

type SyncAction =
  | { type: "BEGIN_REQUEST" }
  | { type: "CLEAN_ALL" }
  | { type: "REQUEST_FAILURE"; error: string }
  | { type: "REQUEST_SUCCESS"; events: TimelineEvent[] };

type AsyncAction =
  | { type: "FETCH_EVENTS" }
  | {
      type: "CREATE_EVENT";
      details_kor: string;
      details_eng: string;
      date: string;
    }
  | { type: "UPDATE_EVENT"; event: TimelineEvent }
  | { type: "DELETE_EVENT"; id: number };

type Action = SyncAction | AsyncAction;

export const initialState: PageState = {
  loading: false,
  error: null,
  events: [],
};

export const reducer: Reducer<PageState, SyncAction> = (
  state,
  action
): PageState => {
  switch (action.type) {
    case "BEGIN_REQUEST":
      return { ...state, loading: true, error: null };
    case "CLEAN_ALL":
      return { ...state, loading: false, error: null };
    case "REQUEST_SUCCESS":
      return { ...state, loading: false, error: null, events: action.events };
    case "REQUEST_FAILURE":
      return { ...state, error: action.error, loading: false };
    default:
      return state;
  }
};

export const asyncActionHandlers: AsyncActionHandlers<
  Reducer<PageState, Action>,
  AsyncAction
> = {
  FETCH_EVENTS:
    ({ dispatch }) =>
    async () => {
      dispatch({ type: "BEGIN_REQUEST" });
      try {
        const events = await api.events.index();
        dispatch({
          type: "REQUEST_SUCCESS",
          events,
        });
      } catch (e) {
        console.error(e);
        const error = handleError(e);
        dispatch({ type: "REQUEST_FAILURE", error });
      }
    },
  CREATE_EVENT:
    ({ dispatch }) =>
    async (action: {
      details_kor: string;
      details_eng: string;
      date: string;
    }) => {
      dispatch({ type: "BEGIN_REQUEST" });
      const { details_kor, details_eng, date } = action;
      try {
        await api.events.create({ event: { details_kor, details_eng, date } });
        const events = await api.events.index();
        dispatch({
          type: "REQUEST_SUCCESS",
          events,
        });
      } catch (e) {
        console.error(e);
        const error = handleError(e);
        dispatch({ type: "REQUEST_FAILURE", error });
      }
    },
  UPDATE_EVENT:
    ({ dispatch }) =>
    async (action: { event: TimelineEvent }) => {
      dispatch({ type: "BEGIN_REQUEST" });
      const { event } = action;
      try {
        await api.events.update(event);
        const events = await api.events.index();
        dispatch({
          type: "REQUEST_SUCCESS",
          events,
        });
      } catch (e) {
        console.error(e);
        const error = handleError(e);
        dispatch({ type: "REQUEST_FAILURE", error });
      }
    },
  DELETE_EVENT:
    ({ dispatch }) =>
    async (action: { id: number }) => {
      dispatch({ type: "BEGIN_REQUEST" });
      const { id } = action;
      try {
        await api.events.delete(id);
        const events = await api.events.index();
        dispatch({
          type: "REQUEST_SUCCESS",
          events,
        });
      } catch (e) {
        console.error(e);
        const error = handleError(e);
        dispatch({ type: "REQUEST_FAILURE", error });
      }
    },
};

const PageContext = createContext<{
  state: PageState;
  dispatch: Dispatch<Action>;
}>({
  state: initialState,
  dispatch: () => undefined,
});

export const useTimelineContext = () => useContext(PageContext);

type Props = {
  overrideInitialState?: PageState;
  dispatchOverride?: Dispatch<Action>;
};

export const TimelineProvider: FC<Props> = ({
  dispatchOverride,
  children,
  ...overrideInitialState
}) => {
  const [state, dispatch] = useReducerAsync<
    Reducer<PageState, SyncAction>,
    AsyncAction,
    Action
  >(reducer, { ...initialState, ...overrideInitialState }, asyncActionHandlers);

  const d = dispatchOverride || dispatch;

  return (
    <PageContext.Provider value={{ state, dispatch: d }}>
      {children}
    </PageContext.Provider>
  );
};
