import { FC } from "react";
import { useTranslation } from "react-i18next";

import * as S from "./Pages.css";
import { Blogs } from "./Blogs";

export const Workshops: FC = () => {
  const { t } = useTranslation();

  return (
    <S.Container>
      <S.Title>{t("workshops")}</S.Title>
      <S.Text>
        KSSC is planning and creating content for workshops for public awareness
        of different social issues and mental health. If there is anything you
        would like us to cover, do not hesitate to contact us for sharing your
        ideas at general@kssc-ab.ca.
      </S.Text>
      <Blogs blogKind="workshops" />
    </S.Container>
  );
};
