import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { Icon } from "../electrons";

import * as S from "./NavBarItem.css";

type NavButtonProps =
  | { displayName: string; to: string }
  | {
      displayName: string;
      onClick(): void;
    };

type Props = {
  iconName: string;
  mainButton: NavButtonProps;
};

export const NavBarItemSmall: FC<Props> = ({ iconName, mainButton }) => {
  const navigate = useNavigate();
  return (
    <S.NavItemSmall
      onClick={() => {
        "to" in mainButton ? navigate(mainButton.to) : mainButton.onClick();
      }}
    >
      <Icon iconName={iconName} size="18px" />
      {mainButton.displayName}
    </S.NavItemSmall>
  );
};
