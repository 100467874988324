/* eslint-disable @typescript-eslint/naming-convention */
import { createContext, Dispatch, FC, Reducer, useContext } from "react";
import { AsyncActionHandlers, useReducerAsync } from "use-reducer-async";
import { api } from "../api";
import { handleError } from "../helpers/handlerError";
import { PostComment } from "../types";
import orderBy from "lodash/orderBy";

export type PageState = {
  loading: boolean;
  error: string | null;
  post_comments: PostComment[];
  post_id: number | null;
};

type SyncAction =
  | { type: "BEGIN_REQUEST" }
  | { type: "CLEAN_ALL" }
  | { type: "INIT_SUCCESS"; post_comments: PostComment[]; post_id: number }
  | { type: "ADD_FROM_WS"; post_comment: PostComment }
  | { type: "UPDATE_FROM_WS"; post_comment: PostComment }
  | { type: "DELETE_FROM_WS"; post_comment_id: number }
  | { type: "CREATE_SUCCESS" }
  | { type: "REQUEST_FAILURE"; error: string }
  | { type: "REQUEST_SUCCESS" };

type AsyncAction =
  | { type: "INIT"; post_id: number }
  | { type: "CREATE_POST_COMMENT"; comment: string; user_id: number }
  | { type: "UPDATE_POST_COMMENT"; post_comment: PostComment }
  | { type: "DELETE_POST_COMMENT"; id: number };

export type PostCommentAction = SyncAction | AsyncAction;

export const initialState: PageState = {
  loading: false,
  error: null,
  post_comments: [],
  post_id: null,
};

export const reducer: Reducer<PageState, SyncAction> = (
  state,
  action
): PageState => {
  switch (action.type) {
    case "BEGIN_REQUEST":
      return { ...state, loading: true, error: null };
    case "CLEAN_ALL":
      return { ...state, loading: false, error: null };
    case "REQUEST_SUCCESS":
      return {
        ...state,
        loading: false,
        error: null,
      };
    case "INIT_SUCCESS":
      return {
        ...state,
        loading: false,
        error: null,
        post_comments: action.post_comments,
        post_id: action.post_id,
      };
    case "REQUEST_FAILURE":
      return { ...state, error: action.error, loading: false };
    case "ADD_FROM_WS":
      return {
        ...state,
        post_comments: orderBy(
          [...state.post_comments, action.post_comment],
          ["id"],
          ["asc"]
        ),
      };
    case "UPDATE_FROM_WS":
      if (!state.post_comments.find((p) => p.id === action.post_comment.id))
        return state;
      else
        return {
          ...state,
          post_comments: orderBy(
            [
              ...state.post_comments.filter(
                (p) => p.id !== action.post_comment.id
              ),
              action.post_comment,
            ],
            ["id"],
            ["asc"]
          ),
        };
    case "DELETE_FROM_WS":
      return {
        ...state,
        post_comments: state.post_comments.filter(
          (p) => p.id !== action.post_comment_id
        ),
      };
    default:
      return state;
  }
};

export const asyncActionHandlers: AsyncActionHandlers<
  Reducer<PageState, PostCommentAction>,
  AsyncAction
> = {
  INIT:
    ({ dispatch }) =>
    async ({ post_id }) => {
      dispatch({ type: "BEGIN_REQUEST" });
      try {
        const post_comments = await api.post_comments.index({ post_id });
        dispatch({
          type: "INIT_SUCCESS",
          post_comments: orderBy(post_comments, "id"),
          post_id,
        });
      } catch (e) {
        console.error(e);
        const error = handleError(e);
        dispatch({ type: "REQUEST_FAILURE", error });
      }
    },
  CREATE_POST_COMMENT:
    ({ dispatch, getState }) =>
    async (action: { comment: string; user_id: number }) => {
      dispatch({ type: "BEGIN_REQUEST" });
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { comment, user_id } = action;
      const post_id = getState().post_id;
      if (!post_id) throw new Error("Post was blank!");
      try {
        await api.post_comments.create({
          post_comment: { comment, user_id, post_id },
        });
        dispatch({
          type: "REQUEST_SUCCESS",
        });
      } catch (e) {
        console.error(e);
        const error = handleError(e);
        dispatch({ type: "REQUEST_FAILURE", error });
      }
    },
  UPDATE_POST_COMMENT:
    ({ dispatch, getState }) =>
    async (action: { post_comment: PostComment }) => {
      dispatch({ type: "BEGIN_REQUEST" });
      const { post_comment } = action;
      const post_id = getState().post_id;
      if (!post_id) throw new Error("Post was blank!");
      try {
        await api.post_comments.update(post_comment);

        dispatch({
          type: "REQUEST_SUCCESS",
        });
      } catch (e) {
        console.error(e);
        const error = handleError(e);
        dispatch({ type: "REQUEST_FAILURE", error });
      }
    },
  DELETE_POST_COMMENT:
    ({ dispatch, getState }) =>
    async (action: { id: number }) => {
      dispatch({ type: "BEGIN_REQUEST" });
      const { id } = action;
      const post_id = getState().post_id;
      if (!post_id) throw new Error("Post was blank!");
      try {
        await api.post_comments.delete(id);

        dispatch({
          type: "REQUEST_SUCCESS",
        });
      } catch (e) {
        console.error(e);
        const error = handleError(e);
        dispatch({ type: "REQUEST_FAILURE", error });
      }
    },
};

const PageContext = createContext<{
  state: PageState;
  dispatch: Dispatch<PostCommentAction>;
}>({
  state: initialState,
  dispatch: () => undefined,
});

export const usePostCommentsContext = () => useContext(PageContext);

type Props = {
  overrideInitialState?: PageState;
  dispatchOverride?: Dispatch<PostCommentAction>;
};

export const PostCommentsProvider: FC<Props> = ({
  dispatchOverride,
  children,
  ...overrideInitialState
}) => {
  const [state, dispatch] = useReducerAsync<
    Reducer<PageState, SyncAction>,
    AsyncAction,
    PostCommentAction
  >(reducer, { ...initialState, ...overrideInitialState }, asyncActionHandlers);

  const d = dispatchOverride || dispatch;

  return (
    <PageContext.Provider value={{ state, dispatch: d }}>
      {children}
    </PageContext.Provider>
  );
};
