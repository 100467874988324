import { FC, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppContext } from "../contexts/AppContext";
import { usePostCommentsContext } from "../contexts/PostCommentsContext";
import * as S from "./PostComments.css";
import { ErrorMessage, Icon, TextArea } from "../electrons";
import { Button } from "../atoms";
import { PostCommentItem } from "./PostCommentItem";
import { useSocket } from "../contexts/ActionCableContext";
import { usePostsContext } from "../contexts/PostsContext";

export const PostComments: FC = () => {
  const [comment, setComment] = useState<string>("");
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const { post_id } = useParams();
  const [titleContent, setTitleContent] = useState<string>("");
  const { dispatch: socketDispatch } = useSocket();

  const {
    dispatch,
    state: { post_comments, error, loading },
  } = usePostCommentsContext();
  const {
    dispatch: postsDispatch,
    state: { posts },
  } = usePostsContext();
  const {
    state: { user },
  } = useAppContext();
  useEffect(() => {
    dispatch({ type: "INIT", post_id: Number(post_id || 0) });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const post = posts.find((p) => p.id === Number(post_id || 0));
  useEffect(() => {
    if (post) {
      setTitleContent(post.title);
      socketDispatch({
        type: "POST_SUBSCRIBE",
        postId: post.id,
        postCommentsDispatch: dispatch,
      });
    }
    return () => socketDispatch({ type: "UNSUBSCRIBE", channelName: "post" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const navigate = useNavigate();

  const onSubmit = () => {
    if (user) {
      dispatch({
        type: "CREATE_POST_COMMENT",
        comment,
        user_id: user.id,
      });
      setComment("");
    }
  };

  const commentsEndRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = () => {
    document.getElementById("comments-container")?.scrollTo({
      top: commentsEndRef?.current?.offsetTop,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    scrollToBottom();
  }, [post_comments]);

  const onPostEdit = () => {
    post &&
      titleContent.length &&
      postsDispatch({
        type: "UPDATE_POST",
        post: { ...post, title: titleContent },
      });
  };
  const focusInput = () => document?.getElementById("post-input")?.focus();
  useEffect(() => () => dispatch({ type: "CLEAN_ALL" }), [dispatch]);
  if (!user || post_comments.length === 0)
    return (
      <>
        {error && <ErrorMessage error={error} />}
        <S.NothingDiv>Nothing to see yet!</S.NothingDiv>
      </>
    );
  return (
    <>
      <S.Container>
        {error && <ErrorMessage error={error} />}
        <S.BackButtonWrapper>
          {user && (
            <Button onClick={() => navigate(-1)}>
              <Icon iconName="arrow-left" />
              Back
            </Button>
          )}
        </S.BackButtonWrapper>
        <S.CommentsContainer id="comments-container">
          {post && (
            <S.PostTitle>
              {!isEditing && <span>{post.title}</span>}
              {isEditing && (
                <S.EditPost>
                  <S.PostInput
                    id="post-input"
                    value={titleContent}
                    onChange={(e) => setTitleContent(e.target.value)}
                    onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                      if (e.key === "Enter" && !e.shiftKey) {
                        e.preventDefault();
                        onPostEdit();
                        setIsEditing(false);
                      }
                    }}
                  />
                  <S.IconButton>
                    <button
                      type="button"
                      onClick={() => {
                        onPostEdit();
                        setIsEditing(false);
                      }}
                    >
                      <Icon
                        iconName="paper-plane"
                        size="14px"
                        color="var(--kssc-teal)"
                      />
                    </button>
                  </S.IconButton>
                </S.EditPost>
              )}
              {post.user_id === user.id && !isEditing && (
                <S.IconButton>
                  <button
                    type="button"
                    onClick={() => {
                      focusInput();
                      setIsEditing(true);
                    }}
                  >
                    <Icon
                      iconName="pencil"
                      size="16px"
                      color="var(--kssc-teal)"
                    />
                  </button>
                </S.IconButton>
              )}
            </S.PostTitle>
          )}
          {post &&
            user &&
            post_comments.map((p) => (
              <PostCommentItem
                key={p.id}
                comment={p}
                onEdit={(post_comment) =>
                  dispatch({ type: "UPDATE_POST_COMMENT", post_comment })
                }
                onDelete={(id) => dispatch({ type: "DELETE_POST_COMMENT", id })}
              />
            ))}
          <div ref={commentsEndRef} />
        </S.CommentsContainer>
        <S.NewComment>
          <TextArea
            onKeyDown={(e: React.KeyboardEvent<HTMLTextAreaElement>) => {
              if (e.key === "Enter" && !e.shiftKey) {
                e.preventDefault();
                onSubmit();
              }
            }}
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            label={"Comment"}
            variant="light"
          />
          <Button disabled={loading} onClick={onSubmit}>
            <Icon iconName={loading ? "cog" : "paper-plane"} spin={loading} />
          </Button>
        </S.NewComment>
      </S.Container>
    </>
  );
};
