import { FC } from "react";
import { useTranslation } from "react-i18next";
import { ReturnButton } from "../atoms";

import * as S from "./Pages.css";

export const EmailConfirmationError: FC = () => {
  const { t } = useTranslation();
  return (
    <S.Container>
      <S.Title>{t("email-confirmation-error-title")}</S.Title>
      <S.Text>
        {t("email-confirmation-error")}
        <div>{t("help")}</div>
        <ReturnButton />
      </S.Text>
    </S.Container>
  );
};
