import { FC, useEffect, useState } from "react";
import { CounsellingContainer, CreatePost, Loading } from "../molecules";

import * as S from "./Pages.css";
import { Button } from "../atoms";
import { Icon } from "../electrons";
import { usePostsContext } from "../contexts/PostsContext";
import { useAppContext } from "../contexts/AppContext";
import { Link } from "react-router-dom";
import { orderBy } from "lodash";
import { useTranslation } from "react-i18next";

export const OnlineCounselling: FC = () => {
  const [isCreating, setIsCreating] = useState<boolean>(false);
  const { t } = useTranslation();
  const {
    state: { locale },
  } = useAppContext();

  const {
    dispatch,
    state: { posts, loading },
  } = usePostsContext();

  const {
    state: { user },
  } = useAppContext();

  useEffect(() => () => dispatch({ type: "CLEAN_ALL" }), [dispatch]);

  if (isCreating)
    return (
      <CreatePost
        onCancel={() => setIsCreating(false)}
        onSubmit={(title, content) => {
          user &&
            dispatch({ type: "CREATE_POST", title, content, user_id: user.id });
        }}
      />
    );

  return (
    <S.Container>
      <S.Title>{t("online-counselling")}</S.Title>
      {loading && <Loading />}
      <S.AddButtonWrapper>
        {user && (
          <Button onClick={() => setIsCreating(true)}>
            <Icon iconName="plus" />
            Add
          </Button>
        )}
        {!user && locale === "en" && (
          <small>
            <Link to="/sign-in">Sign in</Link> to create a post
          </small>
        )}
        {!user && locale === "kr" && (
          <small>
            <Link to="/sign-in">로그인</Link> 후 글 작성하기
          </small>
        )}
      </S.AddButtonWrapper>
      <CounsellingContainer
        posts={
          user?.role === "kssc_admin"
            ? orderBy(posts, ["unanswered_comments", "id"], ["desc", "desc"])
            : orderBy(posts, "id")
        }
      />
    </S.Container>
  );
};
