/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/control-has-associated-label */
import { FC, useEffect, useRef, useState } from "react";
import { Button } from "../atoms";
import { useBlogsContext } from "../contexts/BlogContext";
import { ErrorMessage, Icon, Input, TextArea } from "../electrons";
import { Blog } from "../types";
import * as S from "./CreatePost.css";
import { Loading } from "./Loading";
import "react-dropdown/style.css";
import "../atoms/dropdown-styles.css";
import { IKImage } from "imagekitio-react";

type Props = {
  onSubmit: (
    title_kor: string,
    description_kor: string,
    title_eng: string,
    description_eng: string,
    image: string | null
  ) => void;
  onCancel: () => void;
  blog?: Blog;
  variant?: "green";
};

export const AddBlog: FC<Props> = ({ onSubmit, onCancel, blog, variant }) => {
  const urlEndpoint = "https://ik.imagekit.io/kssc";
  const inputRef = useRef(null);
  const [file, setFile] = useState<string | null>(null);
  const handleAddPhoto = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e?.target?.files) {
      const fileItem = e?.target?.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(fileItem);
      reader.onload = () => {
        setFile(reader.result as string);
      };
    }
  };
  const [titleKor, setTitleKor] = useState<string>(blog?.title_kor || "");
  const [titleEng, setTitleEng] = useState<string>(blog?.title_eng || "");

  const [descriptionKor, setDescriptionKor] = useState<string>(
    blog?.description_kor || ""
  );
  const [descriptionEng, setDescriptionEng] = useState<string>(
    blog?.description_eng || ""
  );

  const {
    dispatch,
    state: { error, loading },
  } = useBlogsContext();

  useEffect(() => () => dispatch({ type: "CLEAN_ALL" }), [dispatch]);

  return (
    <>
      <S.Container>
        <Loading visible={loading} error={!!error} onComplete={onCancel} />
        {error && <ErrorMessage error={error} />}
        <Input
          label="Title 한국어"
          type="text"
          value={titleKor}
          variant={!!blog && variant ? undefined : "light"}
          onChange={(e) => setTitleKor(e.target.value)}
        />
        <Input
          label="Title 영어"
          type="text"
          value={titleEng}
          variant={!!blog && variant ? undefined : "light"}
          onChange={(e) => setTitleEng(e.target.value)}
        />
        <TextArea
          variant={!!blog && variant ? undefined : "light"}
          label="Description 한국어"
          value={descriptionKor}
          onChange={(e) => setDescriptionKor(e.target.value)}
        />
        <TextArea
          variant={!!blog && variant ? undefined : "light"}
          label="Description 영어"
          value={descriptionEng}
          onChange={(e) => setDescriptionEng(e.target.value)}
        />
        <S.InputContainer>
          <S.Label htmlFor="photoUpload">
            <input
              id="photoUpload"
              style={{ display: "none" }}
              type="file"
              name="photo"
              ref={inputRef}
              onChange={handleAddPhoto}
            />
          </S.Label>
          {file && (
            <S.PhotosList>
              <S.PhotosListItem>
                <img src={file} height="200" alt="preview" />
              </S.PhotosListItem>
            </S.PhotosList>
          )}
          {!file && blog?.image && (
            <IKImage
              urlEndpoint={urlEndpoint}
              path={blog.image}
              width={200}
              height={200}
              transformation={[{ height: 200, width: 200 }]}
              lqip={{ active: true }}
              style={{ float: "left", paddingRight: 20, paddingBottom: 20 }}
            />
          )}
        </S.InputContainer>
        <S.ButtonsAddPhoto>
          {inputRef && (
            <Button
              type="button"
              color="var(--kssc-dark-yellow)"
              onClick={() => (inputRef?.current as any)?.click()}
            >
              <Icon iconName="plus" />
              {blog?.image ? "Update" : "Select"} Photo
            </Button>
          )}
        </S.ButtonsAddPhoto>
        <S.Buttons>
          <Button
            type="submit"
            onClick={(e) => {
              e.preventDefault();
              onSubmit(
                titleKor,
                descriptionKor,
                titleEng,
                descriptionEng,
                file || blog?.image || null
              );
            }}
          >
            <Icon iconName="paper-plane" />
            {blog ? "Save Changes" : "Submit"}
          </Button>
          <Button type="button" color="var(--kssc-grey)" onClick={onCancel}>
            <Icon iconName="xmark" />
            Cancel
          </Button>
        </S.Buttons>
      </S.Container>
    </>
  );
};
