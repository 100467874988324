import { FC } from "react";
import { useTranslation } from "react-i18next";
import { WIP } from "../electrons";

import * as S from "./Pages.css";

export const YouthClub: FC = () => {
  const { t } = useTranslation();

  return (
    <S.Container>
      <S.Title>{t("youth-club")}</S.Title>
      <S.Text>
        <WIP />
      </S.Text>
    </S.Container>
  );
};
