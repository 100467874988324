import { FC } from "react";
import { Icon } from "../electrons";

import * as S from "./Pages.css";

export const Sponsorship: FC = () => (
  <S.Container>
    <S.Title>후원활동</S.Title>
    <S.Text>
      일시후원: 심리정서지원, 이민정착안정 프로그램 및 센터의 각종 프로그램을
      일시적으로 후원합니다. 정기후원: 심리정서지원, 이민정착안정프로그램 및
      센터의 각종 프로그램을 정기적으로 후원합니다. 기업후원: 사무실, 사업장등에
      한인사회복지센터 후원모금통을 설치하는 후원활동에 참여 가능합니다.
      재능기부: 문화공연, 강의, 컴퓨터, IT등 본인의 재능을 기부합니다.
    </S.Text>
    <S.ContactFormText>
      {" "}
      <S.IconWrapper>
        <Icon iconName="phone" color="var(--kssc-dark-teal)" />
      </S.IconWrapper>
      <S.ContactTitle>문의</S.ContactTitle>
      <S.ContactText gridArea="text2">
        <a href="tel:5873745313">587-374-5313</a>
      </S.ContactText>
    </S.ContactFormText>
    <S.ContactFormText>
      {" "}
      <S.IconWrapper>
        <Icon iconName="envelope" color="var(--kssc-dark-teal)" />
      </S.IconWrapper>
      <S.ContactTitle>계좌</S.ContactTitle>
      <S.ContactText gridArea="text2">
        <a href="mailto:klee@kssc-ab.ca">klee@kssc-ab.ca</a>
      </S.ContactText>
    </S.ContactFormText>
  </S.Container>
);
