import { FC, useState, useEffect } from "react";
import { Button } from "../atoms";
import { Icon, Input, ErrorMessage, TextArea } from "../electrons";
import * as S from "./CreateTimelineEvent.css";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import "./react-datepicker-override.css";
import { CalendarEvent } from "../types";
import { Loading } from "./Loading";
import { useCalendarContext } from "../contexts/CalendarContext";
import moment from "moment";

type Props =
  | {
      onSubmit(
        event_at: string,
        title: string,
        location: string,
        end_time: string | null,
        details: string
      ): void;
      onCancel(): void;
      event: undefined;
    }
  | {
      onSubmit(event: CalendarEvent): void;
      onCancel(): void;
      event: CalendarEvent;
    };

export const CreateCalendarEvent: FC<Props> = ({
  onSubmit,
  onCancel,
  event,
}) => {
  const [eventAt, setEventAt] = useState(
    (event && new Date(event.event_at)) || moment().startOf("hour").toDate()
  );
  const {
    state: { error, loading },
    dispatch,
  } = useCalendarContext();
  useEffect(() => () => dispatch({ type: "CLEAN_ALL" }), [dispatch]);
  const [title, setTitle] = useState<string>(event?.title || "");
  const [location, setLocation] = useState<string>(event?.location || "");
  const [details, setDetails] = useState<string>(event?.details || "");
  const [noEndTime, setNoEndTime] = useState<boolean>(event?.end_time === null);
  const [endTime, setEndTime] = useState(
    (event?.end_time && new Date(event.end_time)) || null
  );
  const filterPassedTime = (time: string | number | Date) => {
    const selectedDate = new Date(time);

    return eventAt.getTime() < selectedDate.getTime();
  };
  return (
    <>
      {error && <ErrorMessage error={error} />}
      <Loading visible={loading} error={!!error} onComplete={onCancel} />
      <S.Container>
        <S.Dates>
          <S.DateInput>
            <span>start time</span>
            <DatePicker
              className="date-input"
              selected={eventAt}
              onChange={(d: Date) => setEventAt(d)}
              showMonthDropdown
              showTimeSelect
              timeFormat="p"
              timeIntervals={15}
              dateFormat="Pp"
            />
          </S.DateInput>
          {!noEndTime && (
            <>
              <Icon iconName="arrow-right" color="var(--kssc-teal)" />
              <S.DateInput>
                <span>end time</span>
                <DatePicker
                  className="date-input"
                  selected={endTime}
                  onChange={(d: Date) => setEndTime(d)}
                  showMonthDropdown
                  showTimeSelect
                  timeFormat="p"
                  timeIntervals={15}
                  dateFormat="Pp"
                  disabled={!eventAt}
                  minDate={eventAt}
                  filterTime={filterPassedTime}
                />
              </S.DateInput>
            </>
          )}
        </S.Dates>
        <S.Checkbox>
          {" "}
          <S.CheckboxInput
            type="checkbox"
            checked={noEndTime}
            onChange={(e) => {
              setEndTime(null);
              setNoEndTime(e.target.checked);
            }}
          />
          <label htmlFor="checkbox">No end time?</label>
        </S.Checkbox>
        <Input
          type="text"
          variant="light"
          label="Title"
          value={title}
          required
          onChange={(e) => setTitle(e.target.value)}
        />
        <Input
          type="text"
          variant="light"
          label="Location"
          value={location}
          required
          onChange={(e) => setLocation(e.target.value)}
        />
        <TextArea
          variant="light"
          label="Details"
          value={details}
          onChange={(e) => setDetails(e.target.value)}
        />
        <S.Buttons>
          <Button color="var(--kssc-pastel-red)" onClick={onCancel}>
            <Icon iconName="xmark" />
            취소
          </Button>
          <Button
            type="submit"
            onClick={(e) => {
              e.preventDefault();
              !!event
                ? onSubmit({
                    ...event,
                    event_at: eventAt.toString(),
                    title,
                    location,
                    end_time: endTime?.toString() || null,
                    details,
                  })
                : onSubmit(
                    eventAt.toString(),
                    title,
                    location,
                    endTime?.toString() || null,
                    details
                  );
            }}
          >
            <Icon iconName="paper-plane" />
            저장
          </Button>
        </S.Buttons>
      </S.Container>
    </>
  );
};
