import { useEffect, useState } from "react";

export const useWindowWidth = (): number => {
  function getWidth() {
    return window.innerWidth;
  }
  const [windowWidth, setWindowWidth] = useState<number>(getWidth);
  useEffect(() => {
    function handleResize() {
      setWindowWidth(getWidth());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return windowWidth;
};
