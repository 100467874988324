import styled from "styled-components";

export const Container = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
`;
export const PostCommentWrapper = styled.div<{ isReply: boolean }>`
  width: 100%;
  display: flex;
  justify-content: ${(p) => (p.isReply ? "start" : "end")};
`;
export const Post = styled.div<{ isReply: boolean; question?: boolean }>`
  margin: 10px 20px;
  padding: 10px;
  align-self: flex-start;
  border-radius: 5px;
  background-color: ${(p) =>
    // eslint-disable-next-line no-nested-ternary
    p.isReply
      ? p.question
        ? "var(--kssc-pastel-yellow)"
        : "var(--kssc-teal)"
      : p.question
      ? "var(--kssc-light-red)"
      : "var(--kssc-yellow)"};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 15px;
  max-width: 60%;
  min-width: 40%;
  box-shadow: var(--kssc-box-shadow);
`;
export const BackButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;
`;
export const CommentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  height: 67vh;
  overflow: auto;
  background-color: var(--kssc-light-yellow);
  border-radius: 5px;
  box-shadow: rgb(223, 222, 222) 0px 0px 6px 1px inset;
`;
export const NewComment = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 25px;
  button {
    height: 40px;
  }
`;
export const EditPost = styled.div`
  display: flex;
  gap: 10px;
  button {
    height: 40px;
    background-color: var(--kssc-dark-teal);
  }
  width: 100%;
`;
export const PostDetails = styled.div`
  display: flex;
  gap: 10px;
`;
export const IconButtons = styled.div`
  display: flex;
  button {
    border: none;
    color: var(--kssc-white);
    background: none;
    cursor: pointer;
  }
`;
export const IconButton = styled.div`
  display: flex;
  button {
    width: 30px;
    height: 30px;
    border: none;
    color: var(--kssc-white);
    background: #fff9ec9e;
    box-shadow: 1px 1px 8px rgb(0 0 0 / 0.3);
    border-radius: 5px;
    padding: 5px;
    cursor: pointer;
  }
`;
export const PostActions = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
export const CommentInput = styled.textarea`
  background: var(--kssc-light-red);
  border: none;
  width: 100%;
  outline: none;
  border-radius: 4px;
  &:focus {
    outline: 2px solid var(--kssc-white);
  }
`;
export const PostInput = styled.input`
  background: var(--kssc-light-red);
  border: none;
  border-radius: 4px;
  width: 100%;
  outline: none;
  padding-left: 5px;
  &:focus {
    outline: 2px solid var(--kssc-white);
  }
`;
export const PostTitle = styled.div`
  position: sticky;
  padding: 10px;
  width: 100%;
  background-color: #f58a8f9c;
  top: 0;
  display: flex;
  justify-content: space-between;
  input,
  span {
    color: var(--kssc-dark-teal);
    font-size: var(--kssc-lg);
  }
`;
export const Checkbox = styled.input``;
export const FirstRow = styled.div`
  display: flex;
  align-items: space-between;
  width: 100%;
`;
export const LabelComponent = styled.label`
  display: flex;
  align-items: center;
  padding-left: 10px;
  color: var(--kssc-dark-teal);
  input {
    margin-left: 10px;
  }
  &:focus-within {
    color: var(--kssc-teal);
  }
`;
export const NothingDiv = styled.div`
  font-size: var(--kssc-3xl);
  color: #2cbcb34d;
  font-weight: bold;
  text-align: center;
  padding: 20px;
`;
