import { createContext, Dispatch, FC, Reducer, useContext } from "react";
import { AsyncActionHandlers, useReducerAsync } from "use-reducer-async";
import { api } from "../api";
import { handleError } from "../helpers/handlerError";
import { Blog } from "../types";

export type PageState = {
  loading: boolean;
  error: string | null;
  blogs: Blog[];
};

type SyncAction =
  | { type: "BEGIN_REQUEST" }
  | { type: "CLEAN_ALL" }
  | { type: "REQUEST_FAILURE"; error: string }
  | { type: "REQUEST_SUCCESS"; blogs: Blog[] }
  | { type: "INIT_SUCCESS"; blogs: Blog[] };

type AsyncAction =
  | { type: "FETCH_BLOGS"; kind: Blog["kind"] }
  | {
      type: "ADD_BLOG";
      title_kor: string;
      title_eng: string;
      description_kor: string;
      description_eng: string;
      image: string | null;
      kind: Blog["kind"];
    }
  | { type: "DELETE_BLOG"; id: number }
  | { type: "UPDATE_BLOG"; blog: Blog };

export type BlogsAction = SyncAction | AsyncAction;

export const initialState: PageState = {
  loading: false,
  error: null,
  blogs: [],
};

export const reducer: Reducer<PageState, SyncAction> = (
  state,
  action
): PageState => {
  console.info("action", action);
  switch (action.type) {
    case "BEGIN_REQUEST":
      return { ...state, loading: true, error: null };
    case "CLEAN_ALL":
      return { ...state, loading: false, error: null };
    case "REQUEST_SUCCESS":
      return {
        ...state,
        loading: false,
        error: null,
        blogs: action.blogs,
      };
    case "INIT_SUCCESS":
      return {
        ...state,
        loading: false,
        error: null,
        blogs: action.blogs,
      };
    case "REQUEST_FAILURE":
      return { ...state, error: action.error, loading: false };
    default:
      return state;
  }
};

export const asyncActionHandlers: AsyncActionHandlers<
  Reducer<PageState, BlogsAction>,
  AsyncAction
> = {
  FETCH_BLOGS:
    ({ dispatch }) =>
    async ({ kind }) => {
      dispatch({ type: "BEGIN_REQUEST" });
      try {
        const blogs = await api.blogs.index(kind);
        dispatch({
          type: "INIT_SUCCESS",
          blogs,
        });
      } catch (e) {
        console.error(e);
        const error = handleError(e);
        dispatch({ type: "REQUEST_FAILURE", error });
      }
    },
  ADD_BLOG:
    ({ dispatch, getState }) =>
    async (action: {
      title_kor: string;
      description_kor: string;
      title_eng: string;
      description_eng: string;
      image: string | null;
      kind: Blog["kind"];
    }) => {
      dispatch({ type: "BEGIN_REQUEST" });
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const {
        title_kor,
        description_kor,
        title_eng,
        description_eng,
        image,
        kind,
      } = action;
      try {
        const blog = await api.blogs.create({
          blog: {
            title_kor,
            description_kor,
            title_eng,
            description_eng,
            image,
            kind,
          },
        });
        dispatch({
          type: "REQUEST_SUCCESS",
          blogs: [...getState().blogs, blog],
        });
      } catch (e) {
        console.error(e);
        const error = handleError(e);
        dispatch({ type: "REQUEST_FAILURE", error });
      }
    },
  UPDATE_BLOG:
    ({ dispatch, getState }) =>
    async (action: { blog: Blog }) => {
      dispatch({ type: "BEGIN_REQUEST" });
      const { blog } = action;
      try {
        const blogResponse = await api.blogs.update(blog);
        dispatch({
          type: "REQUEST_SUCCESS",
          blogs: [
            ...getState().blogs.filter((p) => p.id !== blog.id),
            blogResponse,
          ],
        });
      } catch (e) {
        console.error(e);
        const error = handleError(e);
        dispatch({ type: "REQUEST_FAILURE", error });
      }
    },
  DELETE_BLOG:
    ({ dispatch, getState }) =>
    async (action: { id: number }) => {
      dispatch({ type: "BEGIN_REQUEST" });
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { id } = action;
      try {
        await api.blogs.delete(id);
        dispatch({
          type: "REQUEST_SUCCESS",
          blogs: getState().blogs.filter((p) => p.id !== id),
        });
      } catch (e) {
        console.error(e);
        const error = handleError(e);
        dispatch({ type: "REQUEST_FAILURE", error });
      }
    },
};

const PageContext = createContext<{
  state: PageState;
  dispatch: Dispatch<BlogsAction>;
}>({
  state: initialState,
  dispatch: () => undefined,
});

export const useBlogsContext = () => useContext(PageContext);

type Props = {
  overrideInitialState?: PageState;
  dispatchOverride?: Dispatch<BlogsAction>;
};

export const BlogsProvider: FC<Props> = ({
  dispatchOverride,
  children,
  ...overrideInitialState
}) => {
  const [state, dispatch] = useReducerAsync<
    Reducer<PageState, SyncAction>,
    AsyncAction,
    BlogsAction
  >(reducer, { ...initialState, ...overrideInitialState }, asyncActionHandlers);

  const d = dispatchOverride || dispatch;

  return (
    <PageContext.Provider value={{ state, dispatch: d }}>
      {children}
    </PageContext.Provider>
  );
};
