import { FC } from "react";

import * as S from "./Pages.css";
import { useAppContext } from "../contexts/AppContext";
import { useTranslation } from "react-i18next";

export const StaffIntroduction: FC = () => {
  const {
    state: { locale },
  } = useAppContext();
  const { t } = useTranslation();
  return (
    <S.Container>
      <S.Title>{t("org-chart")}</S.Title>
      <S.Chart>
        <img
          src={
            locale === "kr"
              ? "/images/org-chart-ko.png"
              : "/images/org-chart-en.png"
          }
          alt="조직도"
        />
      </S.Chart>
      <S.Title>{t("important-people")}</S.Title>
    </S.Container>
  );
};
