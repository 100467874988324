import { FC } from "react";
import { WIP } from "../electrons";

import * as S from "./Pages.css";

export const MediaReleases: FC = () => (
  <S.Container>
    <S.Title>보도자료</S.Title>
    <S.Text>
      <WIP />
    </S.Text>
  </S.Container>
);
