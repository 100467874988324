import styled from "styled-components";
import { MEDIA } from "../styles";

export const Divider = styled.div`
  margin: 0;
  flex-shrink: 0;
  border-width: 0;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.12);
  border-bottom-width: thin;
  display: flex;
  white-space: nowrap;
  text-align: center;
  border: 0;
  color: var(--kssc-pastel-teal);
  padding: 0 10px 5px 10px;
  &::before,
  ::after {
    position: relative;
    width: 100%;
    border-top: thin solid var(--kssc-pastel-teal);
    top: 50%;
    content: "";
    -webkit-transform: translateY(50%);
    -moz-transform: translateY(50%);
    -ms-transform: translateY(50%);
    transform: translateY(50%);
  }
  span {
    padding: 0 10px;
  }
`;

export const Item = styled.div<{ background: string }>`
  box-shadow: var(--kssc-paper-shadow);
  color: var(--kssc-dark-teal);
  margin: 10px;
  border-radius: 5px;
  background-color: ${(p) => p.background};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
  font-size: var(--kssc-lg);
  @media (max-width: ${MEDIA.xs}) {
    flex-direction: column;
    button {
      width: 90%;
    }
  }
`;
export const Success = styled.div`
  color: var(--kssc-dark-teal);
  border-radius: 5px;
  background-color: var(--kssc-light-teal);
  padding: 10px;
  font-size: var(--kssc-lg);
  margin: 20px;
  display: flex;
  align-items: center;
  svg {
    padding-left: 5px;
  }
`;
export const Stack = styled.div`
  display: flex;
  flex-direction: column;
`;
export const PostTitle = styled.span`
  max-width: 200px;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  margin-bottom: -3px;
  @media (max-width: ${MEDIA.xs}) {
    width: 100px;
  }
`;
export const ItemText = styled.span`
  padding: 5px;

  em {
    padding-left: 5px;
  }
  @media (max-width: ${MEDIA.xs}) {
    width: 100%;
  }
`;
