import { IKImage } from "imagekitio-react";
import { Dispatch, FC, memo, SetStateAction, useState } from "react";
import { Icon } from "../electrons";

import * as S from "./PhotoShow.css";
import { Photo } from "../types";
import { Button } from "../atoms";

type Props = {
  setThumbnail: Dispatch<SetStateAction<Photo | undefined>>;
  photo: Photo;
  isEditingAlbum: boolean;
  thumbnail: Photo | undefined;
  onDelete: (id: number) => void;
  onEdit: (photo: Photo) => void;
  viewImage: () => void;
};

const PhotoComponent: FC<Props> = ({
  setThumbnail,
  photo,
  isEditingAlbum,
  thumbnail,
  onDelete,
  onEdit,
  viewImage,
}) => {
  const urlEndpoint = "https://ik.imagekit.io/kssc";
  const [mode, setMode] = useState<"view" | "delete" | "edit">("view");
  const [title, setTitle] = useState<string>(photo.title);

  return (
    <>
      <S.Photo isSelected={isEditingAlbum && thumbnail?.id === photo.id}>
        {
          <IKImage
            onClick={() => (isEditingAlbum ? setThumbnail(photo) : viewImage())}
            urlEndpoint={urlEndpoint}
            path={photo.image}
            width="30%"
            height="30%"
            transformation={[{ height: 200, width: 200 }]}
            lqip={{ active: true }}
            loading="lazy"
          />
        }
        <figcaption>
          {mode === "view" && <>{photo.title}</>}{" "}
          {mode === "delete" && isEditingAlbum && (
            <S.ActionContainer>
              <strong>Are you sure you want to delete this photo?</strong>
              {
                <S.ButtonsContainer>
                  <Button
                    onClick={() => onDelete(photo.id)}
                    color="var(--kssc-green)"
                  >
                    삭제
                  </Button>
                  <Button
                    color="var(--kssc-grey)"
                    onClick={() => {
                      setMode("view");
                    }}
                  >
                    취소
                  </Button>
                </S.ButtonsContainer>
              }
            </S.ActionContainer>
          )}
          {mode === "edit" && isEditingAlbum && (
            <S.EditContainer>
              <S.TitleInput
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
              <S.ActionButton
                onClick={() => {
                  onEdit({ ...photo, title });
                  setMode("view");
                }}
              >
                <Icon iconName={"paper-plane"} />
              </S.ActionButton>
              <S.ActionButton
                color="var(--kssc-grey)"
                onClick={() => {
                  setMode("view");
                }}
              >
                <Icon iconName={"rotate-left"} />
              </S.ActionButton>
            </S.EditContainer>
          )}
          {mode !== "delete" && isEditingAlbum && (
            <S.ActionButton
              color="var(--kssc-pastel-red)"
              onClick={() => setMode("delete")}
            >
              <Icon iconName="trash" />
            </S.ActionButton>
          )}
          {mode !== "edit" && isEditingAlbum && (
            <S.ActionButton
              color="var(--kssc-pastel-yellow)"
              onClick={() => {
                setMode("edit");
              }}
            >
              <Icon iconName="pencil" />
            </S.ActionButton>
          )}
        </figcaption>
      </S.Photo>
    </>
  );
};

export const PhotoShow = memo(PhotoComponent);
