import { createContext, Dispatch, FC, Reducer, useContext } from "react";
import { AsyncActionHandlers, useReducerAsync } from "use-reducer-async";
import { api } from "../api";
import { handleError } from "../helpers/handlerError";
import { Album } from "../types";

export type PageState = {
  loading: boolean;
  error: string | null;
  albums: Album[];
};

type SyncAction =
  | { type: "BEGIN_REQUEST" }
  | { type: "CLEAN_ALL" }
  | { type: "REQUEST_FAILURE"; error: string }
  | { type: "REQUEST_SUCCESS"; albums: Album[] };

type AsyncAction =
  | { type: "FETCH_ALBUMS" }
  | {
      type: "CREATE_ALBUM";
      title_kor: string;
      title_eng: string;
      description_kor: string;
      description_eng: string;
    }
  | { type: "UPDATE_ALBUM"; album: Album }
  | { type: "DELETE_ALBUM"; id: number };

export type AlbumAction = SyncAction | AsyncAction;

export const initialState: PageState = {
  loading: false,
  error: null,
  albums: [],
};

export const reducer: Reducer<PageState, SyncAction> = (
  state,
  action
): PageState => {
  switch (action.type) {
    case "BEGIN_REQUEST":
      return { ...state, loading: true, error: null };
    case "CLEAN_ALL":
      return { ...state, loading: false, error: null };
    case "REQUEST_SUCCESS":
      return { ...state, loading: false, error: null, albums: action.albums };
    case "REQUEST_FAILURE":
      return { ...state, error: action.error, loading: false };
    default:
      return state;
  }
};

export const asyncActionHandlers: AsyncActionHandlers<
  Reducer<PageState, AlbumAction>,
  AsyncAction
> = {
  FETCH_ALBUMS:
    ({ dispatch }) =>
    async () => {
      dispatch({ type: "BEGIN_REQUEST" });
      try {
        const albums = await api.albums.index();
        dispatch({
          type: "REQUEST_SUCCESS",
          albums,
        });
      } catch (e) {
        console.error(e);
        const error = handleError(e);
        dispatch({ type: "REQUEST_FAILURE", error });
      }
    },
  CREATE_ALBUM:
    ({ dispatch }) =>
    async (action: {
      title_kor: string;
      title_eng: string;
      description_kor: string;
      description_eng: string;
    }) => {
      dispatch({ type: "BEGIN_REQUEST" });
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { title_kor, description_kor, title_eng, description_eng } = action;
      try {
        await api.albums.create({
          album: { title_kor, description_kor, title_eng, description_eng },
        });
        const albums = await api.albums.index();
        dispatch({
          type: "REQUEST_SUCCESS",
          albums,
        });
      } catch (e) {
        console.error(e);
        const error = handleError(e);
        dispatch({ type: "REQUEST_FAILURE", error });
      }
    },
  UPDATE_ALBUM:
    ({ dispatch }) =>
    async (action: { album: Album }) => {
      dispatch({ type: "BEGIN_REQUEST" });
      const { album } = action;
      try {
        await api.albums.update(album);
        const albums = await api.albums.index();
        dispatch({
          type: "REQUEST_SUCCESS",
          albums,
        });
      } catch (e) {
        console.error(e);
        const error = handleError(e);
        dispatch({ type: "REQUEST_FAILURE", error });
      }
    },
  DELETE_ALBUM:
    ({ dispatch }) =>
    async (action: { id: number }) => {
      dispatch({ type: "BEGIN_REQUEST" });
      const { id } = action;
      try {
        await api.albums.delete(id);
        const albums = await api.albums.index();
        dispatch({
          type: "REQUEST_SUCCESS",
          albums,
        });
      } catch (e) {
        console.error(e);
        const error = handleError(e);
        dispatch({ type: "REQUEST_FAILURE", error });
      }
    },
};

const PageContext = createContext<{
  state: PageState;
  dispatch: Dispatch<AlbumAction>;
}>({
  state: initialState,
  dispatch: () => undefined,
});

export const useAlbumContext = () => useContext(PageContext);

type Props = {
  overrideInitialState?: PageState;
  dispatchOverride?: Dispatch<AlbumAction>;
};

export const AlbumProvider: FC<Props> = ({
  dispatchOverride,
  children,
  ...overrideInitialState
}) => {
  const [state, dispatch] = useReducerAsync<
    Reducer<PageState, SyncAction>,
    AsyncAction,
    AlbumAction
  >(reducer, { ...initialState, ...overrideInitialState }, asyncActionHandlers);

  const d = dispatchOverride || dispatch;

  return (
    <PageContext.Provider value={{ state, dispatch: d }}>
      {children}
    </PageContext.Provider>
  );
};
