import styled from "styled-components";
import { MEDIA } from "../styles";
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--kssc-white);
  padding: 20px;
  gap: 25px;
  position: relative;

  svg {
    font-size: 30px;
    @media (max-width: ${MEDIA.xs}) {
      font-size: 20px;
    }
  }
`;

export const Text = styled.div`
  font-size: var(--kssc-lg);
  @media (max-width: ${MEDIA.xs}) {
    font-size: var(--kssc-sm);
  }
`;
export const Title = styled.div`
  font-size: var(--kssc-xlg);
  color: var(--kssc-pastel-teal);
  font-weight: bold;
  @media (max-width: ${MEDIA.xs}) {
    font-size: var(--kssc-md);
  }
`;
