import * as ActionCable from "@rails/actioncable";
import { createContext, Dispatch, FC, Reducer, useContext } from "react";
import { AsyncActionHandlers, useReducerAsync } from "use-reducer-async";
import { environment, Environment } from "../helpers/environment";
import { PostCommentAction } from "./PostCommentsContext";
import { NotificationAction } from "./NotificationsContext";
import { PostAction } from "./PostsContext";
import { toast } from "react-toastify";
import { Consumer } from "@rails/actioncable";
import { Post, Question, User } from "../types";
import { QuestionCommentAction } from "./QuestionCommentsContext";
import { QuestionAction } from "./QuestionsContext";

const ToastMessage = ({ post }: { post: Post | Question }) => (
  <div>
    A new {"confidential" in post ? "question" : "post"} has been added:{" "}
    {post.title}
  </div>
);

export type PageState = {
  consumer: Consumer | null;
  postChannel: ActionCable.Subscription<ActionCable.Consumer> | null;
  questionChannel: ActionCable.Subscription<ActionCable.Consumer> | null;
  systemChannel: ActionCable.Subscription<ActionCable.Consumer> | null;
  notificationsChannel: ActionCable.Subscription<ActionCable.Consumer> | null;
};

type SyncAction =
  | {
      type: "SET_CONSUMER";
      consumer: Consumer | null;
    }
  | {
      type: "SET_CHANNEL";
      channelName: "post" | "notifications" | "system" | "question";
      channel: ActionCable.Subscription<ActionCable.Consumer> | null;
    };

type AsyncAction =
  | {
      type: "INIT";
      postsDispatch: Dispatch<PostAction>;
      questionsDispatch: Dispatch<QuestionAction>;
      user: User | null;
    }
  | {
      type: "DISCONNECT";
    }
  | {
      type: "UNSUBSCRIBE";
      channelName: "post" | "notifications" | "system" | "question";
    }
  | {
      type: "POST_SUBSCRIBE";
      postId: number;
      postCommentsDispatch: Dispatch<PostCommentAction>;
    }
  | {
      type: "QUESTION_SUBSCRIBE";
      questionId: number;
      questionCommentsDispatch: Dispatch<QuestionCommentAction>;
    }
  | {
      type: "NOTIFICATIONS_SUBSCRIBE";
      notificationsDispatch: Dispatch<NotificationAction>;
    };

type Action = SyncAction | AsyncAction;

export const initialState: PageState = {
  consumer: null,
  postChannel: null,
  notificationsChannel: null,
  systemChannel: null,
  questionChannel: null,
};

// eslint-disable-next-line consistent-return
export const reducer: Reducer<PageState, SyncAction> = (
  state,
  action
  // eslint-disable-next-line consistent-return
): PageState => {
  switch (action.type) {
    case "SET_CONSUMER":
      return { ...state, consumer: action.consumer };
    case "SET_CHANNEL":
      switch (action.channelName) {
        case "post":
          return { ...state, postChannel: action.channel };
        case "question":
          return { ...state, questionChannel: action.channel };
        case "notifications":
          return { ...state, notificationsChannel: action.channel };
        case "system":
          return { ...state, systemChannel: action.channel };
      }
  }
};

export const asyncActionHandlers: AsyncActionHandlers<
  Reducer<PageState, Action>,
  AsyncAction
> = {
  INIT:
    ({ dispatch }) =>
    async ({ postsDispatch, questionsDispatch, user }) => {
      try {
        if (!user) return;
        const actionCableUrl =
          environment() === Environment.prod
            ? "wss://server.kssc-ab.ca/cable"
            : "http://localhost:3000/cable";
        const consumer = ActionCable.createConsumer(actionCableUrl);
        dispatch({
          type: "SET_CONSUMER",
          consumer,
        });
        const systemChannel = consumer.subscriptions.create(
          { channel: "SystemChannel" },
          {
            connected() {},
            disconnected() {},
            received(data) {
              if (data.post && data.type === "post_added") {
                if (user?.role === "kssc_admin")
                  toast(<ToastMessage post={data.post} />);

                postsDispatch({
                  type: "ADD_FROM_WS",
                  post: data.post,
                });
              }
              if (data.question && data.type === "question_added") {
                if (user?.role === "kssc_admin")
                  toast(<ToastMessage post={data.question} />);

                questionsDispatch({
                  type: "ADD_FROM_WS",
                  question: data.question,
                });
              }
            },
          }
        );
        dispatch({
          type: "SET_CHANNEL",
          channelName: "system",
          channel: systemChannel,
        });
      } catch (e) {
        console.error(e);
      }
    },
  DISCONNECT:
    ({ dispatch, getState }) =>
    async () => {
      try {
        const systemChannel = getState().systemChannel;
        systemChannel?.unsubscribe();
        dispatch({ type: "SET_CHANNEL", channelName: "system", channel: null });
        const consumer = getState().consumer;
        if (!consumer) return;
        consumer.disconnect();
        dispatch({
          type: "SET_CONSUMER",
          consumer: null,
        });
      } catch (e) {
        console.error(e);
      }
    },
  UNSUBSCRIBE:
    ({ dispatch, getState }) =>
    async ({ channelName }) => {
      try {
        const consumer = getState().consumer;
        if (!consumer) return;
        const postChannel = getState().postChannel;
        const questionChannel = getState().questionChannel;
        const notificationsChannel = getState().notificationsChannel;
        const systemChannel = getState().systemChannel;
        switch (channelName) {
          case "post":
            postChannel?.unsubscribe();
            break;
          case "question":
            questionChannel?.unsubscribe();
            break;
          case "system":
            systemChannel?.unsubscribe();
            break;
          case "notifications":
            notificationsChannel?.unsubscribe();
        }
        dispatch({ type: "SET_CHANNEL", channelName, channel: null });
      } catch (e) {
        console.error(e);
      }
    },
  POST_SUBSCRIBE:
    ({ getState, dispatch }) =>
    async ({ postId, postCommentsDispatch }) => {
      try {
        const consumer = getState().consumer;
        if (!consumer) return;
        const postChannel = consumer.subscriptions.create(
          { channel: "PostChannel", id: postId },
          {
            connected() {
              console.info("conncted to posts");
            },
            disconnected() {},
            received(data) {
              console.info(data);
              if (
                data.post_comment &&
                ["post_comment_added", "post_comment_updated"].includes(
                  data.type
                )
              )
                postCommentsDispatch({
                  type:
                    data?.type === "post_comment_added"
                      ? "ADD_FROM_WS"
                      : "UPDATE_FROM_WS",
                  post_comment: data.post_comment,
                });
              if (data.post_comment_id && data?.type === "post_comment_deleted")
                postCommentsDispatch({
                  type: "DELETE_FROM_WS",
                  post_comment_id: Number(JSON.parse(data.post_comment_id)),
                });
            },
          }
        );
        dispatch({
          type: "SET_CHANNEL",
          channelName: "post",
          channel: postChannel,
        });
      } catch (e) {
        console.error(e);
      }
    },
  QUESTION_SUBSCRIBE:
    ({ getState, dispatch }) =>
    async ({ questionId, questionCommentsDispatch }) => {
      try {
        const consumer = getState().consumer;
        if (!consumer) return;
        const questionChannel = consumer.subscriptions.create(
          { channel: "QuestionChannel", id: questionId },
          {
            connected() {
              console.info("connected to questions!", questionId);
            },
            disconnected() {},
            received(data) {
              console.info(data);
              if (
                data.question_comment &&
                ["question_comment_added", "question_comment_updated"].includes(
                  data.type
                )
              )
                questionCommentsDispatch({
                  type:
                    data?.type === "question_comment_added"
                      ? "ADD_FROM_WS"
                      : "UPDATE_FROM_WS",
                  question_comment: data.question_comment,
                });
              if (
                data.question_comment_id &&
                data?.type === "question_comment_deleted"
              )
                questionCommentsDispatch({
                  type: "DELETE_FROM_WS",
                  question_comment_id: Number(
                    JSON.parse(data.question_comment_id)
                  ),
                });
            },
          }
        );
        dispatch({
          type: "SET_CHANNEL",
          channelName: "question",
          channel: questionChannel,
        });
      } catch (e) {
        console.error(e);
      }
    },
  NOTIFICATIONS_SUBSCRIBE:
    ({ getState, dispatch }) =>
    async ({ notificationsDispatch }) => {
      try {
        const consumer = getState().consumer;
        if (!consumer) return;
        const notificationsChannel = consumer.subscriptions.create(
          { channel: "NotificationsChannel" },
          {
            connected() {},
            disconnected() {},
            received(data) {
              if (data.notification && data.type === "notification_added")
                notificationsDispatch({
                  type: "ADD_FROM_WS",
                  notification: data.notification,
                });
              if (data.notification_id && data?.type === "notification_deleted")
                notificationsDispatch({
                  type: "DELETE_FROM_WS",
                  notification_id: Number(JSON.parse(data.notification_id)),
                });
            },
          }
        );
        dispatch({
          type: "SET_CHANNEL",
          channelName: "notifications",
          channel: notificationsChannel,
        });
      } catch (e) {
        console.error(e);
      }
    },
};

const PageContext = createContext<{
  state: PageState;
  dispatch: Dispatch<Action>;
}>({
  state: initialState,
  dispatch: () => undefined,
});

export const useSocket = () => useContext(PageContext);

type Props = {
  overrideInitialState?: PageState;
  dispatchOverride?: Dispatch<Action>;
};

export const SocketProvider: FC<Props> = ({
  dispatchOverride,
  children,
  ...overrideInitialState
}) => {
  const [state, dispatch] = useReducerAsync<
    Reducer<PageState, SyncAction>,
    AsyncAction,
    Action
  >(reducer, { ...initialState, ...overrideInitialState }, asyncActionHandlers);

  const d = dispatchOverride || dispatch;

  return (
    <PageContext.Provider value={{ state, dispatch: d }}>
      {children}
    </PageContext.Provider>
  );
};
