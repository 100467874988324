import styled from "styled-components";
import { Button } from "../atoms";
import { MEDIA } from "../styles";

export const BackButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;
`;
export const Container = styled.div`
  padding: 20px;
`;

export const CreateButton = styled(Button)`
  width: 200px;
  svg {
    padding-right: 5px;
  }
`;
export const BlogsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 20px;
  gap: 15px;
  flex-direction: column;
  @media (max-width: ${MEDIA.xs}) {
    gap: 5px;
  }
`;

export const BlogItem = styled.div`
  display: flex;
  align-items: center;
  color: ${(p) => (p.color ? p.color : "var(--kssc-dark-teal)")};
  width: 100%;
  white-space: pre-wrap;
`;
export const ItemContainer = styled.div`
  background-color: var(--kssc-light-teal);
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 20px;
  margin-right: 35px;
  @media (max-width: ${MEDIA.xs}) {
    padding: 10px;
    margin-right: 15px;

    width: 100%;
    flex-direction: column;

    > * svg {
      font-size: 15px;
    }
  }
`;
export const BlogItemTitle = styled.div`
  font-weight: bold;
  font-size: var(--kssc-3xl);
  @media (max-width: ${MEDIA.xs}) {
    max-width: 140px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;
export const BlogItemContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
  width: 100%;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
  margin-top: 10px;
  button {
    margin: 0;
    padding: 10px 5px;
    width: 50px;
    svg {
      margin-right: 0;
    }
  }
`;

export const Photo = styled.div<{ isSelected?: boolean }>`
  * {
    box-sizing: border-box;
  }
  img {
    height: auto;
    width: auto;
    margin: 10px;
    border-radius: 5px;
    border: ${(p) =>
      p.isSelected ? "25px solid var(--kssc-yellow)" : "unset"};
  }
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--kssc-teal);
  font-size: var(--kssc-sm);
  margin-bottom: 20px;
`;

export const BlogIntro = styled.div`
  white-space: nowrap;
  overflow: hidden;
  width: 50%;
  text-overflow: ellipsis;
`;
export const BlogShowBody = styled.div`
  margin: 20px;
  text-align: center;
`;

export const BlogShowTitle = styled.h1`
  text-align: left;
`;

export const BlogShowText = styled.p`
  text-align: justify;
  white-space: pre-line;
`;
