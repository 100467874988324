import { FC } from "react";
import { Icon } from "../electrons";

import * as S from "./Pages.css";

export const MentalHealthEducation: FC = () => (
  <S.Container>
    <S.Title>정신건강교육</S.Title>
    <S.Text>
      ‘마음의 병이 만병의 근원이다’라고 합니다. 급변하는 사회속에서 이전에는
      미처 신경쓰지 못했던 마음의 건강이 날로 중요해지고 있습니다.
    </S.Text>
    <S.Text>
      한인사회복지센터는 정신건강을 위해 대중교육을 제공하고 있습니다. 나는 어떤
      사람인지, 다른 사람과 어떻게 건강한 의사소통을 할 수 있는지, 정신과 마음을
      건강하게 하기 위해서 어떤 노력들이 필요한지 등에 대해 함께 이야기 합니다.
    </S.Text>
    <S.Text>
      신청문의는
      <S.ContactUsText>
        <Icon iconName="phone" color="var(--kssc-teal)" size="18px" />
        <a href="tel:5873745313">587-374-5313</a>
      </S.ContactUsText>{" "}
      또는
      <S.ContactUsText>
        <Icon iconName="envelope" color="var(--kssc-teal)" size="18px" />
        <span>
          <a href="mailto:general@kssc-ab.ca">general@kssc-ab.ca</a>
        </span>
      </S.ContactUsText>
      로 연락주세요.
    </S.Text>
  </S.Container>
);
