import styled from "styled-components";
import { MEDIA } from "../styles";

export const PaginationButton = styled.button<{ selected?: boolean }>`
  border: var(--kssc-teal) 3px solid;
  box-sizing: border-box;
  background-color: ${(p) =>
    p.selected ? "var(--kssc-white)" : "var(--kssc-teal)"};
  color: ${(p) => (p.selected ? "var(--kssc-teal)" : "var(--kssc-white)")};
  font-weight: ${(p) => (p.selected ? "bold" : "normal")};
  border-radius: 5px;
  font-size: var(--kssc-lg);
  margin: 5px;
  padding: 18px;
  &:hover {
    cursor: pointer;
  }
  &:disabled {
    cursor: unset;
    background-color: var(--kssc-light-grey);
    border-color: var(--kssc-light-grey);
  }

  @media (max-width: ${MEDIA.xs}) {
    font-size: var(--kssc-md);
    padding: 7px;
    margin: 2px;
  }
`;

export const Ellipsis = styled.span`
  background-color: none;
  color: var(--kssc-teal);
  margin: 5px;
  padding: 20px;
  @media (max-width: ${MEDIA.xs}) {
    font-size: var(--kssc-sm);
    padding: 5px;
    margin: 2px;
  }
`;
