// eslint-disable-next-line no-shadow
export enum Environment {
  localhost = "localhost",
  prod = "prod",
}

export const environment = (): Environment => {
  try {
    const hostPrefix = window.location.hostname;
    return hostPrefix.includes("kssc-ab")
      ? Environment.prod
      : Environment.localhost;
  } catch {
    return Environment.localhost;
  }
};
