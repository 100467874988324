import { FC, ReactNode } from "react";
import { Portal } from "../electrons";
import * as S from "./Modal.css";

type Props = {
  children: ReactNode;
  visible: boolean;
};
export const Modal: FC<Props> = ({ visible, children }) => {
  if (!visible) return null;
  return (
    <S.RefDiv id="portal">
      <Portal>
        <S.PopperDiv>{children}</S.PopperDiv>
      </Portal>
    </S.RefDiv>
  );
};
