import styled from "styled-components";
import { Button } from "../atoms";
import { MEDIA } from "../styles";

export const Container = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 500px;
  background: var(--kssc-light-teal);
  border-radius: 8px;
  position: fixed;
  z-index: 3;
  top: 50%;
  left: 0;
  right: 0;
  margin: auto;
  color: var(--kssc-teal);
  box-shadow: var(--kssc-box-shadow);
  font-size: var(--kssc-xlg);
  gap: 10px;
  @media (max-width: ${MEDIA.xs}) {
    width: 100%;
    position: absolute;
  }
`;
export const Item = styled.div<{ visible: boolean }>`
  display: ${(p) => (p.visible ? "flex" : "none")};
  align-items: flex-start;
  span {
    font-size: 15px;
    padding-left: 5px;
  }
  svg {
    margin: 0 5px;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-self: flex-end;
  justify-content: flex-end;
  gap: 20px;
  button {
    margin: 0;
    padding: 10px 5px;
    width: 50px;
    svg {
      margin-right: 0;
    }
  }
`;

export const CloseButton = styled(Button)`
  width: 200px;
  align-self: center;
  svg {
    padding-right: 5px;
  }
`;
export const DeleteConfirm = styled.div`
  font-weight: bold;
`;
