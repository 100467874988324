import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Icon } from "../electrons";
import { useWindowWidth } from "../hooks/useWindowWidth";

import * as S from "./NavBarItem.css";

type NavButtonProps = {
  displayName: string;
  to?: string;
};
type SubNavButtonProps = {
  displayName: string;
  to?: string;
  subItems?: (NavButtonProps & { to: string })[];
};

type Props = {
  iconName: string;
  mainButton: NavButtonProps;
  subMenuItems?: SubNavButtonProps[];
  onClose: () => void;
  breakSpaces?: boolean;
};

export const NavBarItem: FC<Props> = ({
  iconName,
  mainButton,
  subMenuItems,
  onClose,
  breakSpaces,
}) => {
  const width = useWindowWidth();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [subItemIsOpen, setSubItemIsOpen] = useState<string | null>(null);
  const navigate = useNavigate();
  return (
    <S.NavSection
      onMouseEnter={() => width > 706 && setIsOpen(true)}
      onMouseLeave={() => width > 706 && setIsOpen(false)}
    >
      <S.NavItem
        hoverable={!!mainButton.to}
        onClick={() => {
          isOpen ? setIsOpen(false) : setIsOpen(true);
          !!mainButton.to && navigate(mainButton.to);
          !subMenuItems && onClose();
        }}
      >
        <S.NavTitle
          style={{ whiteSpace: breakSpaces ? "break-spaces" : "unset" }}
        >
          <Icon iconName={iconName} size="20px" />
          {mainButton.displayName}
        </S.NavTitle>
        {subMenuItems &&
          (isOpen ? (
            <Icon
              iconName={
                width > 706 ? "fa-solid fa-angle-up" : "fa-solid fa-arrow-up"
              }
            />
          ) : (
            <Icon
              iconName={
                width > 706
                  ? "fa-solid fa-angle-down"
                  : "fa-solid fa-arrow-down"
              }
            />
          ))}
      </S.NavItem>
      <S.NavSubItemsContainer isVisible={isOpen}>
        {subMenuItems?.map((i) => (
          <S.Wrapper key={i.to}>
            <S.NavSubItem
              onClick={(e) => {
                if (!i.to) return;
                subItemIsOpen
                  ? setSubItemIsOpen(null)
                  : setSubItemIsOpen(i.displayName);
                e.stopPropagation();
                !i.subItems && setIsOpen(false);
                navigate(i.to);
              }}
              onMouseEnter={() =>
                i.subItems && width > 706 && setSubItemIsOpen(i.displayName)
              }
              onMouseLeave={() =>
                i.subItems && width > 706 && setSubItemIsOpen(null)
              }
            >
              <span>{i.displayName}</span>
              {i.subItems && (
                <Icon
                  iconName={
                    // eslint-disable-next-line no-nested-ternary
                    width > 706
                      ? "fa-solid fa-arrow-right"
                      : subItemIsOpen === i.displayName
                      ? "fa-solid fa-arrow-up"
                      : "fa-solid fa-arrow-down"
                  }
                />
              )}
              {width > 706 && (
                <S.NavSubSubItemsContainer
                  isVisible={subItemIsOpen === i.displayName}
                >
                  {i.subItems?.map((s) => (
                    <S.NavSubSubItem
                      key={`wide-${s.to}`}
                      onClick={(e) => {
                        e.stopPropagation();
                        setIsOpen(false);
                        navigate(s.to);
                      }}
                    >
                      {s.displayName}
                    </S.NavSubSubItem>
                  ))}
                </S.NavSubSubItemsContainer>
              )}
            </S.NavSubItem>
            {width <= 706 && (
              <S.NavSubSubItemsContainer
                isVisible={subItemIsOpen === i.displayName}
              >
                {i.subItems?.map((s) => (
                  <S.NavSubSubItem
                    key={`mobile-${s.to}`}
                    onClickCapture={(e) => {
                      e.stopPropagation();
                      onClose();
                      navigate(s.to);
                    }}
                  >
                    {s.displayName}
                  </S.NavSubSubItem>
                ))}
              </S.NavSubSubItemsContainer>
            )}
          </S.Wrapper>
        ))}
      </S.NavSubItemsContainer>
    </S.NavSection>
  );
};
