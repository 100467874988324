import { FC } from "react";
import { Link } from "react-router-dom";
import { Icon } from "../electrons";

import * as S from "./Pages.css";

export const Sympathy: FC = () => (
  <S.Container>
    <S.Title>공감</S.Title>
    <S.SubTitle>이용방법 </S.SubTitle>
    <S.ContactFormText>
      {" "}
      <S.IconWrapper>
        <Icon iconName="phone" color="var(--kssc-dark-teal)" />
      </S.IconWrapper>
      <S.ContactTitle>전화</S.ContactTitle>
      <S.ContactText gridArea="text2">
        <a href="tel:5873745313">587-374-5313</a>
      </S.ContactText>
    </S.ContactFormText>
    <S.ContactFormText>
      {" "}
      <S.IconWrapper>
        <Icon iconName="computer" color="var(--kssc-dark-teal)" />
      </S.IconWrapper>
      <S.ContactTitle>온라인</S.ContactTitle>
      <S.ContactText gridArea="text2">
        1. 이메일 상담: general@kssc-ab.ca 제목에 ‘공감’ 프로그램 신청합니다 로
        기입해주세요.
      </S.ContactText>
      <S.ContactText gridArea="text3">
        {" "}
        2. <Link to="/online-counselling">홈페이지 게시판 이용 </Link>
      </S.ContactText>
    </S.ContactFormText>

    <S.Text>
      심리정서지원 프로그램 <strong>‘공감’</strong>은 누구나 이용하실 수 있는
      서비스로 마음이 어려움이 있거나 힘든일이 있는 부분에 대해서 익명성을
      보장받으며 이야기 할 수 있습니다.{" "}
    </S.Text>

    <S.Text>
      그 어디에도 털어놓을 수 없는 이야기들을 이야기하시면서 마음의 답답함과
      감정의 어려움을 조금이나마 내려놓으실 수 있도록 지원하고 있는
      프로그램입니다.
    </S.Text>
    <S.Title>심리정서지원프로그램</S.Title>
    <S.SubTitle>심리정서지원 (Emotional Support Program)은</S.SubTitle>
    <S.List>
      <li>
        <strong>공감:</strong> 친구, 가족은 물론 누구에게도 털어놓기 힘든 마음의
        소리를 온라인 및 전화로 털어놓을 수 있는 ‘익명’ 상담 서비스입니다.
      </li>
      <li>
        <strong>정신건강교육:</strong> 현대 사회에서는 몸의 건강만큼이나 마음
        건강이 얼마나 삶의 질을 좌우하는지 많은 연구결과들을 통해
        입증되었습니다. 나를 돌아보는 방법을 배우고 내면의 소리에 귀를
        기울임으로써 건강하고 올바른 마음 관리를 위해 한인사회복지센터에서는
        전문가들을 모시고 정신 건강 교육을 합니다.
      </li>
    </S.List>
    <S.TextAlt1>
      ‘공감’ 프로그램은 전화상담과 이메일상담으로 기관 내부 교육을 이수한
      자원상담사들과 진행하며, 모두 익명성이 보장됩니다. 한인사회복지센터는
      상담사의 윤리강령을 준수하며, 여러분의 어렵고 힘든 일상을 함께
      나누겠습니다.
    </S.TextAlt1>
    <S.Text>
      정신건강교육프로그램은 대중 교육 및 단체 상담 활동으로, 심리 정서와 밀접한
      관계를 맺고 있는 의사소통 방법과 이민사회와 생활에 대해서 함께 이야기 할
      수 있는 프로그램 입니다.
    </S.Text>
  </S.Container>
);
