import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC } from "react";

type Props = {
  iconName: string;
  size?: string;
  color?: string;
  spin?: boolean;
};

export const Icon: FC<Props> = ({ iconName, size, color, spin }) => {
  return (
    <FontAwesomeIcon
      icon={iconName as IconProp}
      fontSize={size}
      color={color}
      spin={spin}
    />
  );
};
