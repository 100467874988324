import styled from "styled-components";
import { MEDIA } from "../styles";

export const Container = styled.div`
  color: var(--kssc-dark-teal);
  display: inline-flex;
  justify-content: flex-end;
  @media (max-width: ${MEDIA.nav}) {
    flex-direction: column;
    display: block;
    width: 100%;
    background-color: var(--kssc-pastel-yellow);
  }
`;
export const SmallContainer = styled.div`
  display: flex;
  align-items: flex-end;
  font-size: var(--kssc-small);
  justify-content: flex-end;
  align-items: center;
`;
export const CurrentUser = styled.span`
  display: flex;
  align-items: flex-end;
  font-size: var(--kssc-small);
  justify-content: flex-end;
  color: var(--kssc-dark-yellow);
  padding: 0 5px;
  svg {
    padding: 0 5px;
  }
`;
export const BottomContainer = styled.div`
  color: var(--kssc-dark-teal);
  background-color: var(--kssc-white);
  display: flex;
  justify-content: space-evenly;
  font-size: var(--kssc-lg);
  @media (max-width: ${MEDIA.nav}) {
    flex-direction: column;
  }
`;
export const MenuButton = styled.div`
  display: flex;
  align-items: center;
  padding: 1.15rem 1.5rem;
  font-size: var(--kssc-2xl);
  white-space: nowrap;

  svg {
    height: 1em;
    padding-right: 5px;
  }
  &:hover {
    cursor: pointer;
  }
`;
export const SelectWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  padding: 5px 20px 0 0px;
`;
