import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { Icon } from "../electrons";

import * as S from "./NavBarItem.css";

type NavButtonProps = { displayName: string; to: string };

type Props = {
  iconName: string;
  mainButton: NavButtonProps;
  background: string;
  backgroundHover: string;
};

export const NavBarItemBottom: FC<Props> = ({
  iconName,
  mainButton,
  background,
  backgroundHover,
}) => {
  const navigate = useNavigate();
  return (
    <S.NavItemBottom
      background={background}
      backgroundHover={backgroundHover}
      onClick={() => {
        navigate(mainButton.to);
      }}
    >
      <S.NavTitle>
        <Icon iconName={iconName} size="20px" />
        {mainButton.displayName}
      </S.NavTitle>
    </S.NavItemBottom>
  );
};
