import { FC, useEffect, useRef } from "react";
import * as S from "./Loading.css";
import { Icon } from "../electrons";

const useDidMountEffect = (func: Function, deps: boolean[] = []) => {
  const didMount = useRef(false);

  useEffect(() => {
    if (didMount.current) func();
    else didMount.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};

type Props = {
  visible?: boolean;
  onComplete?: () => void;
  error?: boolean;
};
export const Loading: FC<Props> = ({
  visible = true,
  onComplete,
  error = false,
}) => {
  const timer = useRef<NodeJS.Timeout | null>(null);

  useDidMountEffect(() => {
    const handleComplete = () => {
      if (timer.current) {
        clearTimeout(timer.current);
        timer.current = null;
      }
      !!onComplete && !error && onComplete();
    };
    if (!visible && !timer.current) {
      timer.current && clearTimeout(timer.current);
      timer.current = setTimeout(handleComplete, 1);
      handleComplete();
    }

    return handleComplete;
  }, [visible, error]);

  if (!visible || error) return null;
  return (
    <S.Container>
      <S.LoadingIcon>
        <Icon iconName="cog" color="var(--kssc-white)" size="100px" spin />
        <span>로딩</span>
      </S.LoadingIcon>
    </S.Container>
  );
};
