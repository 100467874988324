import { FC, useState, useEffect } from "react";
import { useAppContext } from "../contexts/AppContext";
import { useTimelineContext } from "../contexts/TimelineContext";
import { ErrorMessage, Icon } from "../electrons";

import * as S from "./Timeline.css";
import { Title } from "./Pages.css";
import { CreateTimelineEvent, Loading } from "../molecules";
import moment from "moment";
import { Button } from "../atoms";
import { TimelineEvent } from "../types";
import { useTranslation } from "react-i18next";

export const Timeline: FC = () => {
  const { t } = useTranslation();
  const [isAdding, setIsAdding] = useState<boolean>(false);
  const [isEditingEvent, setIsEditingEvent] = useState<TimelineEvent | null>(
    null
  );
  const [isDeletingEvent, setIsDeletingEvent] = useState<TimelineEvent | null>(
    null
  );
  const {
    state: { user, locale },
  } = useAppContext();
  const {
    dispatch,
    state: { events, loading, error },
  } = useTimelineContext();

  useEffect(() => {
    dispatch({ type: "FETCH_EVENTS" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => () => dispatch({ type: "CLEAN_ALL" }), [dispatch]);
  const pageContent = () => {
    if (isAdding)
      return (
        <CreateTimelineEvent
          onCancel={() => setIsAdding(false)}
          onSubmit={(date, details_kor, details_eng) =>
            dispatch({ type: "CREATE_EVENT", date, details_kor, details_eng })
          }
        />
      );
    if (isEditingEvent)
      return (
        <CreateTimelineEvent
          event={isEditingEvent}
          onCancel={() => setIsEditingEvent(null)}
          onSubmit={(date, detailsKor, detailsEng) => {
            dispatch({
              type: "UPDATE_EVENT",
              event: {
                ...isEditingEvent,
                date,
                details_kor: detailsKor,
                details_eng: detailsEng,
              },
            });
          }}
        />
      );
    return (
      <S.EventsContainer>
        {error && <ErrorMessage error={error} />}
        {events.length === 0 && <div>No timeline events yet!</div>}
        {events.length > 0 &&
          events.map((event) => (
            <S.TimelineItem key={event.id}>
              <S.TimelineItemDate>
                {moment(event.date).format("MM/DD/YYYY")}
              </S.TimelineItemDate>
              {(!isDeletingEvent ||
                (isDeletingEvent && isDeletingEvent.id !== event.id)) && (
                <S.TimelineItemDetails>
                  {locale === "kr" ? event.details_kor : event.details_eng}
                  {user?.role === "kssc_admin" && (
                    <S.ButtonsContainer>
                      <Button onClick={() => setIsEditingEvent(event)}>
                        <Icon iconName="pencil" />
                      </Button>
                      <Button
                        color="var(--kssc-pastel-red)"
                        onClick={() => setIsDeletingEvent(event)}
                      >
                        <Icon iconName="trash" />
                      </Button>
                    </S.ButtonsContainer>
                  )}
                </S.TimelineItemDetails>
              )}
              {!!isDeletingEvent && isDeletingEvent.id === event.id && (
                <S.TimelineItemDetails>
                  <strong>Are you sure you want to delete this event?</strong>
                  {user?.role === "kssc_admin" && (
                    <S.ButtonsContainer>
                      <Button
                        onClick={() =>
                          dispatch({
                            type: "DELETE_EVENT",
                            id: isDeletingEvent.id,
                          })
                        }
                        color="var(--kssc-green)"
                      >
                        삭제
                      </Button>
                      <Button
                        color="var(--kssc-grey)"
                        onClick={() => {
                          setIsDeletingEvent(null);
                          dispatch({ type: "CLEAN_ALL" });
                        }}
                      >
                        취소
                      </Button>
                    </S.ButtonsContainer>
                  )}
                </S.TimelineItemDetails>
              )}
            </S.TimelineItem>
          ))}
      </S.EventsContainer>
    );
  };
  return (
    <S.Container>
      {loading && <Loading />}
      <Title>{t("timeline")}</Title>
      {user?.role === "kssc_admin" && (
        <S.CreateButton onClick={() => setIsAdding(true)} disabled={isAdding}>
          <Icon iconName="calendar-plus" />
          Add Event
        </S.CreateButton>
      )}
      {pageContent()}
    </S.Container>
  );
};
