import { FC, useEffect, useState } from "react";
import { useAppContext } from "../contexts/AppContext";
import { useBlogsContext } from "../contexts/BlogContext";
import { ErrorMessage, Icon } from "../electrons";
import * as S from "./Blogs.css";
import { AddBlog, Loading } from "../molecules";
import { Button } from "../atoms";
import { IKImage } from "imagekitio-react";
import { useNavigate, useParams } from "react-router-dom";
import { Blog } from "../types";

export const BlogShow: FC = () => {
  const urlEndpoint = "https://ik.imagekit.io/kssc";
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [blog, setBlog] = useState<Blog | undefined>(undefined);
  const { blog_id, kind: blogKind } = useParams();
  const navigate = useNavigate();

  const {
    state: { user, locale },
  } = useAppContext();

  const {
    dispatch,
    state: { blogs, loading, error },
  } = useBlogsContext();

  useEffect(() => {
    dispatch({ type: "FETCH_BLOGS", kind: blogKind as Blog["kind"] });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    blog_id && setBlog(blogs.find((b) => b.id === Number(blog_id)));
  }, [blog_id, blogs]);

  useEffect(() => () => dispatch({ type: "CLEAN_ALL" }), [dispatch]);
  if (!blog)
    return (
      <S.Container>
        <div>Oops! We can't find that article.</div>
      </S.Container>
    );

  const pageContent = () => {
    if (isEditing)
      return (
        <AddBlog
          blog={blog}
          onCancel={() => {
            setIsEditing(false);
          }}
          onSubmit={(
            title_kor: string,
            description_kor: string,
            title_eng: string,
            description_eng: string,
            image: string | null
          ) => {
            dispatch({
              type: "UPDATE_BLOG",
              blog: {
                ...blog,
                title_kor,
                description_kor,
                title_eng,
                description_eng,
                image,
              },
            });
          }}
        />
      );
    return (
      <>
        <S.BlogShowBody>
          {blog.image && (
            <IKImage
              urlEndpoint={urlEndpoint}
              path={blog.image}
              width={400}
              height={400}
              transformation={[{ height: 400, width: 400 }]}
              lqip={{ active: true }}
              style={{ float: "left", paddingRight: 20, paddingBottom: 20 }}
            />
          )}
          {locale === "kr" ? (
            <>
              <S.BlogShowTitle>{blog.title_kor}</S.BlogShowTitle>
              <S.BlogShowText>{blog.description_kor}</S.BlogShowText>
            </>
          ) : (
            <>
              <S.BlogShowTitle>{blog.title_eng}</S.BlogShowTitle>
              <S.BlogShowText>{blog.description_eng}</S.BlogShowText>
            </>
          )}
        </S.BlogShowBody>
        {user?.role === "kssc_admin" && (
          <S.ButtonsContainer>
            <Button onClick={() => setIsEditing(true)}>
              <Icon iconName="pencil" />
            </Button>
          </S.ButtonsContainer>
        )}
      </>
    );
  };
  return (
    <S.Container>
      <Loading visible={loading} />
      <S.BackButtonWrapper>
        {
          <Button onClick={() => navigate(-1)}>
            <Icon iconName="arrow-left" />
            Back
          </Button>
        }
      </S.BackButtonWrapper>
      {error && <ErrorMessage error={error} />}
      {pageContent()}
    </S.Container>
  );
};
