import * as S from "./App.css";
import { PageContent } from "./organisms";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { NavBarSmall } from "./atoms";
import { IconWithText, Loading } from "./molecules";
import { Suspense, useEffect } from "react";
import { api } from "./api";
import { Icon } from "./electrons";
import { useAppContext } from "./contexts/AppContext";
import { useSocket } from "./contexts/ActionCableContext";
import { usePostsContext } from "./contexts/PostsContext";
import { useQuestionsContext } from "./contexts/QuestionsContext";
import i18n from "./i18n";

library.add(fas, fab);

function App() {
  const {
    dispatch,
    state: { user },
  } = useAppContext();
  useEffect(() => {
    const getLogin = async (): Promise<void> => {
      const currentLogin = await api.users.show();
      dispatch({ type: "SET_USER", user: currentLogin });
    };
    getLogin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    i18n.on("languageChanged", () =>
      dispatch({ type: "SET_LOCALE", locale: i18n.language as "kr" | "en" })
    );
  }, []);
  const { dispatch: socketDispatch } = useSocket();
  const { dispatch: postsDispatch } = usePostsContext();
  const { dispatch: questionsDispatch } = useQuestionsContext();
  useEffect(() => {
    postsDispatch({ type: "FETCH_POSTS" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    questionsDispatch({ type: "FETCH_QUESTIONS" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    socketDispatch({
      type: "INIT",
      postsDispatch,
      questionsDispatch,
      user,
    });
    return () => socketDispatch({ type: "DISCONNECT" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);
  return (
    <Suspense fallback={<Loading />}>
      <S.PageContainer>
        <S.MainContent>
          <NavBarSmall />
          <PageContent />
        </S.MainContent>
        <S.Footer>
          <S.IconsContainer>
            <IconWithText
              iconName="fa-solid fa-location-dot"
              title="ADDRESS"
              textLine1="4838 Richard Rd SW Calgary"
              textLine2="AB T3E 6L1"
            />
            <IconWithText
              iconName="fa-solid fa-phone"
              title="PHONE"
              textLine1="+1 587 374 5313"
            />
            <IconWithText
              iconName="fa-solid fa-envelope-open"
              title="EMAIL"
              textLine1="general@kssc-ab.ca"
            />
          </S.IconsContainer>
          <S.SocialIcons>
            <a href="https://www.facebook.com/ksscalberta">
              <Icon iconName="fa-brands fa-facebook" />
            </a>
            <a href="https://www.instagram.com/kssc_ab/">
              <Icon iconName="fa-brands fa-instagram" />
            </a>
            <a href="https://ca.linkedin.com/company/kssc-ab">
              <Icon iconName="fa-brands fa-linkedin" />
            </a>
          </S.SocialIcons>
        </S.Footer>
      </S.PageContainer>
    </Suspense>
  );
}

export default App;
