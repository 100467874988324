import styled from "styled-components";
import { Button } from "../atoms";
import { MEDIA } from "../styles";

export const NewsletterData = styled.span`
  display: flex;
  flex-direction: column;
  color: var(--kssc-dark-teal);
  gap: 3px;
  @media (max-width: ${MEDIA.xs}) {
    font-size: var(--kssc-sm);
  }
`;
export const NewsletterTitle = styled.span`
  font-size: var(--kssc-md);
  font-weight: bold;
  @media (max-width: ${MEDIA.xs}) {
    font-size: var(--kssc-sm);
  }
`;
export const NewsletterDate = styled.span`
  font-size: var(--kssc-md);
  font-weight: 100;
  svg {
    padding-left: 10px;
  }
`;

export const NewsletterItemContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 10px 10px 10px;
`;

export const NewslettersContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 20px;
  gap: 5px;
  flex-direction: column;
`;

export const NewsletterItem = styled.div`
  display: flex;
  align-items: center;
  color: ${(p) => (p.color ? p.color : "var(--kssc-dark-teal)")};
  width: 100%;
`;
export const ItemContainer = styled.div`
  background-color: var(--kssc-light-teal);
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 20px;
  margin-right: 35px;
  @media (max-width: ${MEDIA.xs}) {
    padding: 10px;
    width: 100%;
    flex-direction: column;

    > * svg {
      font-size: 15px;
    }
  }
`;

export const PhotoModalWrapper = styled.span`
  border-radius: 4px;
  img {
    width: 100%;
  }
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  border: 10px solid var(--kssc-light-teal);
  background-color: black;
  span {
    padding: 10px;
    font-size: 20px;
    font-weight: 300;
    color: var(--kssc-white);
  }
`;

export const Photo = styled.div`
  * {
    box-sizing: border-box;
  }
  img {
    height: auto;
    width: auto;
    margin: 20px;
    border-radius: 5px;
  }
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--kssc-teal);
  font-size: var(--kssc-sm);
  margin-bottom: 20px;
`;
export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
  margin-top: 10px;
  button {
    margin: 0;
    padding: 10px 5px;
    width: 50px;
    svg {
      margin-right: 0;
    }
  }
`;

export const Container = styled.div`
  padding: 20px;
`;

export const CreateButton = styled(Button)`
  width: 200px;
  svg {
    padding-right: 5px;
  }
`;
