import { FC } from "react";

import * as S from "./Pages.css";

export const VisaApplicationService: FC = () => (
  <S.Container>
    <S.Title>비자신청 서비스</S.Title>
    <S.Text>
      비자신청 서비스는 이민자의 언어장벽 및 컴퓨터 이용의 어려움이 있는 분들을
      대상으로 제공되는 서비스입니다. 캐나다 정부 또는 대사관 홈페이지에
      게재되어 있는 내용을 그대로 전달드림으로써 필요한 정보를 알려드리는
      이민정착도움 프로그램입니다.
    </S.Text>
  </S.Container>
);
