import styled from "styled-components";
import { MEDIA } from "../styles";

export const Container = styled.div`
  background-color: var(--kssc-white);
  position: relative;
  min-height: 890px;
  top: 0;
  bottom: 0;
  height: 100%;
  @media (max-width: ${MEDIA.nav}) {
    min-height: unset;
  }
`;

export const HeaderContent = styled.div`
  display: flex;
  align-items: end;
  @media (max-width: ${MEDIA.nav}) {
    flex-direction: column;
  }
`;
export const NavBars = styled.div`
  display: flex;
  align-items: end;
  flex-direction: column;
`;
