import styled from "styled-components";
import { Button } from "../atoms";
import { MEDIA } from "../styles";

export const Container = styled.div`
  padding: 20px;
`;
export const TimelineItem = styled.div`
  display: flex;
  justify-content: space-between;
  border: var(--kssc-border);
  border-radius: 5px;
  width: 100%;
  background-color: var(--kssc-light-yellow);
  @media (max-width: ${MEDIA.xs}) {
    flex-direction: column;
  }
`;
export const TimelineItemDate = styled.span`
  padding: 10px;
  width: 20%;
  align-self: center;
  @media (max-width: ${MEDIA.xs}) {
    width: 100%;
  }
`;
export const TimelineItemDetails = styled.span`
  border-left: var(--kssc-border);
  width: 80%;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: ${MEDIA.xs}) {
    width: 100%;
    border-left: none;
    border-top: var(--kssc-border);
  }
`;
export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
  button {
    margin: 0;
    padding: 10px 5px;
    width: 50px;
    svg {
      margin-right: 0;
    }
  }
`;

export const EventsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 20px;
  gap: 5px;
  flex-direction: column;
`;
export const CreateButton = styled(Button)`
  width: 200px;
  svg {
    padding-right: 5px;
  }
`;

export const CalendarWrapper = styled.div`
  padding: 20px;
`;
