/* eslint-disable @typescript-eslint/naming-convention */
import { createContext, Dispatch, FC, Reducer, useContext } from "react";
import { AsyncActionHandlers, useReducerAsync } from "use-reducer-async";
import { api } from "../api";
import { handleError } from "../helpers/handlerError";
import { QuestionComment } from "../types";
import orderBy from "lodash/orderBy";

export type PageState = {
  loading: boolean;
  error: string | null;
  question_comments: QuestionComment[];
  question_id: number | null;
};

type SyncAction =
  | { type: "BEGIN_REQUEST" }
  | { type: "CLEAN_ALL" }
  | {
      type: "INIT_SUCCESS";
      question_comments: QuestionComment[];
      question_id: number;
    }
  | { type: "ADD_FROM_WS"; question_comment: QuestionComment }
  | { type: "UPDATE_FROM_WS"; question_comment: QuestionComment }
  | { type: "DELETE_FROM_WS"; question_comment_id: number }
  | { type: "CREATE_SUCCESS" }
  | { type: "REQUEST_FAILURE"; error: string }
  | { type: "REQUEST_SUCCESS" };

type AsyncAction =
  | { type: "INIT"; question_id: number }
  | { type: "CREATE_QUESTION_COMMENT"; comment: string; user_id: number }
  | { type: "UPDATE_QUESTION_COMMENT"; question_comment: QuestionComment }
  | { type: "DELETE_QUESTION_COMMENT"; id: number };

export type QuestionCommentAction = SyncAction | AsyncAction;

export const initialState: PageState = {
  loading: false,
  error: null,
  question_comments: [],
  question_id: null,
};

export const reducer: Reducer<PageState, SyncAction> = (
  state,
  action
): PageState => {
  switch (action.type) {
    case "BEGIN_REQUEST":
      return { ...state, loading: true, error: null };
    case "CLEAN_ALL":
      return { ...state, loading: false, error: null };
    case "REQUEST_SUCCESS":
      return {
        ...state,
        loading: false,
        error: null,
      };
    case "INIT_SUCCESS":
      return {
        ...state,
        loading: false,
        error: null,
        question_comments: action.question_comments,
        question_id: action.question_id,
      };
    case "REQUEST_FAILURE":
      return { ...state, error: action.error, loading: false };
    case "ADD_FROM_WS":
      return {
        ...state,
        question_comments: orderBy(
          [...state.question_comments, action.question_comment],
          ["id"],
          ["asc"]
        ),
      };
    case "UPDATE_FROM_WS":
      if (
        !state.question_comments.find(
          (p) => p.id === action.question_comment.id
        )
      )
        return state;
      else
        return {
          ...state,
          question_comments: orderBy(
            [
              ...state.question_comments.filter(
                (p) => p.id !== action.question_comment.id
              ),
              action.question_comment,
            ],
            ["id"],
            ["asc"]
          ),
        };
    case "DELETE_FROM_WS":
      return {
        ...state,
        question_comments: state.question_comments.filter(
          (p) => p.id !== action.question_comment_id
        ),
      };
    default:
      return state;
  }
};

export const asyncActionHandlers: AsyncActionHandlers<
  Reducer<PageState, QuestionCommentAction>,
  AsyncAction
> = {
  INIT:
    ({ dispatch }) =>
    async ({ question_id }) => {
      dispatch({ type: "BEGIN_REQUEST" });
      try {
        const question_comments = await api.question_comments.index({
          question_id,
        });
        dispatch({
          type: "INIT_SUCCESS",
          question_comments: orderBy(question_comments, "id"),
          question_id,
        });
      } catch (e) {
        console.error(e);
        const error = handleError(e);
        dispatch({ type: "REQUEST_FAILURE", error });
      }
    },
  CREATE_QUESTION_COMMENT:
    ({ dispatch, getState }) =>
    async (action: { comment: string; user_id: number }) => {
      dispatch({ type: "BEGIN_REQUEST" });
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { comment, user_id } = action;
      const question_id = getState().question_id;
      if (!question_id) throw new Error("Question was blank!");
      try {
        await api.question_comments.create({
          question_comment: { comment, user_id, question_id },
        });
        dispatch({
          type: "REQUEST_SUCCESS",
        });
      } catch (e) {
        console.error(e);
        const error = handleError(e);
        dispatch({ type: "REQUEST_FAILURE", error });
      }
    },
  UPDATE_QUESTION_COMMENT:
    ({ dispatch, getState }) =>
    async (action: { question_comment: QuestionComment }) => {
      dispatch({ type: "BEGIN_REQUEST" });
      const { question_comment } = action;
      const question_id = getState().question_id;
      if (!question_id) throw new Error("Question was blank!");
      try {
        await api.question_comments.update(question_comment);

        dispatch({
          type: "REQUEST_SUCCESS",
        });
      } catch (e) {
        console.error(e);
        const error = handleError(e);
        dispatch({ type: "REQUEST_FAILURE", error });
      }
    },
  DELETE_QUESTION_COMMENT:
    ({ dispatch, getState }) =>
    async (action: { id: number }) => {
      dispatch({ type: "BEGIN_REQUEST" });
      const { id } = action;
      const question_id = getState().question_id;
      if (!question_id) throw new Error("Question was blank!");
      try {
        await api.question_comments.delete(id);

        dispatch({
          type: "REQUEST_SUCCESS",
        });
      } catch (e) {
        console.error(e);
        const error = handleError(e);
        dispatch({ type: "REQUEST_FAILURE", error });
      }
    },
};

const PageContext = createContext<{
  state: PageState;
  dispatch: Dispatch<QuestionCommentAction>;
}>({
  state: initialState,
  dispatch: () => undefined,
});

export const useQuestionCommentsContext = () => useContext(PageContext);

type Props = {
  overrideInitialState?: PageState;
  dispatchOverride?: Dispatch<QuestionCommentAction>;
};

export const QuestionCommentsProvider: FC<Props> = ({
  dispatchOverride,
  children,
  ...overrideInitialState
}) => {
  const [state, dispatch] = useReducerAsync<
    Reducer<PageState, SyncAction>,
    AsyncAction,
    QuestionCommentAction
  >(reducer, { ...initialState, ...overrideInitialState }, asyncActionHandlers);

  const d = dispatchOverride || dispatch;

  return (
    <PageContext.Provider value={{ state, dispatch: d }}>
      {children}
    </PageContext.Provider>
  );
};
