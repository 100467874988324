import moment from "moment";
import { FC, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "../atoms";
import { Pagination } from "../atoms/Pagination";
import { useAppContext } from "../contexts/AppContext";
import { useQuestionsContext } from "../contexts/QuestionsContext";
import { ErrorMessage, Icon } from "../electrons";
import { Question } from "../types";
import * as S from "./CounsellingContainer.css";
import * as E from "./EventTooltip.css";
type Props = {
  questions: Question[];
};

let pageSize = 8;
export const QuestionsContainer: FC<Props> = ({ questions }) => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [isDeleting, setIsDeleting] = useState<number | null>(null);
  const {
    state: { user },
  } = useAppContext();
  const navigate = useNavigate();
  const {
    dispatch,
    state: { error },
  } = useQuestionsContext();

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * pageSize;
    const lastPageIndex = firstPageIndex + pageSize;
    return questions.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, questions]);

  return (
    <S.Container>
      {error && <ErrorMessage error={error} />}
      {currentTableData.map((p, i) => (
        <S.Item key={p.id} color="var(--kssc-dark-yellow)">
          <S.Number color="var(--kssc-dark-yellow)">
            {i + 1 + (currentPage - 1) * pageSize}
          </S.Number>
          {isDeleting !== p.id && (
            <S.PostContainer background="var(--kssc-light-yellow)">
              <S.Title>
                <S.PostTitle>{p.title}</S.PostTitle>
                {p.confidential && (
                  <Icon iconName="lock" color="var(--kssc-dark-yellow)" />
                )}
              </S.Title>
              <S.PostDetails>
                {(p.user_id === user?.id ||
                  user?.role === "kssc_admin" ||
                  !p.confidential) && (
                  <Button
                    color="var(--kssc-dark-yellow)"
                    onClick={() => navigate(`/question-board/${p.id}`)}
                  >
                    View
                  </Button>
                )}
                <S.PostData color="var(--kssc-dark-yellow)">
                  <S.PostCreator>{p.username}</S.PostCreator>
                  <S.PostDate>
                    {moment(p.created_at).format("MM/DD/YY")}
                    {user?.role === "kssc_admin" && p.unanswered_comments && (
                      <Icon
                        iconName="bell"
                        color="var(--kssc-red)"
                        size="15px"
                      />
                    )}
                  </S.PostDate>
                </S.PostData>
              </S.PostDetails>
            </S.PostContainer>
          )}
          {isDeleting === p.id && user?.role === "kssc_admin" && (
            <S.PostContainer>
              <S.DeleteConfirm>
                Are you sure you want to delete this post?
                <small>
                  Note: This will also delete <em>all</em> the post comments.
                </small>
              </S.DeleteConfirm>
              <E.ButtonsContainer>
                <Button
                  onClick={() => {
                    dispatch({
                      type: "DELETE_QUESTION",
                      id: p.id,
                    });
                    setIsDeleting(null);
                  }}
                  color="var(--kssc-green)"
                >
                  삭제
                </Button>
                <Button
                  color="var(--kssc-grey)"
                  onClick={() => {
                    setIsDeleting(null);
                  }}
                >
                  취소
                </Button>
              </E.ButtonsContainer>
            </S.PostContainer>
          )}
          {user?.role === "kssc_admin" && (
            <S.DestroyButton
              color="var(--kssc-pastel-red)"
              onClick={() => setIsDeleting(p.id)}
            >
              <Icon iconName="trash" />
            </S.DestroyButton>
          )}
        </S.Item>
      ))}
      <Pagination
        currentPage={currentPage}
        totalCount={questions.length}
        pageSize={pageSize}
        siblingCount={1}
        onPageChange={(page: number) => setCurrentPage(page)}
      />
    </S.Container>
  );
};
