import moment from "moment";
import { FC, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "../atoms";
import { Pagination } from "../atoms/Pagination";
import { useAppContext } from "../contexts/AppContext";
import { usePostsContext } from "../contexts/PostsContext";
import { ErrorMessage, Icon } from "../electrons";
import { Post } from "../types";
import * as S from "./CounsellingContainer.css";
import * as E from "./EventTooltip.css";
type Props = {
  posts: Post[];
};

let pageSize = 8;
export const CounsellingContainer: FC<Props> = ({ posts }) => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [isDeleting, setIsDeleting] = useState<number | null>(null);
  const {
    state: { user },
  } = useAppContext();
  const navigate = useNavigate();
  const {
    dispatch,
    state: { error },
  } = usePostsContext();

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * pageSize;
    const lastPageIndex = firstPageIndex + pageSize;
    return posts.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, posts]);

  return (
    <S.Container>
      {error && <ErrorMessage error={error} />}
      {currentTableData.map((p, i) => (
        <S.Item key={p.id}>
          <S.Number>{i + 1 + (currentPage - 1) * pageSize}</S.Number>
          {isDeleting !== p.id && (
            <S.PostContainer>
              <S.Title>
                <S.PostTitle>{p.title}</S.PostTitle>
                <Icon iconName="lock" color="var(--kssc-teal)" />
              </S.Title>
              <S.PostDetails>
                {(p.user_id === user?.id || user?.role === "kssc_admin") && (
                  <Button
                    onClick={() => navigate(`/online-counselling/${p.id}`)}
                  >
                    View
                  </Button>
                )}
                <S.PostData>
                  <S.PostCreator>{p.username}</S.PostCreator>
                  <S.PostDate>
                    {moment(p.created_at).format("MM/DD/YY")}
                    {user?.role === "kssc_admin" && p.unanswered_comments && (
                      <Icon
                        iconName="bell"
                        color="var(--kssc-red)"
                        size="15px"
                      />
                    )}
                  </S.PostDate>
                </S.PostData>
              </S.PostDetails>
            </S.PostContainer>
          )}
          {isDeleting === p.id && user?.role === "kssc_admin" && (
            <S.PostContainer>
              <S.DeleteConfirm>
                Are you sure you want to delete this post?
                <small>
                  Note: This will also delete <em>all</em> the post comments.
                </small>
              </S.DeleteConfirm>
              <E.ButtonsContainer>
                <Button
                  onClick={() => {
                    dispatch({
                      type: "DELETE_POST",
                      id: p.id,
                    });
                    setIsDeleting(null);
                  }}
                  color="var(--kssc-green)"
                >
                  삭제
                </Button>
                <Button
                  color="var(--kssc-grey)"
                  onClick={() => {
                    setIsDeleting(null);
                  }}
                >
                  취소
                </Button>
              </E.ButtonsContainer>
            </S.PostContainer>
          )}
          {user?.role === "kssc_admin" && (
            <S.DestroyButton
              color="var(--kssc-pastel-red)"
              onClick={() => setIsDeleting(p.id)}
            >
              <Icon iconName="trash" />
            </S.DestroyButton>
          )}
        </S.Item>
      ))}
      <Pagination
        currentPage={currentPage}
        totalCount={posts.length}
        pageSize={pageSize}
        siblingCount={1}
        onPageChange={(page: number) => setCurrentPage(page)}
      />
    </S.Container>
  );
};
