import styled from "styled-components";

type ButtonProps = {
  color?: string;
};

export const Button = styled.button<ButtonProps>`
  background-color: ${(props) => props?.color || "var(--kssc-teal)"};
  color: var(--kssc-white);
  box-shadow: 6px 8px 8px rgb(0 0 0 / 30%);
  border: none;
  border-radius: 4px;
  padding: 10px;
  margin: 10px;
  font-size: var(--kssc-lg);
  font-weight: 50;
  cursor: pointer;
  svg {
    color: var(--kssc-white);
    margin-right: 7px;
  }
  &:disabled {
    background-color: #e0e0e0;
    cursor: unset;
  }
`;
