import { FC, useState } from "react";
import { Button } from "../atoms";
import { usePostsContext } from "../contexts/PostsContext";
import { ErrorMessage, Icon, Input, TextArea } from "../electrons";
import * as S from "./CreatePost.css";
import { Loading } from "./Loading";

type Props = {
  onSubmit: (title: string, content: string) => void;
  onCancel: () => void;
};

export const CreatePost: FC<Props> = ({ onSubmit, onCancel }) => {
  const [postTitle, setPostTitle] = useState<string>("");
  const [postContent, setPostContent] = useState<string>("");

  const {
    state: { error, loading },
  } = usePostsContext();
  return (
    <>
      <Loading visible={loading} error={!!error} onComplete={onCancel} />
      <S.Container>
        {error && <ErrorMessage error={error} />}
        <Input
          label="Title"
          type="text"
          value={postTitle}
          onChange={(e) => setPostTitle(e.target.value)}
          variant="light"
        />
        <TextArea
          variant="light"
          label="Message"
          value={postContent}
          onChange={(e) => setPostContent(e.target.value)}
        />
        <S.Buttons>
          <Button
            type="submit"
            onClick={(e) => {
              e.preventDefault();
              onSubmit(postTitle, postContent);
            }}
          >
            <Icon iconName="paper-plane" />
            Submit
          </Button>
          <Button type="button" color="var(--kssc-grey)" onClick={onCancel}>
            <Icon iconName="xmark" />
            Cancel
          </Button>
        </S.Buttons>
      </S.Container>
    </>
  );
};
