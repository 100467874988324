import { FC, useState } from "react";
import { Button } from "../atoms";
import { useQuestionsContext } from "../contexts/QuestionsContext";
import { ErrorMessage, Icon, Input, TextArea } from "../electrons";
import * as S from "./CreatePost.css";
import { Loading } from "./Loading";

type Props = {
  onSubmit: (title: string, content: string, confidential: boolean) => void;
  onCancel: () => void;
};

export const CreateQuestion: FC<Props> = ({ onSubmit, onCancel }) => {
  const [postTitle, setQuestionTitle] = useState<string>("");
  const [postContent, setQuestionContent] = useState<string>("");
  const [confidential, setConfidential] = useState<boolean>(true);

  const {
    state: { error, loading },
  } = useQuestionsContext();
  return (
    <>
      <Loading visible={loading} error={!!error} onComplete={onCancel} />
      <S.Container>
        {error && <ErrorMessage error={error} />}
        <S.FirstRow>
          <Input
            label="Title"
            type="text"
            value={postTitle}
            onChange={(e) => setQuestionTitle(e.target.value)}
            variant="light"
          />
          <S.LabelComponent>
            <span>Confidential</span>
            <S.Checkbox
              type="checkbox"
              checked={confidential}
              onChange={(e) => setConfidential(e.target.checked)}
            />
          </S.LabelComponent>
        </S.FirstRow>
        <TextArea
          variant="light"
          label="Message"
          value={postContent}
          onChange={(e) => setQuestionContent(e.target.value)}
        />
        <S.Buttons>
          <Button
            type="submit"
            onClick={(e) => {
              e.preventDefault();
              onSubmit(postTitle, postContent, confidential);
            }}
          >
            <Icon iconName="paper-plane" />
            Submit
          </Button>
          <Button type="button" color="var(--kssc-grey)" onClick={onCancel}>
            <Icon iconName="xmark" />
            Cancel
          </Button>
        </S.Buttons>
      </S.Container>
    </>
  );
};
