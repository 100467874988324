import { FC } from "react";

import styled from "styled-components";

const Button = styled.button`
  border: 0;
  background: none;
  margin-top: 5px;
`;

export const KakaoButton: FC = () => {
  return (
    <Button
      type="button"
      id="add-channel-button"
      onClick={() => window.addChannel()}
    >
      <img src="/images/channel_add_small.png" alt="Add Channel button" />
    </Button>
  );
};
