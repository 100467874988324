import { FC, useState, useEffect } from "react";
import { Button } from "../atoms";
import { Icon, Input, ErrorMessage } from "../electrons";
import * as S from "./CreateTimelineEvent.css";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import "./react-datepicker-override.css";
import { TimelineEvent } from "../types";
import { useTimelineContext } from "../contexts/TimelineContext";
import { Loading } from "./Loading";

type Props = {
  onSubmit(date: string, detailsKor: string, detailsEng: string): void;
  event?: TimelineEvent;
  onCancel(): void;
};

export const CreateTimelineEvent: FC<Props> = ({
  onSubmit,
  event,
  onCancel,
}) => {
  const [date, setDate] = useState(
    (event && new Date(event.date)) || new Date()
  );
  const {
    state: { error, loading },
    dispatch,
  } = useTimelineContext();
  useEffect(() => () => dispatch({ type: "CLEAN_ALL" }), [dispatch]);
  const [detailsKor, setDetailsKor] = useState<string>(
    event?.details_kor || ""
  );
  const [detailsEng, setDetailsEng] = useState<string>(
    event?.details_eng || ""
  );
  return (
    <>
      {error && <ErrorMessage error={error} />}
      <Loading visible={loading} error={!!error} onComplete={onCancel} />
      <S.Container>
        <DatePicker
          className="date-input"
          selected={date}
          onChange={(d: Date) => setDate(d)}
          showMonthDropdown
        />
        <Input
          type="text"
          variant="light"
          label="Details 영어"
          value={detailsEng}
          required
          onChange={(e) => setDetailsEng(e.target.value)}
        />
        <Input
          type="text"
          variant="light"
          label="Details 한국어"
          value={detailsKor}
          required
          onChange={(e) => setDetailsKor(e.target.value)}
        />
        <S.Buttons>
          <Button color="var(--kssc-pastel-red)" onClick={onCancel}>
            <Icon iconName="xmark" />
            취소
          </Button>
          <Button
            type="submit"
            onClick={(e) => {
              e.preventDefault();
              onSubmit(date.toString(), detailsKor, detailsEng);
            }}
          >
            <Icon iconName="paper-plane" />
            저장
          </Button>
        </S.Buttons>
      </S.Container>
    </>
  );
};
