import moment from "moment";
import { forwardRef, RefObject, useState } from "react";
import { Button } from "../atoms";
import { Icon } from "../electrons";
import { CalendarEvent, User } from "../types";

import * as S from "./EventTooltip.css";

type Props = {
  event: CalendarEvent;
  user: User | null;
  onEdit: (event: CalendarEvent) => void;
  onDelete: (event: CalendarEvent) => void;
  visible: boolean;
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
};

export const EventTooltip = forwardRef<RefObject<HTMLDivElement>, Props>(
  ({ event, user, onEdit, onDelete, setIsVisible }, ref) => {
    const [isDeleting, setIsDeleting] = useState<boolean>(false);
    if (isDeleting && user?.role === "kssc_admin")
      return (
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        <S.Container ref={ref as any}>
          <S.DeleteConfirm>Are you sure?</S.DeleteConfirm>
          <S.ButtonsContainer>
            <Button onClick={() => onDelete(event)} color="var(--kssc-green)">
              삭제
            </Button>
            <Button
              color="var(--kssc-grey)"
              onClick={() => {
                setIsDeleting(false);
              }}
            >
              취소
            </Button>
          </S.ButtonsContainer>
        </S.Container>
      );
    return (
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      <S.Container ref={ref as any}>
        {user?.role === "kssc_admin" && (
          <S.ButtonsContainer>
            <Button onClick={() => onEdit(event)}>
              <Icon iconName="pencil" />
            </Button>
            <Button
              color="var(--kssc-pastel-red)"
              onClick={() => setIsDeleting(true)}
            >
              <Icon iconName="trash" />
            </Button>
          </S.ButtonsContainer>
        )}
        <S.Item visible={!!event.title}>
          <Icon size="18px" iconName="calendar" />
          <span>{event.title}</span>
        </S.Item>
        <S.Item visible={!!event.event_at}>
          <Icon size="18px" iconName="clock" />
          <span>{moment(event.event_at).format("MM/DD/YY, h:mm a")} </span>
          {event.end_time && (
            <>
              <Icon size="15px" iconName="arrow-right" />
              <span>{moment(event.end_time).format("MM/DD/YY, h:mm a")} </span>
            </>
          )}
        </S.Item>
        <S.Item visible={!!event.location}>
          <Icon size="18px" iconName="location-dot" />
          <span>{event.location}</span>
        </S.Item>
        <S.Item visible={!!event.details}>
          <Icon size="18px" iconName="calendar-week" />
          <span>{event.details}</span>
        </S.Item>
        <S.CloseButton onClick={() => setIsVisible(false)}>Close</S.CloseButton>
      </S.Container>
    );
  }
);
