import { FC } from "react";
import * as S from "./Input.css";

type Props = {
  label: string;
  type: "email" | "password" | "text";
  value: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  variant?: "light";
  name?: string;
  button?: JSX.Element;
  required?: boolean;
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement> | undefined;
};

export const Input: FC<Props> = ({
  label,
  type,
  value,
  onChange,
  variant,
  name,
  button,
  required,
  onKeyDown,
}) => {
  return (
    <S.GroupComponent variant={variant}>
      <S.LabelComponent variant={variant}>
        <span>{label}</span>
        <S.InputComponent
          variant={variant}
          name={name}
          type={type}
          value={value}
          onChange={onChange}
          style={{ fontSize: 20 }}
          autoComplete="new-password"
          required={required}
          onKeyDown={onKeyDown}
        />
        {!!button && button}
      </S.LabelComponent>
    </S.GroupComponent>
  );
};
