import { FC, useState, useEffect } from "react";
import { TextButton } from "../atoms";
import { Icon, Input, ErrorMessage } from "../electrons";
import { useAppContext } from "../contexts/AppContext";
import * as S from "./Pages.css";
import { useTranslation } from "react-i18next";

export const SignUp: FC = () => {
  const [email, setEmail] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const {
    dispatch,
    state: { error },
  } = useAppContext();
  const { t } = useTranslation();
  useEffect(() => () => dispatch({ type: "CLEAN_ALL" }), [dispatch]);

  return (
    <S.SignUpContainer
      onSubmit={(e) => {
        e.preventDefault();
        dispatch({ type: "SIGN_UP", name, email, password });
      }}
    >
      {error && <ErrorMessage error={error} />}
      <S.SignUpSection>{t("sign-up-information")}</S.SignUpSection>
      <Input
        label={t("name")}
        type="text"
        name="name"
        value={name}
        onChange={(e) => setName(e.target.value)}
        variant="light"
      />
      <Input
        label={t("email")}
        type="email"
        name="new-email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        variant="light"
      />
      <Input
        label={t("password")}
        type={showPassword ? "text" : "password"}
        name="new-password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        variant="light"
        button={
          <TextButton
            type="button"
            onClick={() => setShowPassword(!showPassword)}
          >
            <small>{showPassword ? "hide" : "show"}</small>
            <Icon iconName={showPassword ? "eye-slash" : "eye"} />
          </TextButton>
        }
      />

      <S.SendButton>
        <Icon iconName="paper-plane" />
        {t("submit-sign-in")}
      </S.SendButton>
    </S.SignUpContainer>
  );
};
