import { FC } from "react";
import { useAppContext } from "../contexts/AppContext";
import { NavBarItemSmall } from "../protons";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import "./dropdown-styles.css";
import * as S from "./NavBar.css";
import i18n from "../i18n";
import { useTranslation } from "react-i18next";
import { useWindowWidth } from "../hooks/useWindowWidth";
import { KakaoButton } from "./KakaoButtton";
const options = [
  { value: "kr", label: "한국어" },
  { value: "en", label: "English" },
];

export const NavBarSmall: FC = () => {
  const {
    state: { user, locale },
    dispatch,
  } = useAppContext();
  const { t } = useTranslation();
  const width = useWindowWidth();

  const handleChange = (option: { value: "kr" | "en"; label: string }) => {
    dispatch({ type: "SET_LOCALE", locale: option.value });
    if (option.value !== locale) i18n.changeLanguage(option.value);
  };
  return (
    <>
      <S.SmallContainer>
        {width > 599 && (
          <>
            <S.SelectWrapper>
              <Dropdown
                onChange={handleChange as any}
                options={options}
                value={locale || "kr"}
              />
            </S.SelectWrapper>
            <KakaoButton />
            {user && user.role === "kssc_admin" && (
              <NavBarItemSmall
                iconName="bell"
                mainButton={{
                  displayName: t("notifications"),
                  to: "/notifications",
                }}
              />
            )}
          </>
        )}
        <NavBarItemSmall
          iconName="fa-solid fa-home"
          mainButton={{ displayName: t("home"), to: "/" }}
        />
        {!user && (
          <>
            <NavBarItemSmall
              iconName="fa-solid fa-arrow-right-to-bracket"
              mainButton={{ displayName: t("sign-in"), to: "/sign-in" }}
            />
            <NavBarItemSmall
              iconName="fa-solid fa-user-plus"
              mainButton={{ displayName: t("sign-up"), to: "/sign-up" }}
            />
          </>
        )}
        {user && (
          <>
            <NavBarItemSmall
              iconName="user"
              mainButton={{ displayName: user.name, to: "/profile" }}
            />
            <NavBarItemSmall
              iconName="fa-solid fa-arrow-right-from-bracket"
              mainButton={{
                displayName: t("sign-out"),
                onClick: () => {
                  dispatch({ type: "SIGN_OUT" });
                },
              }}
            />
          </>
        )}
      </S.SmallContainer>
      {width <= 599 && (
        <S.SmallContainer>
          <S.SelectWrapper style={{ paddingLeft: 5 }}>
            <Dropdown
              onChange={handleChange as any}
              options={options}
              value={locale || "kr"}
            />
          </S.SelectWrapper>
          {user && user.role === "kssc_admin" && (
            <NavBarItemSmall
              iconName="bell"
              mainButton={{
                displayName: t("notifications"),
                to: "/notifications",
              }}
            />
          )}
          <KakaoButton />
        </S.SmallContainer>
      )}
    </>
  );
};
