import { ErrorMessage } from "../electrons";
import { FC, useState, useEffect } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { Input, Icon } from "../electrons";

import * as S from "./Pages.css";
import { useAppContext } from "../contexts/AppContext";
import { TextButton } from "../atoms";

export const ResetPassword: FC = () => {
  const [hasSubmitted, setHasSubmitted] = useState<boolean>(false);
  const [password, setPassword] = useState<string>("");
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");

  const {
    state: { error },
    dispatch,
  } = useAppContext();

  useEffect(() => () => dispatch({ type: "CLEAN_ALL" }), [dispatch]);

  if (hasSubmitted && !error)
    return (
      <S.Container>
        <S.Title>Reset Successful</S.Title>
        <S.Text>
          Return to <Link to="/">home</Link>?
        </S.Text>
      </S.Container>
    );
  return (
    <S.Container>
      <S.Title>Reset Password</S.Title>
      <S.SignUpContainer
        onSubmit={(e) => {
          e.preventDefault();
          setHasSubmitted(true);
          dispatch({ type: "RESET_PASSWORD", token, password });
        }}
      >
        {error && <ErrorMessage error={error} />}
        <S.SignUpSection>정보 입력</S.SignUpSection>
        <Input
          label="비밀번호"
          type={showPassword ? "text" : "password"}
          name="new-password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          variant="light"
          button={
            <TextButton
              type="button"
              onClick={() => setShowPassword(!showPassword)}
            >
              <small>{showPassword ? "hide" : "show"}</small>
              <Icon iconName={showPassword ? "eye-slash" : "eye"} />
            </TextButton>
          }
        />

        <S.SendButton>
          <Icon iconName="paper-plane" />
          저장
        </S.SendButton>
      </S.SignUpContainer>
    </S.Container>
  );
};
