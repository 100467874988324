import { FC, useState, useEffect } from "react";
import { useAppContext } from "../contexts/AppContext";
import { useAlbumContext } from "../contexts/AlbumContext";
import { ErrorMessage, Icon } from "../electrons";
import { IKImage } from "imagekitio-react";
import * as S from "./ActivityPhotoAlbum.css";
import { Title } from "./Pages.css";
import { CreateAlbum, Loading } from "../molecules";
import { Button } from "../atoms";
import { Album } from "../types";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export const ActivityPhotoAlbum: FC = () => {
  const urlEndpoint = "https://ik.imagekit.io/kssc";
  const { t } = useTranslation();
  const [isAdding, setIsAdding] = useState<boolean>(false);
  const [isDeletingAlbum, setIsDeletingAlbum] = useState<Album | null>(null);
  const navigate = useNavigate();
  const {
    state: { user, locale },
  } = useAppContext();
  const {
    dispatch,
    state: { albums, loading, error },
  } = useAlbumContext();

  useEffect(() => {
    dispatch({ type: "FETCH_ALBUMS" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => () => dispatch({ type: "CLEAN_ALL" }), [dispatch]);
  const pageContent = () => {
    if (isAdding)
      return (
        <CreateAlbum
          onCancel={() => setIsAdding(false)}
          onSubmit={(
            title_kor: string,
            description_kor: string,
            title_eng: string,
            description_eng: string
          ) =>
            dispatch({
              type: "CREATE_ALBUM",
              title_kor,
              description_kor,
              title_eng,
              description_eng,
            })
          }
        />
      );
    return (
      <S.AlbumsContainer>
        {error && <ErrorMessage error={error} />}
        {albums.length === 0 && <div>No Albums yet!</div>}
        {albums.length > 0 &&
          albums.map((album) => (
            <S.AlbumItem key={album.id}>
              <S.ItemContainer>
                <S.Photo>
                  {album.thumbnail && (
                    <IKImage
                      urlEndpoint={urlEndpoint}
                      path={album.thumbnail}
                      width="200"
                      height="200"
                      transformation={[{ height: 200, width: 200 }]}
                      loading="lazy"
                      lqip={{ active: true }}
                    />
                  )}
                  <Button
                    color="var(--kssc-dark-yellow)"
                    onClick={() => navigate(`/albums/${album.id}`)}
                  >
                    <Icon iconName="image" />
                    View Photos
                  </Button>
                </S.Photo>
                {(!isDeletingAlbum ||
                  (isDeletingAlbum && isDeletingAlbum.id !== album.id)) && (
                  <S.AlbumItemContent>
                    {locale === "kr" ? (
                      <>
                        <S.AlbumItemTitle>{album.title_kor}</S.AlbumItemTitle>
                        {album.description_kor}
                      </>
                    ) : (
                      <>
                        <S.AlbumItemTitle>{album.title_eng}</S.AlbumItemTitle>
                        {album.description_eng}
                      </>
                    )}
                  </S.AlbumItemContent>
                )}
                {!!isDeletingAlbum && isDeletingAlbum.id === album.id && (
                  <S.AlbumItemContent>
                    <strong>Are you sure you want to delete this album?</strong>
                    {user?.role === "kssc_admin" && (
                      <S.ButtonsContainer>
                        <Button
                          onClick={() =>
                            dispatch({
                              type: "DELETE_ALBUM",
                              id: isDeletingAlbum.id,
                            })
                          }
                          color="var(--kssc-green)"
                        >
                          삭제
                        </Button>
                        <Button
                          color="var(--kssc-grey)"
                          onClick={() => {
                            setIsDeletingAlbum(null);
                            dispatch({ type: "CLEAN_ALL" });
                          }}
                        >
                          취소
                        </Button>
                      </S.ButtonsContainer>
                    )}
                  </S.AlbumItemContent>
                )}
              </S.ItemContainer>
              {user?.role === "kssc_admin" && (
                <S.ButtonsContainer>
                  <Button
                    color="var(--kssc-pastel-red)"
                    onClick={() => setIsDeletingAlbum(album)}
                  >
                    <Icon iconName="trash" />
                  </Button>
                </S.ButtonsContainer>
              )}
            </S.AlbumItem>
          ))}
      </S.AlbumsContainer>
    );
  };
  return (
    <S.Container>
      {loading && <Loading />}
      <Title>{t("activity-photo-album")}</Title>
      {user?.role === "kssc_admin" && (
        <S.CreateButton onClick={() => setIsAdding(true)} disabled={isAdding}>
          <Icon iconName="file-circle-plus" />
          Add Album
        </S.CreateButton>
      )}
      {pageContent()}
    </S.Container>
  );
};
