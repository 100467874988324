import { FC } from "react";
import { useTranslation } from "react-i18next";

import * as S from "./Pages.css";
import { Blogs } from "./Blogs";

export const Clinics: FC = () => {
  const { t } = useTranslation();

  return (
    <S.Container>
      <S.Title>{t("clinics")}</S.Title>
      <S.Text>
        KSSC holds free annual tax clinics for low-income individuals and
        families (volunteered by licensed accountants) and we are in the process
        of planning other clinics for other topics.
      </S.Text>
      <Blogs blogKind="clinic" />
    </S.Container>
  );
};
