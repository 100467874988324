import { FC, useState, useEffect } from "react";
import { useAppContext } from "../contexts/AppContext";
import { useAlbumContext } from "../contexts/AlbumContext";
import { ErrorMessage, Icon } from "../electrons";
import { IKImage } from "imagekitio-react";
import * as S from "./ActivityPhotoAlbum.css";
import { Title } from "./Pages.css";
import { AddPhoto, CreateAlbum, Loading, PhotoShow } from "../molecules";
import { Button, Modal } from "../atoms";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { usePhotosContext } from "../contexts/PhotosContext";
import { Photo } from "../types";
import { useWindowWidth } from "../hooks/useWindowWidth";
import { useComponentVisible } from "../helpers/useComponentVisible";

export const PhotoGallery: FC = () => {
  const width = useWindowWidth();
  const urlEndpoint = "https://ik.imagekit.io/kssc";
  const { t } = useTranslation();
  const { album_id } = useParams();
  const [isEditingAlbum, setIsEditingAlbum] = useState<boolean>(false);
  const [photo, setPhoto] = useState<Photo | null>(null);
  const [next, setNext] = useState<Photo | null>(null);
  const [prev, setPrev] = useState<Photo | null>(null);
  const [isAdding, setIsAdding] = useState<boolean>(false);
  const navigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleUserKeyPress = (e: any) => {
    if (isEditingAlbum || isAdding) return;
    e.preventDefault();
    if (e.key === "ArrowRight") handleRightClick();
    if (e.key === "ArrowLeft") handleLeftClick();
  };

  const handleLeftClick = () => {
    if (!photo) return;
    onSetPhoto(prev);
  };
  const handleRightClick = () => {
    if (!photo) return;
    onSetPhoto(next);
  };

  const onSetPhoto = (current: Photo | null) => {
    if (!current) return;
    const currentIndex = photos.findIndex((p) => p.id === current.id);
    setPhoto(current);
    setNext(photos[currentIndex + 1] || null);
    setPrev(photos[currentIndex - 1] || null);
  };
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);
  const {
    state: { user, locale },
  } = useAppContext();
  const {
    dispatch: albumDispatch,
    state: { albums },
  } = useAlbumContext();
  const {
    dispatch,
    state: { error, loading, photos },
  } = usePhotosContext();

  useEffect(() => {
    albumDispatch({ type: "FETCH_ALBUMS" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    album_id && dispatch({ type: "FETCH_PHOTOS", album_id: Number(album_id) });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [album_id]);

  useEffect(() => {
    window.addEventListener("keydown", handleUserKeyPress);

    return () => {
      window.removeEventListener("keydown", handleUserKeyPress);
    };
  });

  const album = albums.find((a) => a.id === Number(album_id || 0));
  const [thumbnail, setThumbnail] = useState<Photo | undefined>(undefined);

  useEffect(() => () => dispatch({ type: "CLEAN_ALL" }), [dispatch]);
  useEffect(() => {
    if (photos && album)
      setThumbnail(photos.find((p) => p.id === album?.thumbnail_id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [photos]);
  if (!album)
    return <ErrorMessage error={`No Album found with id ${album_id}`} />;
  const pageContent = () => {
    if (isAdding && album)
      return (
        <AddPhoto
          album_id={album.id}
          onCancel={() => setIsAdding(false)}
          onSubmit={(
            files: { file: string; fileName: string }[],
            albumId: number
          ) =>
            dispatch({
              type: "ADD_PHOTOS",
              files,
              album_id: albumId,
            })
          }
        />
      );

    return (
      <>
        {error && <ErrorMessage error={error} />}
        <S.AlbumItem>
          <S.ItemContainer>
            {thumbnail && (
              <S.Photo>
                <IKImage
                  urlEndpoint={urlEndpoint}
                  path={thumbnail.image}
                  width="200"
                  transformation={[{ height: 200, width: 200 }]}
                  loading="lazy"
                  lqip={{ active: true }}
                />
              </S.Photo>
            )}
            {!isEditingAlbum && (
              <S.AlbumItemContent>
                {locale === "kr" ? (
                  <>
                    <S.AlbumItemTitle>{album.title_kor}</S.AlbumItemTitle>
                    {album.description_kor}
                  </>
                ) : (
                  <>
                    <S.AlbumItemTitle>{album.title_eng}</S.AlbumItemTitle>
                    {album.description_eng}
                  </>
                )}
              </S.AlbumItemContent>
            )}
            {isEditingAlbum && (
              <CreateAlbum
                variant="green"
                album={album}
                onCancel={() => setIsEditingAlbum(false)}
                onSubmit={(
                  title_kor: string,
                  description_kor: string,
                  title_eng: string,
                  description_eng: string
                ) => {
                  albumDispatch({
                    type: "UPDATE_ALBUM",
                    album: {
                      ...album,
                      title_kor,
                      title_eng,
                      description_kor,
                      description_eng,
                      thumbnail_id: thumbnail?.id || null,
                    },
                  });
                }}
              />
            )}
          </S.ItemContainer>
          {user?.role === "kssc_admin" && (
            <S.ButtonsContainer>
              <Button onClick={() => setIsEditingAlbum(true)}>
                <Icon iconName="pencil" />
              </Button>
            </S.ButtonsContainer>
          )}
        </S.AlbumItem>
        {isEditingAlbum && (
          <S.HelperText>
            Click on a photo to change{" "}
            <S.ThumbnailText>album thumbnail</S.ThumbnailText>
          </S.HelperText>
        )}
        <S.PhotosContainer>
          {photos.map((p) => (
            <PhotoShow
              viewImage={() => {
                onSetPhoto(p);
                setIsComponentVisible(true);
              }}
              photo={p}
              key={p.id}
              onDelete={(id: number) =>
                dispatch({
                  type: "DELETE_PHOTO",
                  id,
                })
              }
              isEditingAlbum={isEditingAlbum}
              thumbnail={thumbnail}
              setThumbnail={setThumbnail}
              onEdit={(editedPhoto: Photo) =>
                dispatch({
                  type: "UPDATE_PHOTO",
                  photo: editedPhoto,
                })
              }
            />
          ))}
        </S.PhotosContainer>
        {photo && (
          <Modal visible={isComponentVisible}>
            <S.PhotoModalWrapper ref={ref}>
              <IKImage
                urlEndpoint={urlEndpoint}
                path={photo.image}
                width={width < 599 ? "100%" : "30%"}
                height={width < 599 ? "100%" : "30%"}
                transformation={[{ height: 300, width: 400 }]}
                lqip={{ active: true }}
                loading="lazy"
              />
              <S.PhotoBase>
                <S.IconButtonLeft
                  type="button"
                  onClick={handleLeftClick}
                  disabled={!prev}
                >
                  <Icon iconName="angle-left" size="40px" color="white" />
                </S.IconButtonLeft>
                <span>{photo.title}</span>
                <S.IconButtonRight
                  type="button"
                  onClick={handleRightClick}
                  disabled={!next}
                >
                  <Icon iconName="angle-right" size="40px" color="white" />
                </S.IconButtonRight>
              </S.PhotoBase>
            </S.PhotoModalWrapper>
          </Modal>
        )}
      </>
    );
  };
  return (
    <S.Container>
      {loading && <Loading />}
      <S.BackButtonWrapper>
        {
          <Button onClick={() => navigate(-1)}>
            <Icon iconName="arrow-left" />
            Back to Albums
          </Button>
        }
      </S.BackButtonWrapper>
      <Title>{t("photo-gallery")}</Title>
      {user?.role === "kssc_admin" && (
        <S.CreateButton onClick={() => setIsAdding(true)} disabled={isAdding}>
          <Icon iconName="image" />
          Add Photos
        </S.CreateButton>
      )}
      {pageContent()}
    </S.Container>
  );
};
