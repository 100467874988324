// eslint-disable-next-line import/no-named-as-default
import Axios from "axios";
import { environment, Environment } from "./helpers/environment";
import {
  TimelineEvent,
  User,
  UserCreateParams,
  CalendarEvent,
  Post,
  PostComment,
  Notification,
  QuestionComment,
  Question,
  Album,
  Photo,
  Newsletter,
  Blog,
} from "./types";

const apiAxios = Axios.create({ withCredentials: true });
apiAxios.defaults.headers.post["Content-Type"] =
  "application/x-www-form-urlencoded";
apiAxios.defaults.baseURL =
  environment() === Environment.prod ? "https://server.kssc-ab.ca/api" : "/api";
export const api = {
  users: {
    index: async () => (await apiAxios.get<User[]>("/users")).data,
    create: async (params: UserCreateParams) =>
      void (await apiAxios.post<void>("/users", params)),
    update: async (name: string, id: number): Promise<User> =>
      (await apiAxios.put<User>(`/users/${id}`, { name })).data,
    show: async (): Promise<User | null> =>
      (await apiAxios.get<User>("/users/sessions")).data,
    signIn: async (params: { user: { email: string; password: string } }) =>
      void (await apiAxios.post<void>("/users/sign_in", params)),
    resetPassword: async (password: string, token: string | null) =>
      void (await apiAxios.put<void>("/users/password", {
        reset_password_token: token,
        password,
      })),
    sendPasswordReset: async (email: string) =>
      void (await apiAxios.post<void>("/users/password", { email })),
    resendConfirmation: async (email: string) =>
      void (await apiAxios.post<void>("/users/confirmation", { email })),
    signOut: async () => void (await apiAxios.delete<void>("/users/sessions")),
  },
  albums: {
    index: async () => (await apiAxios.get<Album[]>("/albums")).data,
    show: async (id: number): Promise<Album> =>
      (await apiAxios.get<Album>(`/albums/${id}`)).data,
    create: async (params: {
      album: {
        title_kor: string;
        description_kor: string;
        title_eng: string;
        description_eng: string;
      };
    }): Promise<Album> => (await apiAxios.post<Album>("/albums", params)).data,
    update: async (album: Album): Promise<Album> =>
      (
        await apiAxios.put<Album>(`/albums/${album.id}`, {
          album,
        })
      ).data,
    delete: async (id: number) =>
      void (await apiAxios.delete<void>(`/albums/${id}`)),
  },
  photos: {
    index: async (params: { album_id: number }) =>
      (await apiAxios.get<Photo[]>("/photos", { params })).data,
    create: async (params: {
      photo: { title: string; image: string; album_id: number };
    }): Promise<Photo> => (await apiAxios.post<Photo>("/photos", params)).data,
    delete: async (id: number) =>
      void (await apiAxios.delete<void>(`/photos/${id}`)),
    update: async (photo: Photo): Promise<Photo> =>
      (
        await apiAxios.put<Photo>(`/photos/${photo.id}`, {
          photo,
        })
      ).data,
  },
  newsletters: {
    index: async () => (await apiAxios.get<Newsletter[]>("/newsletters")).data,
    create: async (params: {
      newsletter: { name: string; image: string; date: string };
    }): Promise<Newsletter> =>
      (await apiAxios.post<Newsletter>("/newsletters", params)).data,
    delete: async (id: number) =>
      void (await apiAxios.delete<void>(`/newsletters/${id}`)),
    update: async (newsletter: Newsletter): Promise<Newsletter> =>
      (
        await apiAxios.put<Newsletter>(`/newsletters/${newsletter.id}`, {
          newsletter,
        })
      ).data,
  },
  blogs: {
    index: async (kind: Blog["kind"]) =>
      (await apiAxios.get<Blog[]>("/blogs", { params: { kind } })).data,
    create: async (params: {
      blog: {
        title_kor: string;
        description_kor: string;
        title_eng: string;
        description_eng: string;
        image: string | null;
        kind: Blog["kind"];
      };
    }): Promise<Blog> => (await apiAxios.post<Blog>("/blogs", params)).data,
    delete: async (id: number) =>
      void (await apiAxios.delete<void>(`/blogs/${id}`)),
    update: async (blog: Blog): Promise<Blog> =>
      (
        await apiAxios.put<Blog>(`/blogs/${blog.id}`, {
          blog,
        })
      ).data,
  },
  posts: {
    index: async () => (await apiAxios.get<Post[]>("/posts")).data,
    show: async (id: number): Promise<Post> =>
      (await apiAxios.get<Post>(`/posts/${id}`)).data,
    create: async (params: {
      post: { title: string; user_id: number; comment: string };
    }): Promise<Post> => (await apiAxios.post<Post>("/posts", params)).data,
    update: async (post: Post): Promise<Post> =>
      (
        await apiAxios.put<Post>(`/posts/${post.id}`, {
          post,
        })
      ).data,
    delete: async (id: number) =>
      void (await apiAxios.delete<void>(`/posts/${id}`)),
  },
  post_comments: {
    index: async (params: { post_id: number }) =>
      (await apiAxios.get<PostComment[]>("/post_comments", { params })).data,
    create: async (params: {
      post_comment: { comment: string; user_id: number; post_id: number };
    }) => void (await apiAxios.post<void>("/post_comments", params)),
    update: async (post_comment: PostComment): Promise<PostComment> =>
      (
        await apiAxios.put<PostComment>(`/post_comments/${post_comment.id}`, {
          post_comment,
        })
      ).data,
    delete: async (id: number) =>
      void (await apiAxios.delete<void>(`/post_comments/${id}`)),
  },
  questions: {
    index: async () => (await apiAxios.get<Question[]>("/questions")).data,
    show: async (id: number): Promise<Question> =>
      (await apiAxios.get<Question>(`/questions/${id}`)).data,
    create: async (params: {
      question: {
        title: string;
        user_id: number;
        comment: string;
        confidential: boolean;
      };
    }): Promise<Question> =>
      (await apiAxios.post<Question>("/questions", params)).data,
    update: async (question: Question): Promise<Question> =>
      (
        await apiAxios.put<Question>(`/questions/${question.id}`, {
          question,
        })
      ).data,
    delete: async (id: number) =>
      void (await apiAxios.delete<void>(`/questions/${id}`)),
  },
  question_comments: {
    index: async (params: { question_id: number }) =>
      (await apiAxios.get<QuestionComment[]>("/question_comments", { params }))
        .data,
    create: async (params: {
      question_comment: {
        comment: string;
        user_id: number;
        question_id: number;
      };
    }) => void (await apiAxios.post<void>("/question_comments", params)),
    update: async (
      question_comment: QuestionComment
    ): Promise<QuestionComment> =>
      (
        await apiAxios.put<QuestionComment>(
          `/question_comments/${question_comment.id}`,
          {
            question_comment,
          }
        )
      ).data,
    delete: async (id: number) =>
      void (await apiAxios.delete<void>(`/question_comments/${id}`)),
  },
  notifications: {
    index: async () =>
      (await apiAxios.get<Notification[]>("/notifications")).data,
    delete: async (id: number) =>
      void (await apiAxios.delete<void>(`/notifications/${id}`)),
  },
  events: {
    index: async () => (await apiAxios.get<TimelineEvent[]>("/events")).data,
    create: async (params: {
      event: { details_kor: string; details_eng: string; date: string };
    }) => void (await apiAxios.post<void>("/events", params)),
    update: async (event: TimelineEvent): Promise<TimelineEvent> =>
      (
        await apiAxios.put<TimelineEvent>(`/events/${event.id}`, {
          event,
        })
      ).data,
    delete: async (id: number) =>
      void (await apiAxios.delete<void>(`/events/${id}`)),
  },
  calendarEvents: {
    index: async () =>
      (await apiAxios.get<CalendarEvent[]>("/calendar_events")).data,
    create: async (params: {
      calendar_event: {
        title: string;
        event_at: string;
        location: string;
        end_time: string | null;
        details: string;
      };
    }) => void (await apiAxios.post<void>("/calendar_events", params)),
    update: async (calendar_event: CalendarEvent): Promise<CalendarEvent> =>
      (
        await apiAxios.put<CalendarEvent>(
          `/calendar_events/${calendar_event.id}`,
          {
            calendar_event,
          }
        )
      ).data,
    delete: async (id: number) =>
      void (await apiAxios.delete<void>(`/calendar_events/${id}`)),
  },
};
