import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavBarBottom } from "../atoms";
import { Icon } from "../electrons";

import * as S from "./Pages.css";

const urls = [
  "/images/allOne.jpg",
  "/images/chinookBlast.jpeg",
  "/images/ensemble.jpg",
  "/images/performance.jpg",
  "/images/performance2.jpg",
  "/images/taekwondo.jpg",
];
const textForUrl = [
  "We Are All One",
  "Chinook Blast",
  "We Are All One",
  "Chinook Blast",
  "We Are All One",
  "We Are All One",
];

export const Home: FC = () => {
  const [urlIndex, setUrlIndex] = useState<number>(0);
  const { t } = useTranslation();
  useEffect(() => {
    const images = [];
    const preload = () => {
      for (const url of urls) {
        const img = new Image();
        img.src = url;
        images.push(img);
      }
    };
    preload();
  }, []);
  useEffect(() => {
    let timer1 = setInterval(() => {
      setUrlIndex((current) => (current + 1) % 6);
    }, 4000);

    return () => clearTimeout(timer1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <S.Container>
        <S.ImageSection imageUrl={urls[urlIndex]}>
          <S.AboutText>
            <S.AboutTitle>{t("title")}</S.AboutTitle>
            {t("home-page-description")}
            <S.ImageText>{textForUrl[urlIndex]}</S.ImageText>
          </S.AboutText>
          <S.Icons>
            <S.IconButtonLeft
              type="button"
              onClick={() => setUrlIndex((i) => (i + 5) % 6)}
            >
              <Icon iconName="angle-left" size="40px" color="white" />
            </S.IconButtonLeft>
            <S.IconButtonRight
              type="button"
              onClick={() => setUrlIndex((i) => (i + 1) % 6)}
            >
              <Icon iconName="angle-right" size="40px" color="white" />
            </S.IconButtonRight>
          </S.Icons>
        </S.ImageSection>
        <NavBarBottom />
      </S.Container>
    </>
  );
};
