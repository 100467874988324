import { FC, useEffect, useState } from "react";
import { QuestionsContainer, CreateQuestion, Loading } from "../molecules";

import * as S from "./Pages.css";
import { Button } from "../atoms";
import { Icon } from "../electrons";
import { useQuestionsContext } from "../contexts/QuestionsContext";
import { useAppContext } from "../contexts/AppContext";
import { Link } from "react-router-dom";
import { orderBy } from "lodash";
import { useTranslation } from "react-i18next";

export const QuestionBoard: FC = () => {
  const [isCreating, setIsCreating] = useState<boolean>(false);
  const { t } = useTranslation();
  const {
    dispatch,
    state: { questions, loading },
  } = useQuestionsContext();

  const {
    state: { user, locale },
  } = useAppContext();

  useEffect(() => () => dispatch({ type: "CLEAN_ALL" }), [dispatch]);

  if (isCreating)
    return (
      <CreateQuestion
        onCancel={() => setIsCreating(false)}
        onSubmit={(title, content, confidential) => {
          user &&
            dispatch({
              type: "CREATE_QUESTION",
              title,
              content,
              user_id: user.id,
              confidential,
            });
        }}
      />
    );

  return (
    <S.Container>
      <S.Title>{t("question-board")}</S.Title>
      {loading && <Loading />}
      <S.AddButtonWrapper>
        {user && (
          <Button onClick={() => setIsCreating(true)}>
            <Icon iconName="plus" />
            Add
          </Button>
        )}
        {!user && locale === "en" && (
          <small>
            <Link to="/sign-in">Sign in</Link> to ask a question
          </small>
        )}
        {!user && locale === "kr" && (
          <small>
            <Link to="/sign-in">로그인</Link> 후 글 작성하기
          </small>
        )}
      </S.AddButtonWrapper>
      <QuestionsContainer
        questions={
          user?.role === "kssc_admin"
            ? orderBy(
                questions,
                ["unanswered_comments", "id"],
                ["desc", "desc"]
              )
            : orderBy(questions, "id")
        }
      />
    </S.Container>
  );
};
