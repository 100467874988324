import React from "react";
import { Icon } from "../electrons";
import { usePagination } from "../hooks/usePagination";
import * as S from "./Pagination.css";

type Props = {
  onPageChange: (num: number) => void;
  totalCount: number;
  siblingCount: number;
  currentPage: number;
  pageSize: number;
};
export const Pagination = ({
  onPageChange,
  totalCount,
  siblingCount,
  currentPage,
  pageSize,
}: Props) => {
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });
  if (!paginationRange) return <></>;
  // If there are less than 2 times in pagination range we shall not render the component
  if (currentPage === 0 || (paginationRange?.length || 0) < 2) return null;

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  let lastPage =
    (paginationRange && paginationRange[paginationRange.length - 1]) || 0;
  return (
    <div>
      <S.PaginationButton
        type="button"
        onClick={onPrevious}
        disabled={currentPage === 1}
      >
        <Icon iconName="arrow-left" />
      </S.PaginationButton>
      {paginationRange.map((pageNumber: number | "DOTS_L" | "DOTS_R") => {
        if (pageNumber === "DOTS_L" || pageNumber === "DOTS_R")
          return (
            <S.Ellipsis key={pageNumber}>
              <Icon iconName="ellipsis" />
            </S.Ellipsis>
          );

        return (
          <S.PaginationButton
            type="button"
            key={pageNumber}
            onClick={() => onPageChange(pageNumber)}
            selected={currentPage === pageNumber}
          >
            {pageNumber}
          </S.PaginationButton>
        );
      })}
      <S.PaginationButton
        type="button"
        onClick={onNext}
        disabled={currentPage === lastPage}
      >
        <Icon iconName="arrow-right" />
      </S.PaginationButton>
    </div>
  );
};
