import styled from "styled-components";
import { Button } from "../atoms";
import { MEDIA } from "../styles";

export const BackButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;
`;
export const Container = styled.div`
  padding: 20px;
`;

export const CreateButton = styled(Button)`
  width: 200px;
  svg {
    padding-right: 5px;
  }
`;
export const AlbumsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 20px;
  gap: 5px;
  flex-direction: column;
`;

export const AlbumItem = styled.div`
  display: flex;
  align-items: center;
  color: ${(p) => (p.color ? p.color : "var(--kssc-dark-teal)")};
  width: 100%;
`;
export const ItemContainer = styled.div`
  background-color: var(--kssc-light-teal);
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 20px;
  margin-right: 35px;
  @media (max-width: ${MEDIA.xs}) {
    padding: 10px;
    width: 100%;
    flex-direction: column;

    > * svg {
      font-size: 15px;
    }
  }
`;
export const AlbumItemTitle = styled.div`
  font-weight: bold;
  font-size: var(--kssc-3xl);
`;
export const AlbumItemContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 10px 10px 10px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
  margin-top: 10px;
  button {
    margin: 0;
    padding: 10px 5px;
    width: 50px;
    svg {
      margin-right: 0;
    }
  }
`;

export const Photo = styled.div<{ isSelected?: boolean }>`
  * {
    box-sizing: border-box;
  }
  img {
    height: auto;
    width: auto;
    margin: 20px;
    border-radius: 5px;
    border: ${(p) =>
      p.isSelected ? "25px solid var(--kssc-yellow)" : "unset"};
  }
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--kssc-teal);
  font-size: var(--kssc-sm);
  margin-bottom: 20px;
`;
export const PhotosContainer = styled.div`
  display: flex;
  flex-wrap: wrap;

  @media (max-width: ${MEDIA.xs}) {
    justify-content: center;
  }
`;
export const ActionContainer = styled.div`
  background-color: var(--kssc-light-teal);
  border-radius: 5px;
  padding: 15px;
  font-size: 15px;
`;

export const HelperText = styled.div`
  color: var(--kssc-dark-teal);
  margin-top: 20px;
`;
export const ThumbnailText = styled.span`
  background-color: var(--kssc-yellow);
  border-radius: 5px;
  padding: 5px;
`;

export const PhotoModalWrapper = styled.span`
  border-radius: 4px;
  img {
    width: 100%;
  }
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  border: 10px solid var(--kssc-light-teal);
  background-color: black;
  span {
    padding: 10px;
    font-size: 20px;
    font-weight: 300;
    color: var(--kssc-white);
  }
`;
export const IconButton = styled(Button)`
  margin: 0;
  height: 45px;
  width: 45px;
  padding: 0;
  svg {
    margin: 0;
  }
  &:hover {
    border: 3px white solid;
  }
  box-shadow: none;
  border-radius: 0;

  &:disabled {
    &:hover {
      border: 0;
    }
    cursor: unset;
    svg {
      color: var(--kssc-dark-grey);
    }
    background-color: var(--kssc-black);
  }
`;
export const IconButtonLeft = styled(IconButton)`
  background-color: var(--kssc-black);
`;
export const IconButtonRight = styled(IconButton)`
  border: 0;
  background-color: unset;
`;
export const PhotoBase = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
