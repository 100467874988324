/* eslint-disable @typescript-eslint/naming-convention */
import { createContext, Dispatch, FC, Reducer, useContext } from "react";
import { AsyncActionHandlers, useReducerAsync } from "use-reducer-async";
import { api } from "../api";
import { handleError } from "../helpers/handlerError";
import { Notification } from "../types";
import orderBy from "lodash/orderBy";

export type PageState = {
  loading: boolean;
  error: string | null;
  notifications: Notification[];
};

type SyncAction =
  | { type: "BEGIN_REQUEST" }
  | { type: "CLEAN_ALL" }
  | { type: "INIT_SUCCESS"; notifications: Notification[] }
  | { type: "ADD_FROM_WS"; notification: Notification }
  | { type: "DELETE_FROM_WS"; notification_id: number }
  | { type: "REQUEST_FAILURE"; error: string }
  | { type: "REQUEST_SUCCESS" };

type AsyncAction =
  | { type: "INIT" }
  | { type: "DELETE_NOTIFICATION"; id: number };

export type NotificationAction = SyncAction | AsyncAction;

export const initialState: PageState = {
  loading: false,
  error: null,
  notifications: [],
};

export const reducer: Reducer<PageState, SyncAction> = (
  state,
  action
): PageState => {
  switch (action.type) {
    case "BEGIN_REQUEST":
      return { ...state, loading: true, error: null };
    case "CLEAN_ALL":
      return { ...state, loading: false, error: null };
    case "REQUEST_SUCCESS":
      return {
        ...state,
        loading: false,
        error: null,
      };
    case "INIT_SUCCESS":
      return {
        ...state,
        loading: false,
        error: null,
        notifications: action.notifications,
      };
    case "REQUEST_FAILURE":
      return { ...state, error: action.error, loading: false };
    case "ADD_FROM_WS":
      return {
        ...state,
        notifications: orderBy(
          [...state.notifications, action.notification],
          ["id"],
          ["asc"]
        ),
      };
    case "DELETE_FROM_WS":
      return {
        ...state,
        notifications: state.notifications.filter(
          (p) => p.id !== action.notification_id
        ),
      };
    default:
      return state;
  }
};

export const asyncActionHandlers: AsyncActionHandlers<
  Reducer<PageState, NotificationAction>,
  AsyncAction
> = {
  INIT:
    ({ dispatch }) =>
    async () => {
      dispatch({ type: "BEGIN_REQUEST" });
      try {
        const notifications = await api.notifications.index();
        dispatch({
          type: "INIT_SUCCESS",
          notifications: orderBy(notifications, "id"),
        });
      } catch (e) {
        console.error(e);
        const error = handleError(e);
        dispatch({ type: "REQUEST_FAILURE", error });
      }
    },
  DELETE_NOTIFICATION:
    ({ dispatch }) =>
    async (action: { id: number }) => {
      dispatch({ type: "BEGIN_REQUEST" });
      const { id } = action;
      try {
        await api.notifications.delete(id);

        dispatch({
          type: "REQUEST_SUCCESS",
        });
      } catch (e) {
        console.error(e);
        const error = handleError(e);
        dispatch({ type: "REQUEST_FAILURE", error });
      }
    },
};

const PageContext = createContext<{
  state: PageState;
  dispatch: Dispatch<NotificationAction>;
}>({
  state: initialState,
  dispatch: () => undefined,
});

export const useNotificationsContext = () => useContext(PageContext);

type Props = {
  overrideInitialState?: PageState;
  dispatchOverride?: Dispatch<NotificationAction>;
};

export const NotificationsProvider: FC<Props> = ({
  dispatchOverride,
  children,
  ...overrideInitialState
}) => {
  const [state, dispatch] = useReducerAsync<
    Reducer<PageState, SyncAction>,
    AsyncAction,
    NotificationAction
  >(reducer, { ...initialState, ...overrideInitialState }, asyncActionHandlers);

  const d = dispatchOverride || dispatch;

  return (
    <PageContext.Provider value={{ state, dispatch: d }}>
      {children}
    </PageContext.Provider>
  );
};
