import { FC } from "react";
import { useTranslation } from "react-i18next";
import * as S from "./Error.css";
import { Icon } from "./Icon";

type Props = {
  error: string;
};

export const ErrorMessage: FC<Props> = ({ error }) => {
  const { t } = useTranslation();
  return (
    <S.Container>
      <Icon iconName="exclamation-circle" size="30px" />
      <div>
        <S.ErrorTitle>{t("error-message")}: </S.ErrorTitle>
        <div>{error}</div>
      </div>
    </S.Container>
  );
};
