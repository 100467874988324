import { FC } from "react";

import * as S from "./Pages.css";

export const Vision: FC = () => {
  return (
    <S.Container>
      <S.SectionTeal>
        <S.Title>한인사회복지센터란?</S.Title>
        <S.Text>
          한인사회복지센터(이하 KSSC)는 다 함께 잘사는 공동체 사회건설을 목표로
          발족하였습니다.
        </S.Text>
        <S.Text>
          특히, 캘거리에 거주하는 한인을 그 중심대상에 두고 다양한 활동을 통해
          함께 나누며 돕고 발전하는 건강한 한인 공동체 사회를 만들고자 합니다.
          언어와 문화적 차이로 인해 이민 사회가 안고 있는 사회 적응의 어려움은
          아동과 청소년은 물론 중장년층을 비롯 노인층에 이르기까지 다양합니다.
        </S.Text>
        <S.Text>
          KSSC는 누구로부터도 손길이 미치지 못해 안타까운 상황에 처한 우리
          이웃과 함께할 것입니다. 또한, 마음의 상처가 더해가는 이웃이 마음을
          열고 자신감을 회복할 수 있도록 도움을 주고자 합니다. 다양한 프로그램을
          개설, 진행하여 역량있는 이민자가 새로운 터전에서 최대한 능력을 발휘할
          수 있도록 할 것입니다.
        </S.Text>
      </S.SectionTeal>
      <S.Text>
        뿐만 아니라 한인 사회에 안주하지 않고 인종과 종교 문화적 차이를 극복하여
        모두와 손을 잡을 수 있는 건강한 캘거리 지역공동체 사회를 만드는 데
        최선을 다할 것입니다.
      </S.Text>
      <S.Text>
        KSSC의 쉼 없는 활동이 캘거리는 물론 알버타 주를 넘어 캐나다 전 지역으로
        확대된다면 캐나다 사회에 정착하는 모든 분께 든든한 디딤돌이 될 것입니다.
      </S.Text>
      <S.Text>
        작은 디딤돌이 든든한 반석이 되는 그날까지 KSSC는 모든 역량을 다해 나아갈
        것입니다. 뜻있는 모든 분의 참여와 후원은 그날을 더욱 앞당길 것이라
        믿습니다.
      </S.Text>
      <S.SectionTeal>
        <S.Title>센터 미션과 비전</S.Title>
        <S.Text>
          <S.VisionStrong>미션</S.VisionStrong>
          한인 공동체가 사회적 자원, 정신건강 관련 자원, 그리고 교육에 접근하기
          용이하도록 가꾸어 나갑니다.
        </S.Text>
        <S.TextLast>
          <S.VisionStrong>비전</S.VisionStrong>
          누구나 언어 장벽 혹은 편견에 막히지 않고 사회적 자원, 정신건강 관련
          자원, 그리고 교육에 접근할 수 있는 공동체.
        </S.TextLast>
      </S.SectionTeal>
    </S.Container>
  );
};
