import { FC } from "react";
import { Link } from "react-router-dom";
import { useAppContext } from "../contexts/AppContext";
import * as S from "./ReturnButton.css";

export const ReturnButton: FC = () => {
  const {
    state: { locale },
  } = useAppContext();
  if (locale === "kr")
    return (
      <S.Text>
        <Link to="/">홈으로 돌아가기</Link>
      </S.Text>
    );
  return (
    <S.Text>
      Return to <Link to="/">home</Link>
    </S.Text>
  );
};
