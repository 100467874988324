import { FC } from "react";
import * as S from "./Input.css";

type Props = {
  label: string;
  value: string;
  onChange: React.ChangeEventHandler<HTMLTextAreaElement>;
  variant?: "light";
  onKeyDown?: React.KeyboardEventHandler<HTMLTextAreaElement> | undefined;
};

export const TextArea: FC<Props> = ({
  label,
  value,
  onChange,
  variant,
  onKeyDown,
}) => {
  return (
    <S.GroupComponent variant={variant}>
      <S.LabelComponent variant={variant}>
        <span>{label}</span>
        <S.TextAreaComponent
          value={value}
          onChange={onChange}
          variant={variant}
          onKeyDown={onKeyDown}
        />
      </S.LabelComponent>
    </S.GroupComponent>
  );
};
