import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC } from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";
import { MEDIA } from "../styles";

const ProgressBar = styled.span`
  background-color: var(--kssc-light-grey);
  color: var(--kssc-dark-grey);
  border-radius: 48px;
  padding: 5px 90px 7px 20px;
  span {
    margin-left: 10px;
    font-size: 17px;
  }
  @media (max-width: ${MEDIA.nav}) {
    display: block;
  }
`;

type Props = {
  size?: string;
};

export const WIP: FC<Props> = ({ size }) => {
  const { t } = useTranslation();

  return (
    <ProgressBar>
      <FontAwesomeIcon
        icon="screwdriver-wrench"
        fontSize={size || 20}
        color="var(--kssc-grey)"
      />
      <span>{t("wip")}</span>
    </ProgressBar>
  );
};
