import styled from "styled-components";
import { Button } from "../atoms";
import { MEDIA } from "../styles";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  gap: 15px;
`;

export const Item = styled.div<{ color?: string }>`
  display: flex;
  align-items: center;
  color: ${(p) => (p.color ? p.color : "var(--kssc-dark-teal)")};
  width: 100%;
`;
export const Number = styled.span<{ color?: string }>`
  font-size: var(--kssc-md);
  color: ${(p) => (p.color ? p.color : "var(--kssc-teal)")};
  padding: 26px 15px 26px 15px;
  margin-right: 5px;
`;

export const PostContainer = styled.div<{ background?: string }>`
  background-color: ${(p) =>
    p.background ? p.background : "var(--kssc-light-teal)"};
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  width: 1000px;
  padding: 20px;
  @media (max-width: ${MEDIA.xs}) {
    padding: 10px;
    width: 100%;
    height: 100px;
    flex-direction: column;

    > * svg {
      font-size: 15px;
    }
  }
`;
export const PostTitle = styled.span`
  font-size: var(--kssc-lg);
  padding-left: 5px;
  @media (max-width: ${MEDIA.xs}) {
    font-size: var(--kssc-md);
  }
`;
export const Title = styled.span`
  display: flex;
  gap: 15px;
  align-items: center;
`;
export const PostData = styled.span<{ color?: string }>`
  display: flex;
  flex-direction: column;
  color: ${(p) => (p.color ? p.color : "var(--kssc-dark-teal)")};
  gap: 3px;
  @media (max-width: ${MEDIA.xs}) {
    font-size: var(--kssc-sm);
  }
`;
export const PostCreator = styled.span`
  font-size: var(--kssc-md);
  font-weight: bold;
  @media (max-width: ${MEDIA.xs}) {
    font-size: var(--kssc-sm);
  }
`;
export const PostDate = styled.span`
  font-size: var(--kssc-md);
  font-weight: 100;
  svg {
    padding-left: 10px;
  }
`;

export const PostDetails = styled.div`
  display: flex;
  align-items: center;
`;
export const DestroyButton = styled(Button)`
  padding: 10px 5px;
  width: 40px;
  svg {
    margin-right: 0;
  }
`;

export const DeleteConfirm = styled.div`
  font-weight: bold;
  small {
    padding-top: 5px;
    display: block;
    font-size: 15px;
  }
`;
