import { Icon } from "../electrons";
import { FC } from "react";

import * as S from "./Pages.css";

export const FindingUs: FC = () => (
  <S.Container>
    <S.Title>오시는길</S.Title>
    <S.SubTitle>
      <Icon iconName="location-dot" /> 4838 Richard Rd SW Calgary, AB T3E 6L1
    </S.SubTitle>
    <S.Map
      title="오시는길"
      loading="lazy"
      referrerPolicy="no-referrer-when-downgrade"
      src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJpVjvMMBxcVMRo3cHKURLzWE&key=AIzaSyClRlswHYgYQjGOktxQhkqCfCSFuqzmDwU&language=ko"
    />
  </S.Container>
);
