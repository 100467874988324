import styled from "styled-components";
export const Container = styled.div`
  opacity: 0.7;
  filter: alpha(opacity=20);
  background-color: #000;
  width: 100%;
  height: 100%;
  z-index: 10;
  top: 0;
  left: 0;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LoadingIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: var(--kssc-white);
  font-size: var(--kssc-4xl);
`;
