import { createContext, Dispatch, FC, Reducer, useContext } from "react";
import { AsyncActionHandlers, useReducerAsync } from "use-reducer-async";
import { api } from "../api";
import { handleError } from "../helpers/handlerError";
import { Post } from "../types";

export type PageState = {
  loading: boolean;
  error: string | null;
  posts: Post[];
};

type SyncAction =
  | { type: "BEGIN_REQUEST" }
  | { type: "CLEAN_ALL" }
  | { type: "ADD_FROM_WS"; post: Post }
  | { type: "REQUEST_FAILURE"; error: string }
  | { type: "REQUEST_SUCCESS"; posts: Post[] };

type AsyncAction =
  | { type: "FETCH_POSTS" }
  | { type: "CREATE_POST"; title: string; content: string; user_id: number }
  | { type: "UPDATE_POST"; post: Post }
  | { type: "DELETE_POST"; id: number };

export type PostAction = SyncAction | AsyncAction;

export const initialState: PageState = {
  loading: false,
  error: null,
  posts: [],
};

export const reducer: Reducer<PageState, SyncAction> = (
  state,
  action
): PageState => {
  switch (action.type) {
    case "BEGIN_REQUEST":
      return { ...state, loading: true, error: null };
    case "ADD_FROM_WS":
      return {
        ...state,
        posts: [...state.posts, action.post],
      };
    case "CLEAN_ALL":
      return { ...state, loading: false, error: null };
    case "REQUEST_SUCCESS":
      return { ...state, loading: false, error: null, posts: action.posts };
    case "REQUEST_FAILURE":
      return { ...state, error: action.error, loading: false };
    default:
      return state;
  }
};

export const asyncActionHandlers: AsyncActionHandlers<
  Reducer<PageState, PostAction>,
  AsyncAction
> = {
  FETCH_POSTS:
    ({ dispatch }) =>
    async () => {
      dispatch({ type: "BEGIN_REQUEST" });
      try {
        const posts = await api.posts.index();
        dispatch({
          type: "REQUEST_SUCCESS",
          posts,
        });
      } catch (e) {
        console.error(e);
        const error = handleError(e);
        dispatch({ type: "REQUEST_FAILURE", error });
      }
    },
  CREATE_POST:
    ({ dispatch }) =>
    async (action: { title: string; content: string; user_id: number }) => {
      dispatch({ type: "BEGIN_REQUEST" });
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { title, content, user_id } = action;
      try {
        await api.posts.create({
          post: { title, user_id, comment: content },
        });
        const posts = await api.posts.index();
        dispatch({
          type: "REQUEST_SUCCESS",
          posts,
        });
      } catch (e) {
        console.error(e);
        const error = handleError(e);
        dispatch({ type: "REQUEST_FAILURE", error });
      }
    },
  UPDATE_POST:
    ({ dispatch }) =>
    async (action: { post: Post }) => {
      dispatch({ type: "BEGIN_REQUEST" });
      const { post } = action;
      try {
        await api.posts.update(post);
        const posts = await api.posts.index();
        dispatch({
          type: "REQUEST_SUCCESS",
          posts,
        });
      } catch (e) {
        console.error(e);
        const error = handleError(e);
        dispatch({ type: "REQUEST_FAILURE", error });
      }
    },
  DELETE_POST:
    ({ dispatch }) =>
    async (action: { id: number }) => {
      dispatch({ type: "BEGIN_REQUEST" });
      const { id } = action;
      try {
        await api.posts.delete(id);
        const posts = await api.posts.index();
        dispatch({
          type: "REQUEST_SUCCESS",
          posts,
        });
      } catch (e) {
        console.error(e);
        const error = handleError(e);
        dispatch({ type: "REQUEST_FAILURE", error });
      }
    },
};

const PageContext = createContext<{
  state: PageState;
  dispatch: Dispatch<PostAction>;
}>({
  state: initialState,
  dispatch: () => undefined,
});

export const usePostsContext = () => useContext(PageContext);

type Props = {
  overrideInitialState?: PageState;
  dispatchOverride?: Dispatch<PostAction>;
};

export const PostsProvider: FC<Props> = ({
  dispatchOverride,
  children,
  ...overrideInitialState
}) => {
  const [state, dispatch] = useReducerAsync<
    Reducer<PageState, SyncAction>,
    AsyncAction,
    PostAction
  >(reducer, { ...initialState, ...overrideInitialState }, asyncActionHandlers);

  const d = dispatchOverride || dispatch;

  return (
    <PageContext.Provider value={{ state, dispatch: d }}>
      {children}
    </PageContext.Provider>
  );
};
