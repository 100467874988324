import { createContext, Dispatch, FC, Reducer, useContext } from "react";
import { AsyncActionHandlers, useReducerAsync } from "use-reducer-async";
import { api } from "../api";
import { handleError } from "../helpers/handlerError";
import { User } from "../types";

export type PageState = {
  loading: boolean;
  error: string | null;
  user: User | null;
  locale: "kr" | "en";
};

type SyncAction =
  | { type: "BEGIN_REQUEST" }
  | { type: "SET_LOCALE"; locale: "kr" | "en" }
  | { type: "REQUEST_FAILURE"; error: string }
  | { type: "REQUEST_SUCCESS" }
  | { type: "SET_USER"; user: User | null }
  | { type: "CLEAN_ALL" };

type AsyncAction =
  | { type: "SIGN_UP"; email: string; password: string; name: string }
  | { type: "SIGN_IN"; email: string; password: string }
  | {
      type: "RESEND_EMAIL";
      email: string;
      kind: "Forgot Password" | "Resend Confirmation";
    }
  | {
      type: "RESET_PASSWORD";
      password: string;
      token: string | null;
    }
  | { type: "UPDATE_USER"; name: string; id: number }
  | { type: "SIGN_OUT" };

type Action = SyncAction | AsyncAction;

export const initialState: PageState = {
  user: null,
  loading: false,
  error: null,
  locale: "kr",
};

export const reducer: Reducer<PageState, SyncAction> = (
  state,
  action
): PageState => {
  switch (action.type) {
    case "SET_USER":
      return { ...state, user: action.user, loading: false, error: null };
    case "SET_LOCALE":
      return { ...state, locale: action.locale };
    case "BEGIN_REQUEST":
      return { ...state, loading: true, error: null };
    case "REQUEST_SUCCESS":
      return { ...state, loading: false, error: null };
    case "REQUEST_FAILURE":
      return { ...state, error: action.error, loading: false };
    case "CLEAN_ALL":
      return { ...state, error: null, loading: false };
    default:
      return state;
  }
};

export const asyncActionHandlers: AsyncActionHandlers<
  Reducer<PageState, Action>,
  AsyncAction
> = {
  SIGN_IN:
    ({ dispatch }) =>
    async (action: { email: string; password: string }) => {
      const { email, password } = action;
      dispatch({ type: "BEGIN_REQUEST" });
      try {
        await api.users.signIn({ user: { email, password } });
        const user = await api.users.show();
        dispatch({
          type: "SET_USER",
          user: user,
        });
        window.location.href = "/";
      } catch (e) {
        console.error(e);
        const error = handleError(e);
        dispatch({ type: "REQUEST_FAILURE", error });
      }
    },
  SIGN_UP:
    ({ dispatch }) =>
    async (action: { email: string; password: string; name: string }) => {
      const { email, password, name } = action;
      dispatch({ type: "BEGIN_REQUEST" });
      try {
        await api.users.create({ user: { email, password, name } });
        dispatch({
          type: "REQUEST_SUCCESS",
        });
        window.location.href = "/";
      } catch (e) {
        const error = handleError(e);
        dispatch({ type: "REQUEST_FAILURE", error });
      }
    },
  RESEND_EMAIL:
    ({ dispatch }) =>
    async (action: {
      email: string;
      kind: "Forgot Password" | "Resend Confirmation";
    }) => {
      const { email, kind } = action;
      dispatch({ type: "BEGIN_REQUEST" });
      try {
        if (kind === "Resend Confirmation")
          await api.users.resendConfirmation(email);
        else await api.users.sendPasswordReset(email);
        dispatch({
          type: "REQUEST_SUCCESS",
        });
      } catch (e) {
        const error = handleError(e);
        dispatch({ type: "REQUEST_FAILURE", error });
      }
    },
  RESET_PASSWORD:
    ({ dispatch }) =>
    async (action: { password: string; token: string | null }) => {
      const { password, token } = action;
      dispatch({ type: "BEGIN_REQUEST" });
      try {
        await api.users.resetPassword(password, token);
        dispatch({
          type: "REQUEST_SUCCESS",
        });
      } catch (e) {
        const error = handleError(e);
        dispatch({ type: "REQUEST_FAILURE", error });
      }
    },
  UPDATE_USER:
    ({ dispatch }) =>
    async (action: { name: string; id: number }) => {
      const { name, id } = action;
      dispatch({ type: "BEGIN_REQUEST" });
      try {
        await api.users.update(name, id);
        const user = await api.users.show();
        dispatch({
          type: "SET_USER",
          user,
        });
      } catch (e) {
        const error = handleError(e);
        dispatch({ type: "REQUEST_FAILURE", error });
      }
    },
  SIGN_OUT:
    ({ dispatch }) =>
    async () => {
      dispatch({ type: "BEGIN_REQUEST" });
      try {
        await api.users.signOut();
        dispatch({
          type: "SET_USER",
          user: null,
        });
        window.location.href = "/";
      } catch (e) {
        const error = handleError(e);
        dispatch({ type: "REQUEST_FAILURE", error });
      }
    },
};

const PageContext = createContext<{
  state: PageState;
  dispatch: Dispatch<Action>;
}>({
  state: initialState,
  dispatch: () => undefined,
});

export const useAppContext = () => useContext(PageContext);

type Props = {
  overrideInitialState?: PageState;
  dispatchOverride?: Dispatch<Action>;
};

export const AppProvider: FC<Props> = ({
  dispatchOverride,
  children,
  ...overrideInitialState
}) => {
  const [state, dispatch] = useReducerAsync<
    Reducer<PageState, SyncAction>,
    AsyncAction,
    Action
  >(reducer, { ...initialState, ...overrideInitialState }, asyncActionHandlers);

  const d = dispatchOverride || dispatch;

  return (
    <PageContext.Provider value={{ state, dispatch: d }}>
      {children}
    </PageContext.Provider>
  );
};
