import styled from "styled-components";
import { MEDIA } from "../styles";

export const Container = styled.span`
  padding: 1rem;
  display: flex;
  align-items: center;
  display: inline-flex;
  align-items: flex-start;
  padding-bottom: 0;
  @media (max-width: ${MEDIA.xs}) {
    flex-direction: column;
    margin: 5px;
    padding: 0;
  }
`;
export const Logo = styled.img`
  padding-right: 10px;
  height: 35%;
  width: 35%;
  display: inline;
  padding: 10px;
  &:hover {
    cursor: pointer;
  }
  @media (max-width: ${MEDIA.xs}) {
    width: 100%;
    padding-right: 0;
  }
`;

export const TextLogo = styled.div`
  display: flex;
  flex-direction: column;
`;
export const HomeLink = styled.div`
  color: var(--kssc-text);
  font-size: var(--kssc-5xl);
  &:hover {
    color: var(--kssc-red);
    cursor: pointer;
  }

  @media (max-width: ${MEDIA.xs}) {
    display: none;
  }
`;
export const Description = styled.span`
  color: var(--kssc-text);
  @media (max-width: ${MEDIA.xs}) {
    display: none;
  }
`;
