import { Icon } from "../electrons";
import { FC } from "react";
import * as S from "./IconWithText.css";
type Props = {
  iconName: string;
  title?: string;
  textLine1: string;
  textLine2?: string;
  textLine3?: string;
  textLine4?: string;
};

export const IconWithText: FC<Props> = ({
  iconName,
  title,
  textLine1,
  textLine2,
  textLine3,
  textLine4,
}) => {
  return (
    <S.Container>
      <Icon iconName={iconName} />
      {title && <S.Title>{title}</S.Title>}
      <S.Text>{textLine1}</S.Text>
      {textLine2 && <S.Text>{textLine2}</S.Text>}
      {textLine3 && <S.Text>{textLine3}</S.Text>}
      {textLine4 && <S.Text>{textLine4}</S.Text>}
    </S.Container>
  );
};
