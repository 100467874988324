import { FC } from "react";
import { useTranslation } from "react-i18next";

import * as S from "./Pages.css";

export const GeneralCounselling: FC = () => {
  const { t } = useTranslation();

  return (
    <S.Container>
      <S.Title>{t("general-counselling")}</S.Title>
      <S.Text>
        KSSC offers 1-on-1 settlement and integration counseling to help settle
        newcomers into a stable life in the Calgary community by providing
        guidance and information for medical care, education, transportation,
        housing, government benefits, income tax, and life in Canada. In
        particular, support is provided to lower cultural and language barriers
        so that newcomers have timely access to the various government benefits
        and entitlements that they are eligible for. For this reason, we support
        newcomers from the very beginning of the settlement period in their
        preferred language. This program caters to this vulnerable population by
        facilitating their landing, promoting their integration via smoother
        transition and supporting their benefit claims. While we initially
        focused on the Korean community in southern Alberta, we do welcome and
        assist other ethnic communities and routinely receive service requests
        from far outside of Alberta.
      </S.Text>
    </S.Container>
  );
};
