import styled, { keyframes } from "styled-components";
import { MEDIA } from "../styles";

const slideRight = keyframes`
from {
  left: -80px;
}

to {
  left: 0px;
}
`;

export const NavTitle = styled.span`
  svg {
    margin-right: 3px;
  }
`;

export const NavSection = styled.div`
  color: var(--kssc-dark-teal);
  height: 4rem;
  position: relative;
  > * {
    box-sizing: border-box;
  }

  @media (max-width: ${MEDIA.nav}) {
    font-size: var(--kssc-xlg);
    height: unset;
  }
`;
export const NavSectionSmall = styled.div`
  color: var(--kssc-dark-teal);
  position: relative;
  > * {
    box-sizing: border-box;
  }

  @media (max-width: ${MEDIA.nav}) {
    font-size: var(--kssc-xlg);
    height: unset;
  }
`;
export const NavSectionBottom = styled.div`
  color: var(--kssc-dark-teal);
  position: relative;
  > * {
    box-sizing: border-box;
  }

  @media (max-width: ${MEDIA.nav}) {
    font-size: var(--kssc-xlg);
    height: unset;
  }
`;

export const NavItem = styled.div<{ hoverable: boolean }>`
  padding: 1.15rem 1.5rem;
  position: relative;
  height: 100%;
  white-space: nowrap;
  &:hover {
    color: ${(p) =>
      p.hoverable ? "var(--kssc-teal)" : "var(--kssc-dark-teal)"};
    cursor: pointer;
  }
  svg {
    padding: 0 3px;
  }

  @media (max-width: ${MEDIA.md}) {
    font-size: 14px;
    padding: 1rem;
    svg {
      height: 14px;
    }
  }

  @media (max-width: ${MEDIA.nav}) {
    height: unset;
    display: flex;
    justify-content: space-between;
  }
`;
export const NavItemSmall = styled.div`
  padding: 3px 10px;
  font-size: var(--kssc-lg);
  color: var(--kssc-dark-yellow);
  &:hover {
    cursor: pointer;
    color: var(--kssc-yellow);
  }
  svg {
    padding: 0 3px;
  }
  @media (max-width: ${MEDIA.nav}) {
    width: unset;
  }
`;
export const NavItemBottom = styled.div<{
  background: string;
  backgroundHover: string;
}>`
  padding: 2rem;
  box-sizing: border-box;
  border: 5px solid var(--kssc-white);
  width: 100%;
  position: relative;
  text-align: center;
  color: var(--kssc-white);
  background-color: ${(p) => p.background};
  &:hover {
    background-color: ${(p) => p.backgroundHover};
    cursor: pointer;
  }
  svg {
    padding: 0 3px;
  }
`;
export const NavSubItemsContainer = styled.div<{ isVisible: boolean }>`
  display: ${(p) => (p.isVisible ? "block" : "none")};
  position: absolute;
  top: 4rem;
  z-index: 3;
  left: 0;
  background-color: var(--kssc-white);
  color: var(--kssc-dark-teal);
  box-shadow: 13px 16px 25px rgb(0 0 0 / 0.3);

  @media (max-width: ${MEDIA.nav}) {
    position: relative;
    width: 100%;
    top: 0;
  }

  border-radius: 0 0 5px 5px;

  &:hover {
    border-radius: 0 0 5px 5px;
    cursor: pointer;
  }

  nav:hover {
    background-color: var(--kssc-yellow);
  }
  nav:last-child:hover {
    border-radius: 0 0 5px 5px;
  }
`;
export const NavSubSubItemsContainer = styled.div<{ isVisible: boolean }>`
  display: ${(p) => (p.isVisible ? "block" : "none")};
  position: absolute;
  top: 0rem;
  left: 170px;
  background-color: var(--kssc-white);
  color: var(--kssc-dark-teal);
  box-shadow: 13px 16px 25px rgb(0 0 0 / 0.3);

  @media (max-width: ${MEDIA.nav}) {
    position: relative;
    width: 100%;
    top: 0;
    left: 0;
  }

  border-radius: 0 0 5px 5px;

  &:hover {
    border-radius: 0 0 5px 5px;
    cursor: pointer;
  }

  article:last-child:hover {
    border-radius: 0 0 5px 5px;
  }
`;

export const Wrapper = styled.nav``;

export const NavSubItem = styled.section`
  padding: 1.15rem 1.5rem;
  width: 170px;
  position: relative;

  svg {
    padding-left: 6px;
  }
  &:hover {
    svg {
      animation: ${slideRight} 0.5s;
      animation-fill-mode: forwards;
      position: relative;
    }
  }

  @media (max-width: ${MEDIA.nav}) {
    width: 90%;
    display: flex;
    justify-content: space-between;
    &:hover {
      svg {
        animation: none;
      }
    }
  }
`;
export const NavSubSubItem = styled.article`
  padding: 1.15rem 1.5rem;
  width: 170px;
  &:hover {
    color: var(--kssc-dark-teal);
    background-color: var(--kssc-light-yellow);
  }

  @media (max-width: ${MEDIA.nav}) {
    width: 100%;
  }
`;
