import React, { FC, useEffect, useState } from "react";
import FullCalendar, { EventClickArg } from "@fullcalendar/react"; // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import momentPlugin from "@fullcalendar/moment";
import interactionPlugin from "@fullcalendar/interaction";

import * as S from "./Timeline.css";
import { CreateCalendarEvent, EventTooltip } from "../molecules";
import { useCalendarContext } from "../contexts/CalendarContext";
import { useAppContext } from "../contexts/AppContext";
import { Icon } from "../electrons";
import { Title } from "./Pages.css";
import moment from "moment";
import { useComponentVisible } from "../helpers/useComponentVisible";
import { CalendarEvent } from "../types";
import { useTranslation } from "react-i18next";

export const Schedule: FC = () => {
  const {
    state: { locale },
  } = useAppContext();
  const { t } = useTranslation();
  const [isAdding, setIsAdding] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [currentEvent, setCurrentEvent] = useState<CalendarEvent | null>(null);
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);
  const {
    state: { user },
  } = useAppContext();
  const {
    dispatch,
    state: { calendarEvents },
  } = useCalendarContext();

  useEffect(() => {
    dispatch({ type: "FETCH_EVENTS" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => () => dispatch({ type: "CLEAN_ALL" }), [dispatch]);
  const events = calendarEvents.map((e) => ({
    title: e.title,
    start: moment(e.event_at).toDate(),
    end: (e.end_time && moment(e.end_time).toDate()) || undefined,
    allDay: !e.end_time,
    extendedProps: { id: e.id },
  }));
  const pageContent = () => {
    if (isAdding)
      return (
        <CreateCalendarEvent
          onCancel={() => setIsAdding(false)}
          onSubmit={(event_at, title, location, end_time, details) =>
            dispatch({
              type: "CREATE_EVENT",
              event_at,
              title,
              location,
              end_time,
              details,
            })
          }
          event={undefined}
        />
      );
    if (isEditing && currentEvent)
      return (
        <CreateCalendarEvent
          onCancel={() => setIsEditing(false)}
          event={currentEvent}
          onSubmit={(event: CalendarEvent) => {
            dispatch({
              type: "UPDATE_EVENT",
              event,
            });
            setCurrentEvent(null);
          }}
        />
      );
    return (
      <S.CalendarWrapper>
        {currentEvent && isComponentVisible && (
          <EventTooltip
            event={currentEvent}
            user={user}
            visible={isComponentVisible}
            setIsVisible={setIsComponentVisible}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            ref={ref as any}
            onEdit={(event) => {
              setCurrentEvent(event);
              setIsEditing(true);
            }}
            onDelete={(event) => {
              dispatch({
                type: "DELETE_EVENT",
                id: event.id,
              });
              setCurrentEvent(null);
            }}
          />
        )}
        <FullCalendar
          plugins={[dayGridPlugin, momentPlugin, interactionPlugin]}
          initialView="dayGridMonth"
          locale={locale === "kr" ? "ko" : "en"}
          events={events}
          eventClick={(arg: EventClickArg) => {
            setCurrentEvent(
              calendarEvents.find(
                (e) => e.id === arg.event.extendedProps?.id
              ) || null
            );
            setIsComponentVisible(true);
          }}
        />
      </S.CalendarWrapper>
    );
  };
  return (
    <S.Container>
      <Title>{t("calendar")}</Title>
      {user?.role === "kssc_admin" && (
        <S.CreateButton onClick={() => setIsAdding(true)}>
          <Icon iconName="calendar-plus" />
          Add Event
        </S.CreateButton>
      )}
      {pageContent()}
    </S.Container>
  );
};
