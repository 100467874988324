import { FC } from "react";
import { useTranslation } from "react-i18next";

import * as S from "./Pages.css";

export const Greetings: FC = () => {
  const { t } = useTranslation();

  return (
    <S.Container>
      <S.Title>{t("greetings")}</S.Title>
      <S.Greeting>
        <S.Chairwoman src="/images/kim-young-in.jpg" alt="kim-young-in" />
        <S.GreetingText>
          <S.StaffListItem>{t("president")}</S.StaffListItem>
          <p>안녕하세요. </p>
          <p>
            우리 한인사회복지센터(센터)는 다양한 분야에서 한인 공동체의 일원인
            여러분을 중심으로 가능한 한 많은 도움의 손길을 내밀어 함께 나아가는
            사회를 만들고자 설립되었습니다.
          </p>{" "}
          <p>
            이민자로서 주류사회에 쉽게 들어가지 못하고 고립될 수 밖에 없는 타지
            생활을 하고 계시는 많은 분들께 도움이 될 수 있으면 합니다.
          </p>{" "}
          <p>
            비록 시작은 작은 한 걸음에 불과하지만 지금 내딛는 작은 발걸음
            하나하나가 부지런히 타지 생활에 있어 부족하게 느껴졌던 부분들을
            채워줄 수 있기를 바랍니다.
          </p>{" "}
          <p>
            복지란 사람의 삶을 가장 사람답게, 그리고 종국엔 모두의 삶이 윤택도록
            만들기 위한 분야입니다. 따라서 우리 센터는 복지 시스템이 잘 되어있는
            캐나다에 더이상 협의회 형식이 아닌 종합복지의 개념을 적용하고
            있습니다. 많은 분들의 큰 관심과 지지에 다시 한 번 감사드리며, 우리
            공동체 안팎으로 널리 퍼질 수 있는 선한 영향력을 위해 더욱 열심히
            뛰겠습니다.
          </p>{" "}
          <strong>회장 김영인</strong>
          <S.Hr />
        </S.GreetingText>
      </S.Greeting>
    </S.Container>
  );
};
