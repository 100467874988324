import { FC } from "react";
import { useAppContext } from "../contexts/AppContext";
import { Icon } from "../electrons";

import * as S from "./Pages.css";

export const TranslationService: FC = () => {
  const {
    state: { locale },
  } = useAppContext();
  return (
    <S.Container>
      {locale === "kr" ? (
        <>
          <S.Title>통역 및 번역 서비스</S.Title>
          <S.Text>
            생활 통/번역이 가능한 봉사자들로 구성된 서비스 입니다. 한국어를
            지원함으로써 영어자원에 대한 접근성을 높이고, 언어 장벽 때문에
            캐나다 사회에서 소외되지 않도록 돕습니다.
          </S.Text>
          <S.Text>
            병원예약, 레지스트리, 정부 우편물 통/번역 등의 서비스를 제공하고
            있습니다. 이 외에도 영어로 인해 일상생활에 어려움이 있는 부분이
            있으시면 연락주세요.
          </S.Text>
          <S.Text>
            통/번역 서비스는 자격증이 없어도 한국어와 영어로 의사소통이 가능하신
            자원봉사자분들을 항상 모집하고 있습니다. 봉사활동을 원하시는 분은
            <S.ContactUsText>
              <Icon iconName="envelope" color="var(--kssc-teal)" size="18px" />
              <span>
                <a href="mailto:general@kssc-ab.ca">general@kssc-ab.ca</a>
              </span>
            </S.ContactUsText>
            로 연락 부탁드립니다.
          </S.Text>
        </>
      ) : (
        <>
          <S.Title>Interpretation / Translation</S.Title>
          <S.Text>
            One of the greatest hurdles that immigrants face in Calgary is the
            language access barrier to services. KSSC offers phone-based and
            in-person real-time interpretation services to facilitate access by
            Korean newcomers to government programs, benefits and entitlements.
          </S.Text>
          <S.Text>
            We also offer translation services for official documents to support
            government applications.
          </S.Text>
        </>
      )}
    </S.Container>
  );
};
