import { FC, useState } from "react";
import { useAppContext } from "../contexts/AppContext";
import * as S from "./PostComments.css";
import * as E from "./EventTooltip.css";
import { Icon } from "../electrons";
import { Button } from "../atoms";
import moment from "moment";
import { QuestionComment } from "../types";

type Props = {
  comment: QuestionComment;
  onEdit: (comment: QuestionComment) => void;
  onDelete: (id: number) => void;
};
export const QuestionCommentItem: FC<Props> = ({
  comment,
  onEdit,
  onDelete,
}) => {
  const [mode, setMode] = useState<"view" | "edit" | "delete">("view");
  const [commentContent, setCommentContent] = useState<string>(comment.comment);
  const {
    state: { user },
  } = useAppContext();
  if (!user) throw new Error("User is undefined!");

  const pageContent = () => {
    if (mode === "edit")
      return (
        <S.EditPost>
          <S.CommentInput
            value={commentContent}
            onChange={(e) => setCommentContent(e.target.value)}
            onKeyDown={(e: React.KeyboardEvent<HTMLTextAreaElement>) => {
              if (e.key === "Enter" && !e.shiftKey) {
                e.preventDefault();
                onEdit({ ...comment, comment: commentContent });
                setMode("view");
              }
            }}
          />
          <Button
            onClick={() => {
              onEdit({ ...comment, comment: commentContent });
              setMode("view");
            }}
          >
            <Icon iconName={"paper-plane"} />
          </Button>
        </S.EditPost>
      );
    if (mode === "delete")
      return (
        <>
          <E.DeleteConfirm>Are you sure?</E.DeleteConfirm>
          <E.ButtonsContainer>
            <Button
              onClick={() => onDelete(comment.id)}
              color="var(--kssc-green)"
            >
              삭제
            </Button>
            <Button color="var(--kssc-grey)" onClick={() => setMode("view")}>
              취소
            </Button>
          </E.ButtonsContainer>
        </>
      );
    return (
      <>
        <S.PostActions>
          <S.PostDetails>
            <strong>{comment.username}</strong>
            <small>{moment(comment.created_at).format("MM/DD/YY hh:mm")}</small>
          </S.PostDetails>
          {comment.user_id === user.id && (
            <S.IconButtons>
              <button type="button" onClick={() => setMode("edit")}>
                <Icon iconName="pencil" size="12px" color="var(--kssc-teal)" />
              </button>
              <button type="button" onClick={() => setMode("delete")}>
                <Icon iconName="trash" size="12px" color="var(--kssc-red)" />
              </button>
            </S.IconButtons>
          )}
        </S.PostActions>
        <div>{comment.comment}</div>
      </>
    );
  };

  return (
    <S.PostCommentWrapper isReply={comment.user_id !== user.id}>
      <S.Post question isReply={comment.user_id !== user.id}>
        {pageContent()}
      </S.Post>
    </S.PostCommentWrapper>
  );
};
