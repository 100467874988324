import { FC } from "react";
import { WIP } from "../electrons";

import * as S from "./Pages.css";

export const Jobs: FC = () => (
  <S.Container>
    <S.Title>채용정보</S.Title>
    <S.Text>
      <WIP />
    </S.Text>
  </S.Container>
);
