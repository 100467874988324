import { createContext, Dispatch, FC, Reducer, useContext } from "react";
import { AsyncActionHandlers, useReducerAsync } from "use-reducer-async";
import { api } from "../api";
import { handleError } from "../helpers/handlerError";
import { Newsletter } from "../types";

export type PageState = {
  loading: boolean;
  error: string | null;
  newsletters: Newsletter[];
};

type SyncAction =
  | { type: "BEGIN_REQUEST" }
  | { type: "CLEAN_ALL" }
  | { type: "REQUEST_FAILURE"; error: string }
  | { type: "REQUEST_SUCCESS"; newsletters: Newsletter[] };

type AsyncAction =
  | { type: "FETCH_NEWSLETTERS" }
  | { type: "ADD_NEWSLETTER"; name: string; file: string; date: string }
  | { type: "DELETE_NEWSLETTER"; id: number }
  | { type: "UPDATE_NEWSLETTER"; newsletter: Newsletter };

export type NewslettersAction = SyncAction | AsyncAction;

export const initialState: PageState = {
  loading: false,
  error: null,
  newsletters: [],
};

export const reducer: Reducer<PageState, SyncAction> = (
  state,
  action
): PageState => {
  switch (action.type) {
    case "BEGIN_REQUEST":
      return { ...state, loading: true, error: null };
    case "CLEAN_ALL":
      return { ...state, loading: false, error: null };
    case "REQUEST_SUCCESS":
      return {
        ...state,
        loading: false,
        error: null,
        newsletters: action.newsletters,
      };
    case "REQUEST_FAILURE":
      return { ...state, error: action.error, loading: false };
    default:
      return state;
  }
};

export const asyncActionHandlers: AsyncActionHandlers<
  Reducer<PageState, NewslettersAction>,
  AsyncAction
> = {
  FETCH_NEWSLETTERS:
    ({ dispatch }) =>
    async () => {
      dispatch({ type: "BEGIN_REQUEST" });
      try {
        const newsletters = await api.newsletters.index();
        dispatch({
          type: "REQUEST_SUCCESS",
          newsletters,
        });
      } catch (e) {
        console.error(e);
        const error = handleError(e);
        dispatch({ type: "REQUEST_FAILURE", error });
      }
    },
  ADD_NEWSLETTER:
    ({ dispatch, getState }) =>
    async (action: { name: string; file: string; date: string }) => {
      dispatch({ type: "BEGIN_REQUEST" });
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { name, file, date } = action;
      try {
        const newsletter = await api.newsletters.create({
          newsletter: { name, image: file, date },
        });
        dispatch({
          type: "REQUEST_SUCCESS",
          newsletters: [...getState().newsletters, newsletter],
        });
      } catch (e) {
        console.error(e);
        const error = handleError(e);
        dispatch({ type: "REQUEST_FAILURE", error });
      }
    },
  UPDATE_NEWSLETTER:
    ({ dispatch, getState }) =>
    async (action: { newsletter: Newsletter }) => {
      dispatch({ type: "BEGIN_REQUEST" });
      const { newsletter } = action;
      try {
        const newsletterResponse = await api.newsletters.update(newsletter);
        dispatch({
          type: "REQUEST_SUCCESS",
          newsletters: [
            ...getState().newsletters.filter((p) => p.id !== newsletter.id),
            newsletterResponse,
          ],
        });
      } catch (e) {
        console.error(e);
        const error = handleError(e);
        dispatch({ type: "REQUEST_FAILURE", error });
      }
    },
  DELETE_NEWSLETTER:
    ({ dispatch, getState }) =>
    async (action: { id: number }) => {
      dispatch({ type: "BEGIN_REQUEST" });
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { id } = action;
      try {
        await api.newsletters.delete(id);
        dispatch({
          type: "REQUEST_SUCCESS",
          newsletters: getState().newsletters.filter((p) => p.id !== id),
        });
      } catch (e) {
        console.error(e);
        const error = handleError(e);
        dispatch({ type: "REQUEST_FAILURE", error });
      }
    },
};

const PageContext = createContext<{
  state: PageState;
  dispatch: Dispatch<NewslettersAction>;
}>({
  state: initialState,
  dispatch: () => undefined,
});

export const useNewslettersContext = () => useContext(PageContext);

type Props = {
  overrideInitialState?: PageState;
  dispatchOverride?: Dispatch<NewslettersAction>;
};

export const NewslettersProvider: FC<Props> = ({
  dispatchOverride,
  children,
  ...overrideInitialState
}) => {
  const [state, dispatch] = useReducerAsync<
    Reducer<PageState, SyncAction>,
    AsyncAction,
    NewslettersAction
  >(reducer, { ...initialState, ...overrideInitialState }, asyncActionHandlers);

  const d = dispatchOverride || dispatch;

  return (
    <PageContext.Provider value={{ state, dispatch: d }}>
      {children}
    </PageContext.Provider>
  );
};
