import styled from "styled-components";

export const Container = styled.form`
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 1000px;
  margin: 20px;
  gap: 20px;

  button {
    align-self: flex-end;
  }
`;
export const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  width: 100%;
`;
export const ButtonsAddPhoto = styled(Buttons)`
  justify-content: flex-start;
`;
export const Checkbox = styled.input``;
export const FirstRow = styled.div`
  display: flex;
  align-items: space-between;
  width: 100%;
`;
export const LabelComponent = styled.label`
  display: flex;
  align-items: center;
  padding-left: 10px;
  color: var(--kssc-dark-teal);
  input {
    margin-left: 10px;
  }
  &:focus-within {
    color: var(--kssc-teal);
  }
`;
export const Label = styled.label`
  display: flex;
  text-align: left;
  padding-left: 10px;
  color: var(--kssc-dark-teal);
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 50px;
  gap: 10px;
  img {
    border-radius: 5px;
    margin: 20px 0;
    width: 30%;
    height: 30%;
  }
`;

export const PhotoButtons = styled.div`
  display: flex;
  gap: 10px;

  button {
    white-space: nowrap;
  }
`;

export const SelectedImage = styled.div`
  background-color: var(--kssc-green);
  color: var(--kssc-white);
  border-radius: 5px;
`;

export const FileName = styled.figcaption`
  color: var(--kssc-teal);
`;
export const PhotosListItem = styled.li`
  display: table-cell;
  justify-content: center;
  max-width: 100px;
  img {
    width: 50%;
    height: 50%;
  }
`;
export const PhotosList = styled.ul`
  display: table;
`;

export const SelectWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  padding: 5px 20px 0 0px;
`;
export const PhotosSelect = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
