import styled from "styled-components";

type ButtonProps = {
  color?: string;
};

export const TextButton = styled.button<ButtonProps>`
  background: none;
  color: ${(props) => props?.color || "var(--kssc-teal)"};
  border: none;
  padding: 0 5px;
  font-size: var(--kssc-lg);
  display: flex;
  align-items: center;
  small {
    font-size: var(--kssc-lg);
  }
  cursor: pointer;
  svg {
    color: ${(props) => props?.color || "var(--kssc-teal)"};
    margin-left: 4px;
  }
`;
