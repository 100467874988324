import { FC } from "react";

import * as S from "./Pages.css";

export const SympathyEng: FC = () => (
  <S.Container>
    <S.Title>GongGam (emotional support)</S.Title>
    <S.Text>
      Emotional support is a first-response immediate service we offer in Korean
      and English by phone or text to individuals who are in acute crisis. Our
      volunteers are trained in an emotional support training module, they will
      actively listen to you. Once the individual agrees to move onto the next
      step (resources), they will be guided towards crisis-support and
      distress-support services, and can be referred to Korean-speaking mental
      health professionals.
    </S.Text>
  </S.Container>
);
