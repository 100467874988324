import { FC, useState, useEffect } from "react";
import { useAppContext } from "../contexts/AppContext";
import { ErrorMessage, Icon } from "../electrons";
import * as S from "./Newsletter.css";
import { Title } from "./Pages.css";
import { AddNewsletter, Loading } from "../molecules";
import { Button } from "../atoms";
import { Newsletter } from "../types";
import { useTranslation } from "react-i18next";
import { useNewslettersContext } from "../contexts/NewsletterContext";
import moment from "moment";

export const Newsletters: FC = () => {
  const { t } = useTranslation();
  const [isAdding, setIsAdding] = useState<boolean>(false);
  const [isEditingNewsletter, setIsEditingNewsletter] =
    useState<Newsletter | null>(null);
  const [isDeletingNewsletter, setIsDeletingNewsletter] =
    useState<Newsletter | null>(null);
  const {
    state: { user },
  } = useAppContext();
  const {
    dispatch,
    state: { newsletters, loading, error },
  } = useNewslettersContext();

  useEffect(() => {
    dispatch({ type: "FETCH_NEWSLETTERS" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // console.log(isViewing);
  useEffect(() => () => dispatch({ type: "CLEAN_ALL" }), [dispatch]);
  const pageContent = () => {
    if (isAdding)
      return (
        <AddNewsletter
          onCancel={() => setIsAdding(false)}
          onSubmit={(name: string, file: string, date: string) => {
            dispatch({ type: "ADD_NEWSLETTER", name, file, date });
          }}
        />
      );
    if (isEditingNewsletter)
      return (
        <AddNewsletter
          newsletter={isEditingNewsletter}
          onCancel={() => setIsEditingNewsletter(null)}
          onSubmit={(name: string, image: string, date: string) => {
            dispatch({
              type: "UPDATE_NEWSLETTER",
              newsletter: { ...isEditingNewsletter, name: name, image, date },
            });
          }}
        />
      );
    return (
      <S.NewslettersContainer>
        {error && <ErrorMessage error={error} />}
        {newsletters.length === 0 && <div>No Newsletters yet!</div>}
        {newsletters.length > 0 &&
          newsletters
            .sort(
              (d1, d2) =>
                new Date(d2.date).getTime() - new Date(d1.date).getTime()
            )
            .map((newsletter) => (
              <S.NewsletterItem key={newsletter.id}>
                <S.ItemContainer>
                  <a
                    href={`https://ik.imagekit.io/kssc/${newsletter.image}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {newsletter.name}
                  </a>
                  {(!isDeletingNewsletter ||
                    (isDeletingNewsletter &&
                      isDeletingNewsletter.id !== newsletter.id)) && (
                    <S.NewsletterData color="var(--kssc-dark-yellow)">
                      <S.NewsletterDate>
                        {moment(newsletter.date).format("MM/DD/YY")}
                      </S.NewsletterDate>
                    </S.NewsletterData>
                  )}
                  {!!isDeletingNewsletter &&
                    isDeletingNewsletter.id === newsletter.id && (
                      <S.NewsletterItemContent>
                        <strong>
                          Are you sure you want to delete this newsletter?
                        </strong>
                        {user?.role === "kssc_admin" && (
                          <S.ButtonsContainer>
                            <Button
                              onClick={() =>
                                dispatch({
                                  type: "DELETE_NEWSLETTER",
                                  id: isDeletingNewsletter.id,
                                })
                              }
                              color="var(--kssc-green)"
                            >
                              삭제
                            </Button>
                            <Button
                              color="var(--kssc-grey)"
                              onClick={() => {
                                setIsDeletingNewsletter(null);
                                dispatch({ type: "CLEAN_ALL" });
                              }}
                            >
                              취소
                            </Button>
                          </S.ButtonsContainer>
                        )}
                      </S.NewsletterItemContent>
                    )}
                </S.ItemContainer>
                {user?.role === "kssc_admin" && (
                  <S.ButtonsContainer>
                    <Button onClick={() => setIsEditingNewsletter(newsletter)}>
                      <Icon iconName="pencil" />
                    </Button>
                    <Button
                      color="var(--kssc-pastel-red)"
                      onClick={() => setIsDeletingNewsletter(newsletter)}
                    >
                      <Icon iconName="trash" />
                    </Button>
                  </S.ButtonsContainer>
                )}
              </S.NewsletterItem>
            ))}
      </S.NewslettersContainer>
    );
  };
  return (
    <S.Container>
      {loading && <Loading />}
      <Title>{t("newsletter")}</Title>
      {user?.role === "kssc_admin" && (
        <S.CreateButton onClick={() => setIsAdding(true)} disabled={isAdding}>
          <Icon iconName="file-circle-plus" />
          Add Newsletter
        </S.CreateButton>
      )}
      {pageContent()}
    </S.Container>
  );
};
