import { FC } from "react";
import { useTranslation } from "react-i18next";

import * as S from "./Pages.css";
import { Blogs } from "./Blogs";

export const VolunteeringActivitiesYouthClub: FC = () => {
  const { t } = useTranslation();

  return (
    <S.Container>
      <S.Title>{t("volunteering-activities")}</S.Title>
      <S.Text>
        KSSC’s Youth Club is the latest branch of our organization. Combining
        youthful energy, mentorship and our continued need for volunteers, the
        purpose of this branch is to develop and nurture the leaders of
        tomorrow.
      </S.Text>
      <Blogs blogKind="volunteer" />
    </S.Container>
  );
};
