import { FC } from "react";
import { useTranslation } from "react-i18next";
import { NavBarItemBottom } from "../protons";

import * as S from "./NavBar.css";

export const NavBarBottom: FC = () => {
  const { t } = useTranslation();
  return (
    <S.BottomContainer>
      <NavBarItemBottom
        background="var(--kssc-pastel-teal)"
        backgroundHover="var(--kssc-teal)"
        iconName="fa-solid fa-calendar-days"
        mainButton={{ displayName: t("calendar"), to: "/schedule" }}
      />
      <NavBarItemBottom
        background="var(--kssc-pastel-yellow)"
        backgroundHover="var(--kssc-yellow)"
        iconName="fa-solid fa-hand-holding-dollar"
        mainButton={{ displayName: t("donation-info"), to: "/support-us" }}
      />
      <NavBarItemBottom
        background="var(--kssc-pastel-red)"
        backgroundHover="var(--kssc-red)"
        iconName="fa-solid fa-handshake-angle"
        mainButton={{ displayName: t("volunteer-info"), to: "/jobs" }}
      />
      <NavBarItemBottom
        background="var(--kssc-grey)"
        backgroundHover="var(--kssc-black)"
        iconName="fa-solid fa-map-location-dot"
        mainButton={{ displayName: t("find-us"), to: "/finding-us" }}
      />
    </S.BottomContainer>
  );
};
