import styled from "styled-components";

export const PageContainer = styled.div`
  background-color: var(--kssc-light-yellow);
  min-height: 100%;
  * {
    box-sizing: border-box;
  }
`;
export const Head = styled.div`
  background-color: var(--kssc-white);
  margin: 0 auto;
  padding: 0;
  width: 100%;
  max-width: 1340px;
`;
export const MainContent = styled.div`
  background-color: var(--kssc-white);
  box-sizing: border-box;
  margin: 0 auto;
  padding: 0;
  width: 100%;
  max-width: 1340px;
  min-height: calc(100% - 150px);
`;
export const Footer = styled.div`
  background-color: var(--kssc-dark-teal);
  height: 300px;
  max-width: 1340px;
  margin: 0 auto;
`;

export const IconsContainer = styled.div`
  padding-top: 20px;
  display: flex;
  justify-content: space-evenly;
  align-items: baseline;
  width: 100%;
`;
export const SocialIcons = styled.div`
  padding-top: 20px;
  display: flex;
  justify-content: center;
  gap: 25px;
  align-items: center;
  width: 100%;
  svg {
    font-size: 30px;
    color: var(--kssc-white);
  }
`;
