import { FC } from "react";
import { useTranslation } from "react-i18next";

import * as S from "./Pages.css";
import { Blogs } from "./Blogs";

export const Seminars: FC = () => {
  const { t } = useTranslation();

  return (
    <S.Container>
      <S.Title>{t("seminars")}</S.Title>
      <S.Text>
        From time to time, KSSC organizes and produces seminars on a range of
        topics, including racism, mental health, and government benefits.
      </S.Text>
      <Blogs blogKind="seminar" />
    </S.Container>
  );
};
