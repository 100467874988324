import { FC, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { TextButton } from "../atoms";
import { ErrorMessage, Icon, Input } from "../electrons";
import { useAppContext } from "../contexts/AppContext";
import * as S from "./Pages.css";
import { useTranslation } from "react-i18next";

export const SignIn: FC = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const {
    dispatch,
    state: { error },
  } = useAppContext();
  const { t } = useTranslation();

  useEffect(() => () => dispatch({ type: "CLEAN_ALL" }), [dispatch]);

  return (
    <S.SignUpContainer
      onSubmit={(e) => {
        e.preventDefault();
        dispatch({ type: "SIGN_IN", email, password });
      }}
    >
      {error && <ErrorMessage error={error} />}
      <Input
        label={t("email")}
        type="email"
        name="new-email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        variant="light"
      />
      <Input
        label={t("password")}
        type={showPassword ? "text" : "password"}
        name="new-password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        variant="light"
        button={
          <TextButton
            type="button"
            onClick={() => setShowPassword(!showPassword)}
          >
            <small>{showPassword ? "hide" : "show"}</small>
            <Icon iconName={showPassword ? "eye-slash" : "eye"} />
          </TextButton>
        }
      />

      <S.SendButton>
        <Icon iconName="paper-plane" />
        {t("sign-in")}
      </S.SendButton>
      <S.SignUp>
        {" "}
        <S.SmallLinks>
          <TextButton
            color="var(--kssc-yellow)"
            onClick={() => navigate("/sign-up")}
          >
            {t("sign-up")}
            <Icon iconName="user-plus" />
          </TextButton>
          <TextButton
            color="var(--kssc-yellow)"
            onClick={() => navigate("/forgot-password")}
          >
            {t("forgot-password")}
            <Icon iconName="question-circle" />
          </TextButton>
          <TextButton
            color="var(--kssc-yellow)"
            onClick={() => navigate("/resend-confirmation")}
          >
            {t("resend-confirmation")}
            <Icon iconName="paper-plane" />
          </TextButton>
        </S.SmallLinks>
      </S.SignUp>
    </S.SignUpContainer>
  );
};
