import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppContext } from "../contexts/AppContext";
import { Icon } from "../electrons";
import { useWindowWidth } from "../hooks/useWindowWidth";
import { NavBarItem } from "../protons";

import * as S from "./NavBar.css";

export const NavBar: FC = () => {
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const width = useWindowWidth();
  const { t } = useTranslation();
  const {
    state: { locale },
  } = useAppContext();

  return (
    <S.Container>
      {width <= 706 && (
        <S.MenuButton onClick={() => setIsVisible(!isVisible)}>
          <Icon
            iconName={isVisible ? "fa-solid fa-xmark" : "fa-solid fa-bars"}
            size="var(--kssc-2xl)"
          />{" "}
        </S.MenuButton>
      )}

      {(width > 706 || isVisible) && (
        <>
          <NavBarItem
            iconName="fa-solid fa-people-group"
            onClose={() => setIsVisible(false)}
            mainButton={{ displayName: t("about-the-center") }}
            subMenuItems={[
              { displayName: t("greetings"), to: "/greetings" },
              { displayName: t("about-us"), to: "/vision" },
              {
                displayName: t("organization-structure"),
                to: "/organization-structure",
              },
              { displayName: t("sponsorship"), to: "/sponsorship" },
              { displayName: t("timeline"), to: "/timeline" },
              { displayName: t("finding-us"), to: "/finding-us" },
            ]}
          />
          <NavBarItem
            iconName="fa-solid fa-circle-info"
            onClose={() => setIsVisible(false)}
            mainButton={{
              displayName: t("service-information"),
              to: "/service-information",
            }}
            subMenuItems={[
              {
                displayName: t("settlement"),
                // to: "/settlement",
                subItems: [
                  {
                    displayName: t("seminars"),
                    to: "/seminars",
                  },
                  {
                    displayName: t("general-counselling"),
                    to: "/general-counselling",
                  },
                ],
              },
              {
                displayName: t("education"),
                to: "/education",
                subItems: [
                  {
                    displayName: t("workshops"),
                    to: "/workshops",
                  },
                  {
                    displayName: t("clinics"),
                    to: "/clinics",
                  },
                ],
              },
              { displayName: t("sympathy"), to: "/sympathy" },
              {
                displayName: t("youth-club"),
                // to: "/youth-club",
                subItems: [
                  {
                    displayName: t("volunteering-activities"),
                    to: "/volunteering-activities",
                  },
                  {
                    displayName: t("our-projects"),
                    to: "/our-projects",
                  },
                ],
              },
              {
                displayName: t("events"),
                to: "/events",
              },
              {
                displayName: t("translation-service"),
                to: "/translation-service",
              },
            ]}
          />
          <NavBarItem
            iconName="fa-solid fa-newspaper"
            onClose={() => setIsVisible(false)}
            mainButton={{ displayName: t("communication") }}
            subMenuItems={[
              { displayName: t("blogs"), to: "/blogs" },
              { displayName: t("newsletter"), to: "/newsletter" },
              { displayName: t("photo-album"), to: "/activity-photo-album" },
              { displayName: t("media-releases"), to: "/media-releases" },
              { displayName: t("question-board"), to: "/question-board" },
            ]}
          />
          <NavBarItem
            iconName="fa-solid fa-user-plus"
            onClose={() => setIsVisible(false)}
            mainButton={{
              displayName: t("activities"),
              to: "/application-to-join",
            }}
            subMenuItems={[
              { displayName: t("donation-info"), to: "/volunteering" },
              { displayName: t("volunteer-info"), to: "/jobs" },
              {
                displayName: t("support-us"),
                to: "/support-us",
              },
            ]}
          />
          <NavBarItem
            breakSpaces={locale !== "kr"}
            iconName="fa-solid fa-hand-holding-heart"
            onClose={() => setIsVisible(false)}
            mainButton={{
              displayName: t("online-counselling"),
              to: "/online-counselling",
            }}
          />
        </>
      )}
    </S.Container>
  );
};
