import { FC } from "react";
import { Icon } from "../electrons";

import * as S from "./Pages.css";

export const SupportUs: FC = () => {
  return (
    <S.Container>
      <S.Title>Support Us!</S.Title>
      <S.Text>
        KSSC runs entirely on project-specific grants, generous donations,
        annual membership fees, and fundraising. Outside of funded projects
        (grants), we have zero paid staff, zero contractors and operate 100% on
        volunteers. Every single dollar donated to or raised by our volunteer
        army goes directly to keeping the lights on and for service delivery to
        continue on a free basis. Get in touch to learn more about how you can
        support us.
      </S.Text>
      <S.Text>
        <S.ContactUsText>
          <Icon iconName="phone" color="var(--kssc-teal)" size="18px" />
          <a href="tel:5873745313">587-374-5313</a>
        </S.ContactUsText>{" "}
        or
        <S.ContactUsText>
          <Icon iconName="envelope" color="var(--kssc-teal)" size="18px" />
          <span>
            <a href="mailto:general@kssc-ab.ca">general@kssc-ab.ca</a>
          </span>
        </S.ContactUsText>
      </S.Text>
    </S.Container>
  );
};
