import { FC } from "react";
import { NavBar } from "../atoms";

import * as S from "./PageContent.css";

import { Routes, Route } from "react-router-dom";
import {
  Home,
  Greetings,
  Vision,
  Sympathy,
  EmotionalSupport,
  StaffIntroduction,
  FindingUs,
  ServiceInformation,
  HelpForNewImmigrants,
  ActivityPhotoAlbum,
  ApplicationToJoin,
  LowIncomeSupportTeam,
  Jobs,
  MentalHealthEducation,
  Newsletters,
  OnlineCounselling,
  MediaReleases,
  Blogs,
  Profile,
  SeniorSupportTeam,
  Sponsorship,
  TranslationService,
  Timeline,
  VisaApplicationService,
  Volunteering,
  SignUp,
  SignIn,
  Schedule,
  QuestionBoard,
  EmailConfirmed,
  EmailAlreadyConfirmed,
  EmailConfirmationError,
  ResetPassword,
  ResendEmail,
  Notifications,
  Settlement,
  BlogShow,
  VisionEng,
  Clinics,
  Events,
  GeneralCounselling,
  OurProjects,
  Seminars,
  VolunteeringActivitiesYouthClub,
  Workshops,
  YouthClub,
  SympathyEng,
  SupportUs,
} from "../pages";
import { Header } from "../protons";
import { useAppContext } from "../contexts/AppContext";
import { TimelineProvider } from "../contexts/TimelineContext";
import { CalendarProvider } from "../contexts/CalendarContext";
import { PostCommentsProvider } from "../contexts/PostCommentsContext";
import { PostComments } from "../molecules/PostComments";
import { QuestionComments } from "../molecules/QuestionComments";
import { NotificationsProvider } from "../contexts/NotificationsContext";
import { QuestionCommentsProvider } from "../contexts/QuestionCommentsContext";
import { AlbumProvider } from "../contexts/AlbumContext";
import { PhotosProvider } from "../contexts/PhotosContext";
import { PhotoGallery } from "../pages/PhotoGallery";
import { NewslettersProvider } from "../contexts/NewsletterContext";
import { BlogsProvider } from "../contexts/BlogContext";

export const PageContent: FC = () => {
  const {
    state: { user, locale },
  } = useAppContext();
  return (
    <S.Container>
      <S.HeaderContent>
        <Header />
        <NavBar />
      </S.HeaderContent>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route
          path="/activity-photo-album"
          element={
            <AlbumProvider>
              <ActivityPhotoAlbum />
            </AlbumProvider>
          }
        />
        <Route
          path={"albums/:album_id"}
          element={
            <AlbumProvider>
              <PhotosProvider>
                <PhotoGallery />
              </PhotosProvider>
            </AlbumProvider>
          }
        />
        <Route path="/application-to-join" element={<ApplicationToJoin />} />
        <Route path="/emotional-support" element={<EmotionalSupport />} />
        <Route path="/email-confirmed" element={<EmailConfirmed />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route
          path="/forgot-password"
          element={<ResendEmail kind="Forgot Password" />}
        />
        <Route
          path="/resend-confirmation"
          element={<ResendEmail kind="Resend Confirmation" />}
        />
        <Route
          path="/email-already-confirmed"
          element={<EmailAlreadyConfirmed />}
        />
        <Route
          path="/email-confirmation-error"
          element={<EmailConfirmationError />}
        />
        <Route path="/finding-us" element={<FindingUs />} />
        <Route path="/greetings" element={<Greetings />} />
        <Route
          path="/help-for-new-immigrants"
          element={<HelpForNewImmigrants />}
        />
        <Route path="/jobs" element={<Jobs />} />
        <Route
          path="/low-income-support-team"
          element={<LowIncomeSupportTeam />}
        />

        <Route path="/education" element={<MentalHealthEducation />} />
        <Route
          path="/newsletter"
          element={
            <NewslettersProvider>
              <Newsletters />
            </NewslettersProvider>
          }
        />
        <Route path="/online-counselling/*" element={<OnlineCounselling />} />
        <Route
          path={"online-counselling/:post_id"}
          element={
            <PostCommentsProvider>
              <PostComments />
            </PostCommentsProvider>
          }
        />
        <Route
          path={"question-board/:question_id"}
          element={
            <QuestionCommentsProvider>
              <QuestionComments />
            </QuestionCommentsProvider>
          }
        />
        <Route path="/media-releases" element={<MediaReleases />} />
        <Route
          path="/blogs"
          element={
            <BlogsProvider>
              <Blogs blogKind="blog" />
            </BlogsProvider>
          }
        />
        <Route
          path={"blogs/:kind/:blog_id"}
          element={
            <BlogsProvider>
              <BlogShow />
            </BlogsProvider>
          }
        />
        <Route
          path={"seminars/:kind/:blog_id"}
          element={
            <BlogsProvider>
              <BlogShow />
            </BlogsProvider>
          }
        />
        <Route
          path={"workshops/:kind/:blog_id"}
          element={
            <BlogsProvider>
              <BlogShow />
            </BlogsProvider>
          }
        />
        <Route
          path={"clinics/:kind/:blog_id"}
          element={
            <BlogsProvider>
              <BlogShow />
            </BlogsProvider>
          }
        />
        <Route
          path={"volunteering-activities/:kind/:blog_id"}
          element={
            <BlogsProvider>
              <BlogShow />
            </BlogsProvider>
          }
        />
        <Route
          path={"our-projects/:kind/:blog_id"}
          element={
            <BlogsProvider>
              <BlogShow />
            </BlogsProvider>
          }
        />
        <Route
          path={"events/:kind/:blog_id"}
          element={
            <BlogsProvider>
              <BlogShow />
            </BlogsProvider>
          }
        />
        {user && <Route path="/profile" element={<Profile user={user} />} />}
        {user && user.role === "kssc_admin" && (
          <Route
            path="/notifications"
            element={
              <NotificationsProvider>
                <Notifications />{" "}
              </NotificationsProvider>
            }
          />
        )}
        <Route path="/question-board" element={<QuestionBoard />} />
        <Route path="/senior-support-team" element={<SeniorSupportTeam />} />
        <Route path="/service-information" element={<ServiceInformation />} />
        <Route path="/settlement" element={<Settlement />} />
        <Route path="/sign-up" element={<SignUp />} />
        <Route path="/sign-in" element={<SignIn />} />
        <Route
          path="/schedule"
          element={
            <CalendarProvider>
              <Schedule />
            </CalendarProvider>
          }
        />
        <Route path="/sponsorship" element={<Sponsorship />} />
        <Route path="/organization-structure" element={<StaffIntroduction />} />
        <Route
          path="/sympathy"
          element={locale === "kr" ? <Sympathy /> : <SympathyEng />}
        />
        <Route
          path="/timeline"
          element={
            <TimelineProvider>
              <Timeline />
            </TimelineProvider>
          }
        />
        <Route path="/translation-service" element={<TranslationService />} />
        <Route
          path="/visa-application-service"
          element={<VisaApplicationService />}
        />
        <Route
          path="/vision"
          element={locale === "kr" ? <Vision /> : <VisionEng />}
        />
        <Route path="/volunteering" element={<Volunteering />} />
        <Route
          path="/clinics"
          element={
            <BlogsProvider>
              <Clinics />
            </BlogsProvider>
          }
        />
        <Route
          path="/events"
          element={
            <BlogsProvider>
              <Events />
            </BlogsProvider>
          }
        />
        <Route path="/general-counselling" element={<GeneralCounselling />} />
        <Route
          path="/our-projects"
          element={
            <BlogsProvider>
              <OurProjects />
            </BlogsProvider>
          }
        />
        <Route
          path="/seminars"
          element={
            <BlogsProvider>
              <Seminars />
            </BlogsProvider>
          }
        />
        <Route
          path="/volunteering-activities"
          element={
            <BlogsProvider>
              <VolunteeringActivitiesYouthClub />
            </BlogsProvider>
          }
        />
        <Route
          path="/workshops"
          element={
            <BlogsProvider>
              <Workshops />
            </BlogsProvider>
          }
        />
        <Route path="/youth-club" element={<YouthClub />} />
        <Route path="/support-us" element={<SupportUs />} />
      </Routes>
    </S.Container>
  );
};
