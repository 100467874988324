/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useState, useRef } from "react";
import { Button } from "../atoms";
import { ErrorMessage, Icon, Input } from "../electrons";
import * as S from "./CreatePost.css";
import { Loading } from "./Loading";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import "./react-datepicker-override.css";
import { Newsletter } from "../types";
import { useNewslettersContext } from "../contexts/NewsletterContext";

type Props = {
  onSubmit: (name: string, description: string, date: string) => void;
  onCancel: () => void;
  newsletter?: Newsletter;
};

export const AddNewsletter: FC<Props> = ({
  onSubmit,
  onCancel,
  newsletter,
}) => {
  const [date, setDate] = useState(
    (newsletter && new Date(newsletter.date)) || new Date()
  );
  const inputRef = useRef(null);
  const [name, setName] = useState<string>(newsletter?.name || "");
  const [fileName, setFileName] = useState<string>("");
  const [file, setFile] = useState<string>(newsletter?.image || "");
  const [hasSubmittedForm, setHasSubmittedForm] = useState<boolean>(false);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e?.target?.files && e.target.files[0]?.size > 1048576) {
      alert("Please upload a smaller file. The maximum size is 1MB");
      e.target.value = "";
    }
    if (e?.target?.files) {
      const fileInput = e?.target?.files[0];
      setFileName(fileInput.name);
      const reader = new FileReader();
      reader.readAsDataURL(fileInput);
      reader.onload = () => {
        setFile(reader.result as string);
      };
    }
  };

  const {
    state: { error, loading },
  } = useNewslettersContext();

  return (
    <>
      <S.Container>
        <Loading visible={loading} error={!!error} onComplete={onCancel} />
        {error && <ErrorMessage error={error} />}
        <S.InputContainer>
          <Input
            required={hasSubmittedForm}
            label="Title"
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            variant="light"
          />
          <DatePicker
            className="date-input"
            selected={date}
            onChange={(d: Date) => setDate(d)}
            showMonthDropdown
          />
          {!newsletter && (
            <>
              {" "}
              <input
                style={{ display: "none" }} // hide the default input and use the custom upload button
                type="file"
                name="photo"
                ref={inputRef}
                onChange={handleChange}
              />
              <em style={{ color: "var(--kssc-dark-grey)" }}>
                Note: File size must be less than 1MB
              </em>
              {fileName && <S.FileName>{fileName}</S.FileName>}
              <S.PhotoButtons>
                {inputRef && (
                  <Button
                    type="button"
                    color="var(--kssc-pastel-yellow)"
                    onClick={() => (inputRef?.current as any)?.click()}
                  >
                    <Icon iconName="plus" />
                    Choose File
                  </Button>
                )}
              </S.PhotoButtons>
            </>
          )}
        </S.InputContainer>
        <S.Buttons>
          <Button
            type="submit"
            onClick={(e) => {
              e.preventDefault();
              setHasSubmittedForm(true);
              if (name && file) onSubmit(name, file, date.toString());
            }}
          >
            <Icon iconName="paper-plane" />
            {!!newsletter ? "Update" : "Add"} Newsletter
          </Button>
          <Button type="button" color="var(--kssc-grey)" onClick={onCancel}>
            <Icon iconName="xmark" />
            Cancel
          </Button>
        </S.Buttons>
      </S.Container>
    </>
  );
};
