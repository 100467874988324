/* eslint-disable max-len */
import { FC } from "react";

import * as S from "./Pages.css";

export const HelpForNewImmigrants: FC = () => (
  <S.Container>
    <S.Title>이민정착도움프로그램</S.Title>
    <S.Text>
      이민정착도움 프로그램은 노령층과 저소득층을 위한 정부보조금 신청 Fair
      Entry 와 Free Goods 프로그램 신청을 도와드리고 있습니다.
    </S.Text>
    <S.Text>
      노령층(65세 이상) 지원 가능한 정부보조금에는 OAS/GIS/Allowance등이
      있습니다.
    </S.Text>
    <S.TextAlt1>
      <strong>OAS:</strong>{" "}
      <a href="https://www.canada.ca/en/services/benefits/publicpensions/cpp/old-age-security.html">
        The Old Age Security
      </a>
      (OAS)의 약자로 캐나다 정부가 65세 이상 연령층에 지급하는 노후보장연금
      입니다. 보통 65세가 다가오면 정부에서 신청서가 우편으로 도착합니다. 그렇지
      않은 경우 Service Canada에 따로 연락해야 합니다. 노후보장연금 신청 시
      소득보장보조금(GIS)과 때에 따라 배우자 보조금(Allowance)도 함께 신청할 수
      있습니다.
    </S.TextAlt1>
    <S.Text>
      저소득층을 위한 프로그램에는 Fair Entry와 Free Goods Program이 있습니다.
    </S.Text>
    <S.TextAlt2>
      <strong>Fair Entry:</strong>
      <a href="https://www.calgary.ca/csps/cns/neighbourhood-services/programs-and-services-for-low-income-calgarians.html">
        ‘Fair Entry’
      </a>
      는 캘거리시에서 저소득층을 위해 제공하는 프로그램입니다. 대중교통 정액권
      할인, 체육시설, 골프장, 노인 홈케어, 캘거리 동물원, 캘거리 헤리티지파크 등
      다양한 시설 입장권 할인 혜택이 있습니다. 캘거리시 거주자로 정부에서 규정한
      저소득층 기준에 해당하면 신청 가능합니다.
    </S.TextAlt2>
    <S.Text>
      <strong>Free Goods Program:</strong> 캐나다에 오신 지 얼마 되지 않은
      분이나, 저소득층에 해당하는 사람 또는 가족이 지원할 수 있으며, 기부 받은
      물품(중고/새 상품)들을 무료로 나눠드리는 프로그램입니다. 물품에는 책상,
      침대, 식탁, 음식 등 다양합니다.
    </S.Text>
  </S.Container>
);
