import { FC, useState, useEffect } from "react";
import { Button } from "../atoms";
import { Icon, Input, ErrorMessage } from "../electrons";
import { User } from "../types";
import { useAppContext } from "../contexts/AppContext";
import * as S from "./Pages.css";

type Props = {
  user: User;
};

export const Profile: FC<Props> = ({ user }) => {
  const [name, setName] = useState<string>(user.name);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const {
    dispatch,
    state: { error },
  } = useAppContext();

  useEffect(() => () => dispatch({ type: "CLEAN_ALL" }), [dispatch]);

  if (isEditing && user)
    return (
      <S.SignUpContainer
        onSubmit={(e) => {
          e.preventDefault();
          dispatch({ type: "UPDATE_USER", name, id: user.id });
          setIsEditing(false);
        }}
      >
        {error && <ErrorMessage error={error} />}

        <S.SignUpSection>정보 입력</S.SignUpSection>
        <Input
          label="성함"
          type="text"
          name="name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          variant="light"
        />
        <S.Buttons>
          <Button
            color="var(--kssc-pastel-red)"
            onClick={() => setIsEditing(false)}
          >
            <Icon iconName="xmark" />
            취소
          </Button>
          <Button>
            <Icon iconName="floppy-disk" />
            저장
          </Button>
        </S.Buttons>
      </S.SignUpContainer>
    );
  return (
    <S.Container>
      <S.Title>프로필</S.Title>
      <S.ProfileItems>
        <S.ProfileItem>
          <Icon
            iconName="fa-solid fa-user"
            size="30px"
            color="var(--kssc-yellow)"
          />
          <span>{user?.name}</span>
        </S.ProfileItem>
        <Button onClick={() => setIsEditing(true)}>
          <Icon iconName="fa-solid fa-pencil" />
          편집
        </Button>
      </S.ProfileItems>
    </S.Container>
  );
};
