/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useState, useRef } from "react";
import { Button } from "../atoms";
import { usePhotosContext } from "../contexts/PhotosContext";
import { ErrorMessage, Icon } from "../electrons";
import * as S from "./CreatePost.css";
import { Loading } from "./Loading";

type Props = {
  onSubmit: (
    files: { file: string; fileName: string }[],
    album_id: number
  ) => void;
  onCancel: () => void;
  album_id: number;
};

export const AddPhoto: FC<Props> = ({ onSubmit, onCancel, album_id }) => {
  const inputRef = useRef(null);
  const [files, setFiles] = useState<{ file: string; fileName: string }[]>([]);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e?.target?.files) {
      const filesList = Array.prototype.slice.call(e.target.files);
      for (const file of filesList) {
        const fileName = file.name;
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          setFiles((prev) => [
            ...prev,
            { file: reader.result as string, fileName },
          ]);
        };
      }
    }
  };

  const {
    state: { error, loading },
  } = usePhotosContext();

  return (
    <>
      <S.Container>
        <Loading visible={loading} error={!!error} onComplete={onCancel} />
        {error && <ErrorMessage error={error} />}
        <S.InputContainer>
          <input
            style={{ display: "none" }} // hide the default input and use the custom upload button
            type="file"
            multiple
            name="photo"
            ref={inputRef}
            onChange={handleChange}
          />
          {Boolean(files.length) && (
            <S.PhotosList>
              {files.map((f) => (
                <S.PhotosListItem key={f.fileName}>
                  <img src={f.file} height="200" alt="preview" />
                  <S.FileName key={f.fileName}>{f.fileName}</S.FileName>
                </S.PhotosListItem>
              ))}
            </S.PhotosList>
          )}
        </S.InputContainer>
        <S.ButtonsAddPhoto>
          {inputRef && (
            <Button
              type="button"
              color="var(--kssc-pastel-yellow)"
              onClick={() => (inputRef?.current as any)?.click()}
            >
              <Icon iconName="plus" />
              Select Photos
            </Button>
          )}
          <Button
            type="submit"
            onClick={(e) => {
              e.preventDefault();
              if (files) onSubmit(files, album_id);
            }}
          >
            <Icon iconName="paper-plane" />
            Add Photos to Album
          </Button>
          <Button type="button" color="var(--kssc-grey)" onClick={onCancel}>
            <Icon iconName="xmark" />
            Cancel
          </Button>
        </S.ButtonsAddPhoto>
      </S.Container>
    </>
  );
};
