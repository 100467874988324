import styled from "styled-components";
import { MEDIA } from "../styles";

export const Container = styled.form`
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1000px;
  margin: 20px;
  gap: 20px;

  button {
    align-self: flex-end;
  }
  @media (max-width: ${MEDIA.xs}) {
    margin: 5px;
    padding: 0;
  }
`;
export const Buttons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Dates = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  svg {
    padding: 0 10px;
  }
  @media (max-width: ${MEDIA.xs}) {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    svg {
      display: none;
    }
  }
`;
export const DateInput = styled.div`
  width: 100%;
  display: inline-flex;
  align-items: center;
  span {
    color: var(--kssc-teal);
  }
  @media (max-width: ${MEDIA.xs}) {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
`;
export const Checkbox = styled.div`
  width: 100%;
  display: inline-flex;
  align-items: center;
  label,
  input {
    color: var(--kssc-teal);
  }
`;
export const CheckboxInput = styled.input`
  color: var(--kssc-teal);
`;
