import { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "../atoms";
import { useSocket } from "../contexts/ActionCableContext";
import { useNotificationsContext } from "../contexts/NotificationsContext";
import { Icon } from "../electrons";
import { Notification } from "../types";

import * as S from "./Notifications.css";
import * as P from "./Pages.css";

const colors = [
  "var(--kssc-pastel-teal)",
  "var(--kssc-pastel-yellow)",
  "var(--kssc-pastel-red)",
  "var(--kssc-white)",
];
export const Notifications: FC = () => {
  const {
    state: { notifications },
    dispatch,
  } = useNotificationsContext();
  const { dispatch: socketDispatch } = useSocket();

  useEffect(() => {
    dispatch({ type: "INIT" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    socketDispatch({
      type: "NOTIFICATIONS_SUBSCRIBE",
      notificationsDispatch: dispatch,
    });
    return () =>
      socketDispatch({ type: "UNSUBSCRIBE", channelName: "notifications" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleClick = (notification: Notification) => {
    dispatch({ type: "DELETE_NOTIFICATION", id: notification.id });
    navigate(`/online-counselling/${notification.notifiable_id}`);
  };
  const navigate = useNavigate();
  return (
    <P.Container>
      <P.Title>알림</P.Title>
      <S.Divider>
        <span>온라인상담</span>
      </S.Divider>
      <S.Stack>
        {notifications.length > 0 &&
          notifications.map((notification, index) => (
            <S.Item background={colors[index % 4]} key={notification.id}>
              <S.ItemText>
                {" "}
                A new comment has been added to "
                <S.PostTitle>{notification.title}"</S.PostTitle>" by user{" "}
                <em>{notification.username}</em>
              </S.ItemText>
              <Button onClick={() => handleClick(notification)}>View</Button>
            </S.Item>
          ))}
        {notifications.length === 0 && (
          <S.Success>
            <span>You're up to date!</span>
            <Icon iconName="thumbs-up" />
          </S.Success>
        )}
      </S.Stack>
    </P.Container>
  );
};
