import { FC, useState } from "react";
import { Button } from "../atoms";
import { useAlbumContext } from "../contexts/AlbumContext";
import { ErrorMessage, Icon, Input, TextArea } from "../electrons";
import { Album } from "../types";
import * as S from "./CreatePost.css";
import { Loading } from "./Loading";

type Props = {
  onSubmit: (
    title_kor: string,
    description_kor: string,
    title_eng: string,
    description_eng: string
  ) => void;
  onCancel: () => void;
  album?: Album;
  variant?: "green";
};

export const CreateAlbum: FC<Props> = ({
  onSubmit,
  onCancel,
  album,
  variant,
}) => {
  const [titleKor, setTitleKor] = useState<string>(album?.title_kor || "");
  const [titleEng, setTitleEng] = useState<string>(album?.title_eng || "");
  const [descriptionKor, setDescriptionKor] = useState<string>(
    album?.description_kor || ""
  );
  const [descriptionEng, setDescriptionEng] = useState<string>(
    album?.description_eng || ""
  );

  const {
    state: { error, loading },
  } = useAlbumContext();
  return (
    <>
      <Loading visible={loading} error={!!error} onComplete={onCancel} />
      <S.Container>
        {error && <ErrorMessage error={error} />}
        <Input
          label="Title 한국어"
          type="text"
          value={titleKor}
          variant={!!album && variant ? undefined : "light"}
          onChange={(e) => setTitleKor(e.target.value)}
        />
        <Input
          label="Title 영어"
          type="text"
          value={titleEng}
          variant={!!album && variant ? undefined : "light"}
          onChange={(e) => setTitleEng(e.target.value)}
        />
        <TextArea
          variant={!!album && variant ? undefined : "light"}
          label="Description 한국어"
          value={descriptionKor}
          onChange={(e) => setDescriptionKor(e.target.value)}
        />
        <TextArea
          variant={!!album && variant ? undefined : "light"}
          label="Description 영어"
          value={descriptionEng}
          onChange={(e) => setDescriptionEng(e.target.value)}
        />
        <S.Buttons>
          <Button
            type="submit"
            onClick={(e) => {
              e.preventDefault();
              onSubmit(titleKor, descriptionKor, titleEng, descriptionEng);
            }}
          >
            <Icon iconName="paper-plane" />
            {!!album ? "Save Changes" : "Submit"}
          </Button>
          <Button type="button" color="var(--kssc-grey)" onClick={onCancel}>
            <Icon iconName="xmark" />
            Cancel
          </Button>
        </S.Buttons>
      </S.Container>
    </>
  );
};
