import { FC } from "react";
import { useTranslation } from "react-i18next";

import * as S from "./Pages.css";
import { Blogs } from "./Blogs";

export const Events: FC = () => {
  const { t } = useTranslation();

  return (
    <S.Container>
      <S.Title>{t("events")}</S.Title>
      <S.Text>
        From time to time, KSSC organizes or participates in public events under
        the themes of human rights (anti-racism), cultural promotion, or
        multiculturalism. These events aim to raise awareness and build bridges
        between siloed communities.
      </S.Text>
      <Blogs blogKind="events" />
    </S.Container>
  );
};
