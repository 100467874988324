import { FC } from "react";
import { useTranslation } from "react-i18next";

import * as S from "./Pages.css";
import { Blogs } from "./Blogs";

export const OurProjects: FC = () => {
  const { t } = useTranslation();

  return (
    <S.Container>
      <S.Title>{t("our-projects")}</S.Title>
      <S.Text>
        We are always looking for opportunities for our youths to develop their
        leadership and professional skills, to fundraise, and to collaborate
        with other organizations from within and outside of the Korean
        community.
      </S.Text>
      <Blogs blogKind="projects" />
    </S.Container>
  );
};
