import { FC } from "react";

import * as S from "./Pages.css";

export const VisionEng: FC = () => {
  return (
    <S.Container>
      <S.SectionTeal>
        <S.Title>History and Milestones</S.Title>
        <S.Text>
          KSSC was founded in 2021 to demolish barriers to equal and equitable
          access to social services, and to help make the lives of immigrants
          who are having difficulties settling in Canadian society more
          prosperous. Founded and led by Korean immigrants to Calgary with a
          background in social services, KSSC aims to fill a giant void in the
          Calgary Korean community's fragmented diaspora.{" "}
        </S.Text>
      </S.SectionTeal>
      <S.Text>
        Since getting registered in the fall of 2021, we have already
        accomplished great, impactful things. KSSC provides settlement programs
        and external resources to help immigrants find information on health
        care, schools, housing, banking, employment, breaking barriers for
        immigrant women, and much more. These services are open to immigrants,
        Canadian citizens, permanent residents and visitors.
      </S.Text>
      <S.SectionTeal>
        <S.Text>
          Our agency cares deeply about newcomers' success in Canada and thus
          provides culturally sensitive programs and services in many languages
          to support their short-term and long-term settlement experiences.{" "}
          Although our day-to-day services focus on social services, a recurring
          theme in the plight of our clients is racism, prejudice and
          stereotypes. This places KSSC in a strong position to champion this
          cause and not only be a voice for the Korean community in this area.
          Our efforts in this arena have led to multicultural and anti-racism
          events that have built goodwill between ourselves and other
          ethno-cultural communities in Calgary. Our staff values diversity, in
          which people of all backgrounds find and create opportunities to
          fulfill dreams and participate fully as citizens.
        </S.Text>
      </S.SectionTeal>
      <S.ListAlt>
        <S.CoreValues>KSSC's core values include the following:</S.CoreValues>
        <li> Equality, equity, and respect</li>
        <li> Accessible learning </li>
        <li>Non-discriminatory and non-judgemental approaches </li>
        <li>Confidentiality</li>
        <li> Anti-racism, human rights, and social justice</li>
        <li> Diversity and inclusion</li>
      </S.ListAlt>
      <S.SectionTeal>
        <S.Title>Mission and Vision</S.Title>
        <S.Text>
          <S.VisionStrong>Mission</S.VisionStrong>
          Our Mission is to facilitate easy access for Koreans to government
          programs and services, to build up a bridge between Koreans and other
          communities, to increase Koreans’, immigrants’ and newcomers’ access
          to social, mental-health and education resources in order for them to
          integrate successfully into Canadian society.
        </S.Text>
        <S.TextLast>
          <S.VisionStrong>Vision</S.VisionStrong>
          Our Vision is to create a community where anyone regardless of their
          identity or language abilities can easily access resources for social
          services, mental health, and education without language barriers,
          cultural barriers, prejudice or discrimination.
        </S.TextLast>
      </S.SectionTeal>
    </S.Container>
  );
};
