import { ErrorMessage } from "../electrons";
import { FC, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Input, Icon } from "../electrons";

import * as S from "./Pages.css";
import { useAppContext } from "../contexts/AppContext";

type Props = {
  kind: "Forgot Password" | "Resend Confirmation";
};
export const ResendEmail: FC<Props> = ({ kind }) => {
  const [hasSubmitted, setHasSubmitted] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const {
    state: { error },
    dispatch,
  } = useAppContext();
  useEffect(() => () => dispatch({ type: "CLEAN_ALL" }), [dispatch]);

  if (hasSubmitted && !error)
    return (
      <S.Container>
        <S.Title>{kind}</S.Title>
        <S.Text>
          Email sent, return to <Link to="/">home</Link>?
        </S.Text>
      </S.Container>
    );
  return (
    <S.Container>
      <S.Title>{kind}</S.Title>
      <S.SignUpContainer
        onSubmit={(e) => {
          e.preventDefault();
          setHasSubmitted(true);
          dispatch({ type: "RESEND_EMAIL", email, kind });
        }}
      >
        {error && <ErrorMessage error={error} />}
        <S.SignUpSection>정보 입력</S.SignUpSection>
        <Input
          label="이메일 주소"
          type="email"
          name="new-email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          variant="light"
        />

        <S.SendButton>
          <Icon iconName="paper-plane" />
          저장
        </S.SendButton>
      </S.SignUpContainer>
    </S.Container>
  );
};
